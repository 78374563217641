import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiCloseCircle, mdiCheckBold, mdiAlertCircleOutline } from "@mdi/js";

import * as httpService from "../../http-service/httpService";
import {
  isValidPhoneNumber,
  isValidCard,
  isValidMonth,
  isValidYear,
} from "../utils/Validators";

import AfiliationForm from "../afiliation/AfiliationForm";
import Modal from "../utils/Modal";
import Loading from "../utils/Loading";
import * as moment from "moment";
import conektaHelper from "../utils/conektaHelper";
import { useFiscalDataForm } from "../fiscalData/useFiscalDataForm";
import { ModalHeader } from "../ModalHeader";
import { ModalFooter } from "../ModalFooter";
import { ModalBody } from "../ModalBody";

const AfiliationController = ({
  paymentInf,
  paymentDat,
  estabDat,
  customerDat,
  fiscalDat,
  handleSubmitSuccess,
  cancelButtonPath,
  httpGet,
  httpPost,
  urlPost,
  ...props
}) => {
  const history = useHistory();
  const [paymentInfo, setPaymentInfo] = React.useState(paymentInf);
  const [paymentData, setPaymentData] = React.useState(paymentDat);
  const [cardBrand, setCardBrand] = React.useState("");
  const [establishmentData, setEstablishmentData] = React.useState(estabDat);
  const [customerData, setCustomerData] = React.useState(customerDat);
  const [loading, setLoading] = React.useState(false);
  const [confirm, setConfirm] = React.useState(false);
  const [paymentDataError, setPaymentDataError] = React.useState({
    tokenized_card: "",
    payment_type: "",
  });
  const [paymentInfoError, setPaymentInfoError] = React.useState({
    name: "",
    account: "",
    month: "",
    year: "",
    monthYear: "",
    cvc: "",
  });
  const [establishmentDataError, setEstablishmentDataError] = React.useState({
    name: "",
    branchs: "",
  });
  const [customerDataError, setCustomerDataError] = React.useState({
    name: "",
    phone: "",
    email: "",
    receipt: "",
    invoice: "",
    plan_id: "",
    billing_cycle_start: "",
    state: "",
  });
  const {
    fiscalData,
    fiscalDataError,
    handleOnChangeFiscalData,
    setFiscalData,
    setFiscalDataError,
    ...fiscalDataForm
  } = useFiscalDataForm({
    initialValues: fiscalDat,
  });
  const [dataForm, setDataForm] = React.useState({
    plans: { data: [], isLoading: true },
    states: { data: [], isLoading: true },
  });
  const [modalErrorIsOpen, setModalErrorIsOpen] = React.useState(false);
  const [modalConfirmFiscalDataIsOpen, setModalConfirmFiscalDataIsOpen] =
    React.useState(false);
  const [modalConfirmIsOpen, setModalConfirmIsOpen] = React.useState(false);
  const [modalSuccessIsOpen, setModalSuccessIsOpen] = React.useState(false);
  const [confirmFiscalData, setConfirmFiscalData] = React.useState(false);
  const [globalError, setGlobalError] = React.useState([]);
  const [hasFiscalData, setHasFiscalData] = React.useState(false);
  const [focus, setFocus] = React.useState(false);
  const [total, setTotal] = React.useState("");
  const [amount, setAmount] = React.useState("");

  React.useEffect(() => {
    httpGet(`/api/afiliation/utils/?state=${customerData.state}`)
      .then((response) => {
        setDataForm((dat) => {
          const newValue = { ...dat };
          newValue["plans"] = {
            ...dat["plans"],
            isLoading: false,
            data: response.data.plans,
          };
          newValue["states"] = {
            ...dat["states"],
            isLoading: false,
            data: response.data.states,
          };
          return newValue;
        });
        setAmount(
          response.data.plans[
            getIndexValue(response.data.plans, customerDat.plan_id)
          ].amount
        );
        validateTotal(
          response.data.plans[
            getIndexValue(response.data.plans, customerDat.plan_id)
          ].amount,
          establishmentData.branchs
        );
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [httpGet]);

  const resetErrorEstablishment = (field) => {
    setEstablishmentDataError((err) => ({ ...err, [field]: "" }));
  };
  const resetErrorPaymentInfo = (field) => {
    setPaymentInfoError((err) => ({ ...err, [field]: "" }));
  };
  const resetErrorCustomer = (field) => {
    setCustomerDataError((err) => ({ ...err, [field]: "" }));
  };

  const handleOnChangeEstablishment = (e, idx) => {
    const establishment = { ...establishmentData };
    establishment[e.target.name] = e.target.value;
    resetErrorEstablishment(e.target.name);
    if (e.target.name === "cvc" && e.target.value.length > 0) {
      setEstablishmentDataError((err) => ({
        ...err,
        name: ["Campo Requerido"],
      }));
    }
    if (
      e.target.name === "branchs" &&
      (parseInt(e.target.value) < 1 || e.target.value.length === 0)
    ) {
      setEstablishmentDataError((err) => ({
        ...err,
        branchs: ["El minimo de sucursales es 1"],
      }));
    }

    if (e.target.name === "branchs") {
      validateTotal(amount, e.target.value);
    }
    setEstablishmentData(establishment);
  };

  const handleOnChangePaymentInfo = (e) => {
    conektaHelper.initConekta();
    const payment = { ...paymentInfo };
    payment[e.target.name] = e.target.value;
    resetErrorPaymentInfo(e.target.name);
    if (e.target.name === "cvc" && !conektaHelper.validateCvc(e.target.value)) {
      setPaymentInfoError((err) => ({
        ...err,
        cvc: ["Verifique su codígo de seguridad"],
      }));
    }
    if (e.target.name === "month" && !isValidMonth(e.target.value)) {
      setPaymentInfoError((err) => ({
        ...err,
        month: ["Ingrese un mes en el formato valido MM"],
      }));
    }
    if (e.target.name === "year" && !isValidYear(e.target.value)) {
      setPaymentInfoError((err) => ({
        ...err,
        year: ["Ingrese un mes en el formato valido YY"],
      }));
    }
    if (
      e.target.name === "account" &&
      (!isValidCard(e.target.value) ||
        !conektaHelper.validateCardNumber(e.target.value))
    ) {
      setPaymentInfoError((err) => ({
        ...err,
        account: [
          "Valide que el numero sea correcto. En este campo solo se permiten numeros. No debe de contener espacios.",
        ],
      }));
    }
    if (
      payment.month.length === 2 &&
      payment.year.length === 2 &&
      !conektaHelper.validateExpirationDate(payment.month, payment.year)
    ) {
      setPaymentInfoError((err) => ({
        ...err,
        monthYear: [
          "Fecha de expiraion invalida, verifiquela o escoja otra forma de pago",
        ],
      }));
    } else {
      resetErrorPaymentInfo("monthYear");
    }
    setPaymentInfo(payment);
  };

  const handleOnChangeCustomerData = (e) => {
    const customer = { ...customerData };
    const fieldName = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    customer[fieldName] = value;
    resetErrorCustomer(e.target.name);
    if (fieldName === "phone" && !isValidPhoneNumber(value)) {
      setCustomerDataError((err) => ({
        ...err,
        phone: ['Ingrese un numero a 10 digitos se permiten espacios y "-"'],
      }));
    }
    if (fieldName === "terms" && !value)
      setCustomerDataError((err) => ({
        ...err,
        terms: ["Acepte los terminos y condiciones para continuar"],
      }));

    if (fieldName === "receipt" || fieldName === "invoice")
      customer[fieldName] = value === "true" ? true : false;

    if (fieldName === "invoice") {
      setFiscalData((fiscalData) => ({
        ...fiscalData,
        not_factura: value !== "true",
      }));
    }

    if (fieldName === "name")
      setPaymentInfo((dat) => {
        const newValue = { ...dat };
        newValue["name"] = value;
        return newValue;
      });

    setCustomerData(customer);
  };

  const handleOnChangeFiscalDataProxy = (e) => {
    e.persist();
    if (e.target.name === "not_factura") {
      setCustomerData((customer) => ({
        ...customer,
        invoice: !e.target.checked,
      }));
    }
    handleOnChangeFiscalData(e);
  };

  const handleOnBillingCycle = (date) => {
    resetErrorCustomer("billing_cycle_start");
    if (date === null) {
      setCustomerDataError((prev) => {
        const newValue = { ...prev };
        newValue["billing_cycle_start"] = ["Seleccione un plan de Pagos"];
        return newValue;
      });
    }
    setCustomerData((dat) => ({
      ...dat,
      billing_cycle_start: date.toISOString(),
    }));
  };

  const handlePlanChange = (option) => {
    resetErrorCustomer("plan_id");
    var value = null;
    if (option) {
      value = Number(option.value);
    }

    if (value === null) {
      setTotal("");
      setCustomerDataError((prev) => {
        const newValue = { ...prev };
        newValue["plan_id"] = ["Seleccione un plan de Pagos"];
        return newValue;
      });
    }
    setCustomerData((dat) => ({ ...dat, plan_id: value }));
    validateTotal(option.amount, establishmentData.branchs);
  };

  const validateTotal = (amount, branchs) => {
    var valid = true;
    setAmount(amount);
    var total = amount * branchs;
    var formatter = new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: "MXN",
    });
    if (hasFiscalData) {
      total = total * 1.16;
    }
    if (amount > 0 && branchs > 0) {
      setTotal(`Total: ${formatter.format(total)}`);
      // setTotal(`Total: (${formatter.format(amount)}) * sucursales${branchs} = ${total}`)
    }
    if (total > props.limitAmount) {
      setCustomerDataError((prev) => {
        const newValue = { ...prev };
        newValue["plan_id"] = [
          `Limite de pago. No se puede generar una afiliacion de mas de $8,000 en un mismo cobro. Sugerimos que cambie el numero de sucursales y agregue las faltantes en un nuevo registro`,
        ];
        return newValue;
      });
      valid = false;
    } else {
      resetErrorCustomer("plan_id");
    }
    return valid;
  };

  const handleStateChange = (option) => {
    resetErrorCustomer("state");
    var value = null;
    if (option) {
      value = Number(option.value);
    }
    if (value === null) {
      setCustomerDataError((prev) => {
        const newValue = { ...prev };
        newValue["state"] = ["Seleccione un Estado"];
        return newValue;
      });
    }
    setCustomerData((dat) => ({ ...dat, state: value }));
    setEstablishmentData((dat) => ({ ...dat, state: value }));
  };

  const getIndexValue = (arr, value) => {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i]["value"] === value) {
        return i;
      }
    }
    return -1; //to handle the case where the value doesn't exist
  };

  const validateForm = () => {
    let valid_estab = true;
    let valid_fiscal_data = true;
    let error_estab = {};
    let valid_customer = true;
    let error_customer = {};
    let valid_payment = true;
    let error_payment = {};

    Object.keys(establishmentData).forEach((item) => {
      resetErrorEstablishment(item);
      if (
        establishmentData[item] === null ||
        establishmentData[item] === undefined ||
        establishmentData[item].length === 0
      ) {
        error_estab[item] = ["Este campo es requerido"];
        valid_estab = false;
      }
    });
    if (!valid_estab) {
      setEstablishmentDataError((err) => ({ ...err, ...error_estab }));
    }

    valid_customer = validateTotal(amount, establishmentData.branchs);
    Object.keys(customerData).forEach((item) => {
      resetErrorCustomer(item);
      if (
        customerData[item] === null ||
        customerData[item] === undefined ||
        customerData[item].length === 0
      ) {
        error_customer[item] = ["Este campo es requerido"];
        valid_customer = false;
      }
      if (item === "phone" && !isValidPhoneNumber(customerData[item])) {
        error_customer[item] = [
          'Ingrese un numero a 10 digitos se permiten espacios y "-"',
        ];
        valid_customer = false;
      }
      if (item === "plan_id" && parseInt(customerData[item]) === 0) {
        error_customer[item] = ["Seleccione un plan de Pagos"];
        valid_customer = false;
      }
      if (item === "terms" && !customerData[item]) {
        error_customer[item] = [
          "Acepte los terminos y condiciones para continuar",
        ];
        valid_customer = false;
      }
    });
    if (!valid_customer) {
      setCustomerDataError((err) => ({ ...err, ...error_customer }));
    }

    Object.keys(paymentInfo).forEach((item) => {
      if (paymentInfo[item].length === 0) {
        error_payment[item] = ["Este campo es requerido"];
        valid_payment = false;
      }
      if (item === "cvc" && !conektaHelper.validateCvc(paymentInfo[item])) {
        error_payment[item] = ["Verifique su codígo de seguridad"];
        valid_payment = false;
      }
      if (
        item === "account" &&
        (!isValidCard(paymentInfo[item]) ||
          !conektaHelper.validateCardNumber(paymentInfo[item]))
      ) {
        error_payment[item] = [
          "Valide que el numero sea correcto. En este campo solo se permiten numeros. No debe de contener espacios.",
        ];
        valid_payment = false;
      }
    });

    if (
      paymentInfo.month.length === 2 &&
      paymentInfo.year.length === 2 &&
      !conektaHelper.validateExpirationDate(paymentInfo.month, paymentInfo.year)
    ) {
      error_payment["monthYear"] = [
        "Fecha de expiraion invalida, verifiquela o escoja otra forma de pago",
      ];
      valid_payment = false;
    }
    if (!valid_payment) {
      setPaymentInfoError((err) => ({ ...err, ...error_payment }));
    } else {
      setCardBrand(conektaHelper.getCardBrand(paymentInfo.account));
    }

    valid_fiscal_data = fiscalDataForm.validateForm();
    console.log({ valid_fiscal_data })

    return valid_estab && valid_payment && valid_customer && valid_fiscal_data;
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let is_valid = validateForm();
    console.log({ is_valid })
    if (is_valid) {
      setLoading(true);
      conektaHelper.tokenize(
        paymentInfo.account,
        paymentInfo.name,
        paymentInfo.month,
        `20${paymentInfo.year}`,
        paymentInfo.cvc,
        tokenizeSuccessResponseHandler,
        tokenizeErrorResponseHandler
      );
      setModalConfirmFiscalDataIsOpen(true);
    }
  };

  const tokenizeSuccessResponseHandler = (tokenC) => {
    setPaymentData({
      tokenized_card: tokenC.id,
      payment_type: "conekta",
    });
    setLoading(false);
  };

  const tokenizeErrorResponseHandler = (error) => {
    setGlobalError([error.message_to_purchaser]);
    setLoading(false);
  };

  const handleOnConfirm = () => {
    setModalConfirmIsOpen(false);
    sendForm();
  };

  const sendForm = async () => {
    if (!validateForm()) return;

    let fd = new FormData();

    for (let key in fiscalData) {
      fd.append(`fiscalData['${key}']`, fiscalData[key]);
    }
    for (let key in establishmentData) {
      fd.append(`establishmentData['${key}']`, establishmentData[key]);
    }
    for (let key in paymentData) {
      fd.append(`paymentData['${key}']`, paymentData[key]);
    }
    for (let key in customerData) {
      fd.append(`customerData['${key}']`, customerData[key]);
    }

    setLoading(true);
    httpPost(urlPost, fd)
      .then((response) => {
        setModalSuccessIsOpen(true);
      })
      .catch((error) => {
        let hasGlobalDocumentError = error.response.data 
          && error.response.data.fiscalData 
          && error.response.data.fiscalData.non_field_errors;
        if (error.response.data.errors) {
          setGlobalError(error.response.data.errors[0].message);
          setModalErrorIsOpen(true);
        } else if (hasGlobalDocumentError) {
          setGlobalError(error.response.data.fiscalData.non_field_errors);
          setModalErrorIsOpen(true);
        } else {
          setPaymentInfoError((err) => ({
            ...err,
            ...error.response.data.paymentData,
          }));
          setEstablishmentDataError((err) => ({
            ...err,
            ...error.response.data.establishmentData,
          }));
          setCustomerDataError((err) => ({
            ...err,
            ...error.response.data.customerData,
          }));
          setFiscalDataError((err) => ({
            ...err,
            ...error.response.data.fiscalData,
          }));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const okFiscalData = () => {
    setModalConfirmFiscalDataIsOpen(false);
    setModalConfirmIsOpen(true);
  };

  const controlStylesHasError = (hasError) =>
    hasError ? { borderColor: "red" } : {};

  const handleModalErrorClose = () => setModalErrorIsOpen(false);
  const handleModalConfirmFiscalDataClose = () =>
    setModalConfirmFiscalDataIsOpen(false);
  const handleModalConfirmClose = () => setModalConfirmIsOpen(false);
  const handleSuccessModalClose = () => {
    setModalSuccessIsOpen(false);
    history.replace("/success");
  };

  const getSchemeLabelFromPlans = (plan_id) => {
    const index = getIndexValue(dataForm.plans.data, plan_id);
    const plan = dataForm.plans.data[index];
    return plan ? plan.label : "";
  };

  return (
    <>
      {loading ? (
        <div className="container">
          <div className="columns is-centered">
            <div className="columns is-narrow">
              <Loading className="has-background-dark" />
            </div>
          </div>
        </div>
      ) : (
        <AfiliationForm
          handleOnSubmit={handleOnSubmit}
          handleOnChangePaymentInfo={handleOnChangePaymentInfo}
          handleOnChangeEstablishment={handleOnChangeEstablishment}
          handleOnChangeCustomerData={handleOnChangeCustomerData}
          handleOnChangeFiscalData={handleOnChangeFiscalDataProxy}
          handleOnBillingCycle={handleOnBillingCycle}
          paymentInfo={paymentInfo}
          paymentInfoError={paymentInfoError}
          establishmentData={establishmentData}
          establishmentDataError={establishmentDataError}
          customerData={customerData}
          customerDataError={customerDataError}
          fiscalData={fiscalData}
          fiscalDataError={fiscalDataError}
          dataForm={dataForm}
          cancelButtonPath={cancelButtonPath}
          getIndexValue={getIndexValue}
          handlePlanChange={handlePlanChange}
          handleStateChange={handleStateChange}
          focus={focus}
          setFocus={setFocus}
          cardBrand={cardBrand}
          controlStylesHasError={controlStylesHasError}
          total={total}
        />
      )}

      <Modal onClose={handleModalErrorClose} isOpen={modalErrorIsOpen}>
        <ModalHeader onClose={handleModalErrorClose}>Error</ModalHeader>
        <ModalBody>
          <span className="has-text-danger">
            <Icon path={mdiCloseCircle} title={"Error"} size={5} />
            <p className="is-size-1">{globalError}</p>
          </span>
        </ModalBody>
        <ModalFooter>
          <button
            className="button jb-modal-close"
            onClick={handleModalErrorClose}
          >
            Aceptar
          </button>
        </ModalFooter>
      </Modal>

      <Modal
        onClose={handleModalConfirmFiscalDataClose}
        isOpen={modalConfirmFiscalDataIsOpen}
      >
        <ModalHeader onClose={handleModalConfirmFiscalDataClose}>
          Confirmar Datos Fiscales
        </ModalHeader>
        <ModalBody>
          <div className="has-text-centered">
            <span className="has-text-warning">
              <Icon path={mdiAlertCircleOutline} title={"OK"} size={5} />
            </span>
            <div className="is-size-5">
              {"Confirme sus datos fiscales, son correctos?"}
              <ul className="has-text-left ml-5">
                <li> Rfc: {fiscalData.rfc} </li>
                <li> IdCIF: {fiscalData.idCIF} </li>
                <li> Correo: {fiscalData.email} </li>
              </ul>
              <p>
                <span className="has-text-danger">**</span>{" "}
                <b>Su factura le llegara en los próxímos días</b>
              </p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="button jb-modal-close is-success"
            onClick={okFiscalData}
          >
            Aceptar
          </button>
          <button
            className="button jb-modal-close is-danger"
            onClick={handleModalConfirmFiscalDataClose}
          >
            Corregir Datos Fiscales
          </button>
        </ModalFooter>
      </Modal>
      <Modal onClose={handleModalConfirmClose} isOpen={modalConfirmIsOpen}>
        <ModalHeader onClose={handleModalConfirmClose}>
          Confirmar Datos
        </ModalHeader>
        <ModalBody>
          <div className="has-text-centered">
            <span className="has-text-warning">
              <Icon path={mdiAlertCircleOutline} title={"OK"} size={5} />
            </span>
            <div className="is-size-5">
              {"Confirme sus datos, son correctos?"}
              <ul className="has-text-left ml-5">
                <li> Establecimiento: {establishmentData.name} </li>
                <li> Sucursales: {establishmentData.branchs} </li>
                <li> Estado: {customerData.state} </li>
                <li> Nombre: {customerData.name} </li>
                <li> Telefono: {customerData.phone} </li>
                <li> Correo: {customerData.email} </li>
                <li> Cuenta: {paymentInfo.account} </li>
                <li>
                  {" "}
                  Esquema: {getSchemeLabelFromPlans(customerData.plan_id)}{" "}
                </li>
              </ul>
            </div>
            <p>
              <span className="has-text-danger">**</span>
              <b>
                Al Aceptar, se genera un cargo prueba de $5 pesos, este monto se
                retornara a su tarjeta por parte de nuestro Procesador de Pago
                en los próximos días.
              </b>
            </p>
          </div>
        </ModalBody>

        <ModalFooter>
          <button
            className="button jb-modal-close is-success"
            onClick={handleOnConfirm}
          >
            Aceptar
          </button>
          <button
            className="button jb-modal-close is-danger"
            onClick={handleModalConfirmClose}
          >
            Corregir Datos
          </button>
        </ModalFooter>
      </Modal>

      <Modal onClose={handleSuccessModalClose} isOpen={modalSuccessIsOpen}>
        <ModalHeader onClose={handleSuccessModalClose}>
          Afiliación Completada
        </ModalHeader>
        <ModalBody>
          <div className="has-text-centered">
            <span className="has-text-success">
              <Icon path={mdiCheckBold} title={"OK"} size={5} />
            </span>
            <p className="has-text-success">
              Su registro ha sido procesado, el cargo completo se realizará el
              día aquí colocado. Se iniciará con la alta del negocio en la
              aplicación.
            </p>
          </div>
        </ModalBody>

        <ModalFooter>
          <button
            className="button jb-modal-close"
            onClick={handleSuccessModalClose}
          >
            Aceptar
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};
const {
  //actions
  httpGet,
  httpPost,
} = httpService;

const mapDispatchToProps = {
  //actions
  httpGet,
  httpPost,
};

export default connect(null, mapDispatchToProps)(AfiliationController);
