import { mdiStore, mdiStorePlus, mdiStoreRemove } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { connect } from 'react-redux';


import { DateRangePicker } from 'react-dates';
import * as moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import isInclusivelyBeforeDay from 'react-dates/src/utils/isInclusivelyBeforeDay';

import * as httpService from '../../http-service/httpService';

import Nothing from '../../components/utils/Nothing';
import OpportunityDashboardChart from '../../components/charts/OpportunityDashboardChart';
import DashboardEjecutivo from '../../components/dashboards/DashboardEjecutivo';
import DashboardAdmin from '../../components/dashboards/DashboardAdmin';

const Dashboard = ({ httpGet, ...props }) => {
  
  return (
    <>
      {props.user_data.groups.includes('Admin') ? 
        <DashboardAdmin /> :
        <DashboardEjecutivo />
      }
    </>
  )
};

const mapStateToProps = ({ LoginReducer }) => LoginReducer;

const { //actions
  httpGet
} = httpService;

const mapDispatchToProps = { //actions
  httpGet
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);