import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";

const ActivityNew = (props) => {
  let classSelectActivityType = "select";
  classSelectActivityType += props.activityError.activity_type ? " is-danger" : "";
  let classSelectContact = "select";
  classSelectContact += props.activityError.contact ? " is-danger" : "";
  return (
    <>
      <article className="panel is-primary">
        <p className="panel-heading">Agregar Actividad</p>
        <form onSubmit={props.handleOnSubmit}>
          <div className="panel-block">
            <div className="panel-tabs" style={{ alignItems: "flex-start" }}>
              <div className="field mr-5">
                <label className="label">Nombre de la Actividad</label>
                <div className="control">
                  <input
                    onChange={props.handleOnChangeActivity}
                    value={props.activity.name}
                    type="text"
                    className={
                      props.activityError.name ? "input is-danger" : "input"
                    }
                    name="name"
                    placeholder="Reunion de Presentación"
                  />
                </div>
                {props.activityError.name && (
                  <p className="help is-danger">{props.activityError.name}</p>
                )}
              </div>
              <div className="field mr-5">
                <label className="label">Fecha</label>
                <DatePicker
                  selected={props.date}
                  onChange={(date) => props.setDate(date)}
                  showTimeSelect
                  className="Input"
                  dateFormat="yyyy/MM/dd HH:mm"
                  minDate={new Date()}
                  minTime={setHours(setMinutes(new Date(), 0), 9)}
                  maxTime={setHours(setMinutes(new Date(), 30), 20)}
                />
              </div>
              <div className="field mr-5">
                <label className="label">Tipo de Actividad</label>
                <div className="control">
                  <div className={classSelectActivityType}>
                    <select
                      name="activity_type"
                      value={props.activity.activity_type}
                      onChange={props.handleOnChangeActivity}
                      className={classSelectActivityType}
                    >
                      <option value="">------------</option>
                      {props.dataForm.activityTypes.map(item => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {props.activityError.activity_type && (
                    <p className="help is-danger">
                      {props.activityError.activity_type}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="panel-block">
            <div className="panel-tabs" style={{ alignItems: "flex-start" }}>
              <div className="field mr-5">
                <label className="label">Descripción</label>
                <div className="control">
                  <textarea
                    onChange={props.handleOnChangeActivity}
                    value={props.activity.description}
                    type="text"
                    className={
                      props.activityError.description
                        ? "textarea is-danger"
                        : "textarea"
                    }
                    name="description"
                  />
                </div>
                {props.activityError.description && (
                  <p className="help is-danger">
                    {props.activityError.description}
                  </p>
                )}
              </div>
              <div className="field">
                <label className="label">Contacto</label>
                <div className="control">
                  <div className={classSelectContact}>
                    <select
                      name="contact"
                      value={props.activity.contacts}
                      onChange={props.handleOnChangeActivity}
                      required={false}
                    >
                      <option value="">------------</option>
                      {props.dataForm.contacts.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {props.activityError.contacts && (
                  <p className="help is-danger">
                    {props.activityError.contacts}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="panel-block" style={{ justifyContent: "flex-end" }}>
            <button type="submit" className="button is-primary mr-1">
              Agregar
            </button>
            <button type="button" className="button" onClick={props.handleOnClickNewActivity} >
              Cancelar
            </button>
          </div>
        </form>
      </article>
    </>
  );
};

export default ActivityNew;
