import React from 'react';
import Icon from '@mdi/react';
import { mdiEmoticonDead } from '@mdi/js';


const Error404 = (props) => {
    return (
        <section className="container my-5">
            <div className="columns is-mobile is-centered my-5">
                <div className="column is-narrow ">
                    <h1 className="title">
                        <span className=""><Icon size={5} path={mdiEmoticonDead} title={"Error 404"} />
                            <i></i>
                        </span>
                    </h1>
                </div>
            </div>
            <div className="columns is-mobile is-centered">
                <div className="column is-narrow is-centered">
                    <h1>Lo sentimos la pagina que estas buscando no esta disponible. (Error 404)</h1>
                </div>
            </div>
        </section>
    )
};

export default Error404;