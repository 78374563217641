import React from 'react';
import { useState } from 'react';

import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '300px'
};


const MapLocation = ({ clickedLatLng, onClick, ...props }) => {
    const [center, setCenter] = useState(clickedLatLng);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDQiqdGICBINHjEj0AKfDxTc8-f7UMrRnE"
    })

    const onLoad = React.useCallback(function callback(map) {
        setCenter(clickedLatLng)
        map.setOptions({
            panControl: false,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            overviewMapControl: false,
            rotateControl: false
        })
    }, [])

    return (isLoaded ?
        <GoogleMap
            onLoad={onLoad}
            // Save the current center position in state
            // Save the user's map click position
            onClick={e => onClick(e)}
            center={center}
            defaultZoom={10}
            zoom={15}
            mapContainerStyle={containerStyle}
        >
            <Marker
                position={clickedLatLng}
                draggable={false}
            />

            <></>
        </GoogleMap>
        : <></>

    )

}

export default MapLocation;
