import React, { useMemo } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

import * as httpService from '../../http-service/httpService';

import Icon from '@mdi/react';
import {
  mdiPlus, mdiPencil, mdiTrashCan, mdiBookAccount, mdiCheckBold,
  mdiCloseCircle, mdiAlertCircleOutline, mdiCurrencyUsd, mdiSend
} from '@mdi/js';
import PaginatedTable from '../../components/tables/PaginatedTable';

import Modal from '../../components/utils/Modal';
import Loading from '../../components/utils/Loading';
import SendAfiliationForm from '../../components/afiliation/SendAfiliationForm';

const ManagerListEstablishments = ({ httpPost, ...props }) => {
  const [data, setData] = React.useState([]);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [afiliationFormIsOpen, setAfiliationFormIsOpen] = React.useState(false);
  const [okMessage, setOkMessage] = React.useState('');
  const [globalError, setGlobalError] = React.useState([]);
  const [reload, setReload] = React.useState(0);
  const [rowToDelete, setRowToDelete] = React.useState({
    name: ''
  });
  // Estado, Rol del usuario, Nombre de usuario, nombre del negocio, Fecha de creación.
  const columns = useMemo(
    () => [
      {
        Header: 'U_estado',
        accessor: 'user_state'
      },
      {
        Header: 'Rol',
        accessor: 'user_rol'
      },
      {
        Header: 'Usuario',
        accessor: 'user'
      },
      {
        Header: "E_estado",
        accessor: "state",
      },
      {
        Header: "Nombre",
        accessor: "name",
      },
      {
        Header: "Creado",
        accessor: "registered_on",
        disableFilters: true,
      },
      {
        Header: "",
        disableFilters: true,
        accessor: "actions_data",
        Cell: ({ row: { values }, cell: { value } }) => (
          <div className="buttons is-right">
            <Link className="button is-small is-primary" to={{ pathname: `/establishment/edit/${value.uuid}`, state: { from: props.location } }}>
              <span className="icon"><Icon path={mdiPencil} size={1} title={"Editar Establecimiento"} /></span>
            </Link>
            {value.has_bank_data ?
              <Link className="button is-small is-primary" to={{ pathname: `/bankData/detail/${value.uuid}`, state: { from: props.location } }}>
                <span className="icon is-small"><Icon path={mdiCurrencyUsd} title={"Datos Bancarios"} size={'1rem'} /></span>
              </Link> :
              <Link className="button is-small is-warning" to={{ pathname: `/bankData/create/${value.uuid}`, state: { from: props.location } }}>
                <span className="icon is-small"><Icon path={mdiCurrencyUsd} title={"Datos Bancarios"} size={'1rem'} /></span>
              </Link>
            }
            <Link className="button is-small is-success" to={{ pathname: `/contact/list/${value.uuid}`, state: { from: props.location } }}>
              <span className="icon is-small"><Icon path={mdiBookAccount} title={"Contactos"} size={'1rem'} /></span>
            </Link>
            <button className="button is-small is-danger jb-modal" onClick={() => handleOnClickDelete(values)} type="button">
              <span className="icon is-small"><Icon path={mdiTrashCan} size={'1rem'} /></span>
            </button>
          </div>
        )
      }
    ],
    []
  );

  const handleOnClickDelete = (row) => {
    setRowToDelete(row)
    setModalIsOpen(true)
  }

  const handleOnModalClose = () => {
    console.log("hola");
    setModalIsOpen(false);
    setAfiliationFormIsOpen(false);
    setGlobalError([]);
    setRowToDelete({ name: '' });
    setOkMessage('')
  }

  const deleteEstablishment = () => {
    console.log(rowToDelete.name + " Eliminado");
    var url = `/api/establishment/delete/${rowToDelete.actions_data.uuid}/`;
    setReload(true);
    httpPost(url)
      .then((response) => {
        handleOnModalClose();
        setReload(false);
        setModalIsOpen(true);
      })
      .catch((error) => console.log(error))
  }
  // porcentaje tambien en numero
  return (
    <>
      <section className="section is-main-section">
        <div className="columns">
          <div className="column has-text-right">
            <Link className="button is-warning" to={{ pathname: '/establishmentBank/new', state: { from: props.location } }}>
              <span>Agregar con Datos Bancarios</span>
              <span className="icon is-small">
                <Icon path={mdiPlus} size={"1rem"} />
              </span>
            </Link>
            <Link className="button is-info  ml-3" to={{ pathname: '/establishment/new', state: { from: props.location } }}>
              <span>Agregar Establecimiento</span>
              <span className="icon is-small">
                <Icon path={mdiPlus} size={"1rem"} />
              </span>
            </Link>
            {(props.user_data.groups.includes('Admin') || props.user_data.groups.includes('Agencia Distribuidor') || props.user_data.groups.includes('Ejecutivo')) &&
              <button className="button is-success  ml-3" onClick={() => setAfiliationFormIsOpen(true)}>
                <span>Enviar Afiliacion</span>
                <span className="icon is-small">
                  <Icon path={mdiSend} size={"1rem"} />
                </span>
              </button>
            }
          </div>
        </div>
        {reload ? <Loading /> :
          <PaginatedTable
            columns={columns}
            url={`/api/managers/list/establishment/`}
            data={data}
            setData={setData}
            exportToExcel={true}
            url_excel={`/api/managers/excel/establishment/?format=xlsx`}
            excelFileName={'Establecimientos'}
          />
        }

        <Modal
          onClose={handleOnModalClose}
          isOpen={afiliationFormIsOpen || modalIsOpen || globalError.length > 0}
          isForm={afiliationFormIsOpen}
        >

          <header className="modal-card-head">
            <p className="modal-card-title is-size-4">{globalError.length > 0 ? "Error" : afiliationFormIsOpen ? "Enviar Afiliación" : okMessage}</p>
            <button onClick={handleOnModalClose} className="delete jb-modal-close" aria-label="close"></button>
          </header>
          <section className="modal-card-body"
            style={afiliationFormIsOpen ? {
              borderBottomLeftRadius: '6px',
              borderBottomRightRadius: '6px'
            } : {}}
          >
            <div className="columns is-centered">
              <div className={afiliationFormIsOpen ? "column" : "column has-text-centered"} >
                {(globalError.length > 0 ?
                  <span className="has-text-danger">
                    <Icon path={mdiCloseCircle} title={'OK'} size={5} />
                    <p className="is-size-1">{globalError}</p>
                  </span>
                  : afiliationFormIsOpen ?
                    <SendAfiliationForm
                      onSuccess={() => { handleOnModalClose(); setOkMessage('Afiliacion Enviada'); setModalIsOpen(true); }}
                      onClickCancelButton={handleOnModalClose}
                      setOkMessage={setOkMessage}
                      setGlobalError={setGlobalError}
                    />
                    : rowToDelete.name.length > 0 ?
                      <span className="has-text-danger">
                        <Icon path={mdiAlertCircleOutline} title={'OK'} size={5} />
                        <p className="is-size-5">{"Seguro que quires eliminar el establecimiento " + rowToDelete.name + "?"}</p>
                      </span>
                      : <span className="has-text-success">
                        <Icon path={mdiCheckBold} title={'OK'} size={5} />
                      </span>)
                }
              </div>
            </div>
          </section>
          {!afiliationFormIsOpen &&
            <footer className="modal-card-foot">
              {rowToDelete.name.length > 0 ? <>
                <button className="button jb-modal-close" onClick={deleteEstablishment}>Si, Eliminar</button>
                <button className="button jb-modal-close is-danger" onClick={handleOnModalClose}>No, Gracias</button>
              </> :
                <button className="button jb-modal-close" onClick={handleOnModalClose}>Aceptar</button>
              }
            </footer>
          }
        </Modal>
      </section>
    </>
  )
};

const mapStateToProps = ({ LoginReducer }) => LoginReducer;

const { //actions
  httpPost
} = httpService;

const mapDispatchToProps = { //actions
  httpPost
};
export default connect(mapStateToProps, mapDispatchToProps)(ManagerListEstablishments);