import React from 'react';
import { connect } from 'react-redux';

import * as httpService from '../../http-service/httpService';

import Card from '../../components/Card';
import UserController from '../../components/user/UserController';



const UserEdit = ({httpGet, match: { params: { userUuid } }, ...props }) => {
  const urlPost = `/api/user/edit/${userUuid}/`
  const [user, setUser] = React.useState({
    name: '',
    lastname: '',
    email: '',
    cellphone: '',
    state: '',
    groups: '',
    in_charge: '',
  });
  const handleSubmitSuccess = () => {
    props.history.goBack()
  }

  React.useEffect(()=>{
    httpGet(`/api/user/detail/${userUuid}/`)
      .then(response => {
        setUser(prev => ({...prev,...response.data}));
      })
      .catch(error => {
        console.log(error);
      });
  },[httpGet]);
  return (
    <>
      <section className="section is-main-section">
        <Card title={'Editar Usuario '+ user.email}>
          <UserController
            user={user}
            handleSubmitSuccess={handleSubmitSuccess}
            cancelButtonPath={props.history.goBack}
            urlPost={urlPost}
          />
        </Card>
      </section>
    </>
  )
};
const { //actions
  httpGet
} = httpService;

const mapDispatchToProps = { //actions
  httpGet
};

export default connect(null, mapDispatchToProps)(UserEdit);