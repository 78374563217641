import React from 'react';
import { connect } from 'react-redux';

import Icon from '@mdi/react';
import { mdiCloseCircle, mdiCheckBold, mdiAlertCircleOutline } from '@mdi/js';

import * as httpService from '../../http-service/httpService';
import { isValidPhoneNumber, isValidAccount, isValidClabe } from '../utils/Validators';

import BankDataForm from '../bankData/BankDataForm';
import Modal from '../utils/Modal';
import Loading from '../utils/Loading';


const BankDataController = ({ bankDat, contactDat, handleSubmitSuccess, cancelButtonPath, httpGet, httpPost, urlPost, ...props }) => {

  const [bankData, setBankData] = React.useState(bankDat);
  const [contact, setContact] = React.useState(contactDat);
  const [loading, setLoading] = React.useState(false);
  const [confirm, setConfirm] = React.useState(false);

  React.useEffect(() => {
    setBankData(bankDat);
  }, [bankDat])

  const [bankDataError, setBankDataError] = React.useState({
    bank_id: '',
    account: '',
    clabe: '',
  });
  const [dataForm, setDataForm] = React.useState({
    positions: [],
    banks: []
  });

  const [contactError, setContactError] = React.useState({
    name: '',
    email: '',
    position: '',
    cellphone: '',
  });

  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [globalError, setGlobalError] = React.useState([]);

  React.useEffect(() => {
    httpGet('/api/establishment/bankdata/utils/')
      .then(response => {
        setDataForm({ positions: response.data.positions, banks: response.data.banks });
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }, [httpGet]);
  const resetErrorContact = (field) => {
    setContactError(err => ({ ...err, [field]: '' }));
  }
  const resetErrorBankData = (field) => {
    setBankDataError(err => ({ ...err, [field]: '' }));
  }

  const handleOnChangeContact = (e) => {
    const contacto = { ...contact };
    contacto[e.target.name] = e.target.value;
    resetErrorContact(e.target.name);
    setContact(contacto);
    if (e.target.name === 'cellphone' && !isValidPhoneNumber(e.target.value)) {
      setContactError(err => ({ ...err, cellphone: ['Ingrese un numero a 10 digitos se permiten espacios y "-"'] }))
    }
  }

  const handleOnChangeBankData = (e) => {
    const bank = { ...bankData };
    bank[e.target.name] = e.target.value;
    resetErrorBankData(e.target.name);
    if (e.target.name === 'bank_id' && parseInt(e.target.value) > 0) {
      dataForm.banks.map((item) => {
        if (item.id === parseInt(e.target.value)) {
          bank['bank_name'] = item.name
        }
      });
    }
    if (e.target.name === 'account' && !isValidAccount(e.target.value)) {
      setBankDataError(err => ({ ...err, account: ['Valide que el numero sea correcto. En este campo solo se permiten numeros. No debe de contener espacios.'] }))
    }
    if (e.target.name === 'clabe' && !isValidClabe(e.target.value)) {
      setBankDataError(err => ({ ...err, clabe: ['Valide que el numero sea correcto. En este campo solo se permiten numeros. No debe de contener espacios.'] }))
    }
    setBankData(bank);
  }
  const validateForm = () => {
    let valid_cont = true;
    let error_cont = {};
    let valid_bank = true;
    let error_bank = {};

    Object.keys(contact).forEach((item) => {
      if (contact[item].length === 0) {
        error_cont[item] = ['Este campo es requerido']
        valid_cont = false
      }
      if (item === 'cellphone') {
        error_cont[item] = ['ingrese un numero a 10 digitos se permiten espacios y "-"'];
        valid_cont = isValidPhoneNumber(contact[item]);
      }
    });
    if (!valid_cont) {
      setContactError(err => ({ ...err, ...error_cont }));
    }

    Object.keys(bankData).forEach((item) => {
      if (bankData[item].length === 0) {
        error_bank[item] = ['Este campo es requerido']
        valid_bank = false
      }
      if (item === 'account' && !isValidAccount(bankData[item])) {
        error_bank[item] = ['Valide que el numero sea correcto. En este campo solo se permiten numeros. No debe de contener espacios.']
        valid_bank = false
      }
      if (item === 'clabe' && !isValidClabe(bankData[item])) {
        error_bank[item] = ['Valide que el numero sea correcto. En este campo solo se permiten numeros. No debe de contener espacios.']
        valid_bank = false
      }
    });
    console.log(valid_bank);
    if (!valid_bank) {
      setBankDataError(err => ({ ...err, ...error_bank }));
    }

    return valid_cont && valid_bank;
  }
  const handleOnSubmit = (e) => {
    e.preventDefault();

    let is_valid = validateForm();
    if (is_valid) {
      setModalIsOpen(true);
      setConfirm(true);
    }
  }

  const handleOnConfirm = (e) => {
    setModalIsOpen(false);
    setConfirm(false);
    setLoading(true);
    httpPost(urlPost, { contact, bankData })
      .then(response => {
        setModalIsOpen(true);
      })
      .catch(error => {
        setBankDataError(err => ({ ...err, ...error.response.data.bankData }))
        setContactError(err => ({ ...err, ...error.response.data.contact }))
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleOnModalClose = () => {
    setModalIsOpen(false);
    if (!confirm) {
      handleSubmitSuccess()
    }
  }

  return (
    <>
      {loading ?
        <div className="container">
          <div className="columns is-centered">
            <div className="columns is-narrow">
              <Loading className='has-background-dark' />
            </div>
          </div>
        </div>
        :
        <BankDataForm
          handleOnSubmit={handleOnSubmit}
          handleOnChangeBankData={handleOnChangeBankData}
          handleOnChangeContact={handleOnChangeContact}
          bankData={bankData}
          bankDataError={bankDataError}
          contact={contact}
          contactError={contactError}
          dataForm={dataForm}
          cancelButtonPath={cancelButtonPath}
        />
      }
      <Modal onClose={handleOnModalClose} isOpen={modalIsOpen || globalError.length > 0} >
        <header className="modal-card-head">
          <p className="modal-card-title is-size-4">{globalError.length > 0 ? "Error" : "Datos Bancarios Agregados"}</p>
          <button onClick={handleOnModalClose} className="delete jb-modal-close" aria-label="close"></button>
        </header>
        <section className="modal-card-body">
          <div className="columns is-centered">
            <div className="column has-text-centered">
              {(globalError.length > 0 ?
                <span className="has-text-danger">
                  <Icon path={mdiCloseCircle} title={'OK'} size={5} />
                  <p className="is-size-1">{globalError}</p>
                </span>
                : confirm ?
                  <div className="has-text-centered">
                    <span className="has-text-warning">
                      <Icon path={mdiAlertCircleOutline} title={'OK'} size={5} />
                    </span>
                    <div className="is-size-5">{"Seguro que tus datos bancarios son correctos?"}
                      <ul>
                        <li> Banco: {bankData.bank_name} </li>
                        <li> Cuenta: {bankData.account} </li>
                        <li> Clabe Interbancaria: {bankData.clabe} </li>
                      </ul>
                    </div>
                  </div>
                  : <span className="has-text-success">
                    <Icon path={mdiCheckBold} title={'OK'} size={5} />
                  </span>)}
            </div>
          </div>
        </section>
        <footer className="modal-card-foot" style={{ 'justifyContent': 'flex-end' }}>
          {confirm ? <>
            <button className="button jb-modal-close is-success" onClick={handleOnConfirm}>Aceptar</button>
            <button className="button jb-modal-close is-danger" onClick={handleOnModalClose}>Cancel</button>
          </> :
            <button className="button jb-modal-close" onClick={handleOnModalClose}>Aceptar</button>

          }
        </footer>
      </Modal>
    </>
  )
};
const { //actions
  httpGet, httpPost
} = httpService;

const mapDispatchToProps = { //actions
  httpGet, httpPost
};

export default connect(null, mapDispatchToProps)(BankDataController);