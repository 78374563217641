import React from "react";

import { fiscalDataInitErrors, fiscalDataInitValues } from "./initValues";
import { useFiscalDataValidators } from "./useFiscalDataValidators";

export const useFiscalDataForm = ({
  initialValues = fiscalDataInitValues,
  initialErrors = fiscalDataInitErrors,
}) => {
  const [fiscalData, setFiscalData] = React.useState(initialValues);
  const [fiscalDataError, setFiscalDataError] =
    React.useState(fiscalDataInitErrors);
  const { validateRfc, validateFiscalDocument, validateEmail, validateIdCif } =
    useFiscalDataValidators();

  const resetError = (field) => {
    setFiscalDataError((prev) => ({ ...prev, [field]: "" }));
  };

  React.useEffect(() => {
    setFiscalData(initialValues);
  }, [initialValues]);

  React.useEffect(() => {
    setFiscalDataError(initialErrors);
  }, [initialErrors]);

  const getFieldValue = (e) => {
    let value = "";
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    } else if (e.target.type === "file") {
      value = e.target.files[0];
    } else {
      value = e.target.value;
    }
    return value;
  };

  const handleOnChangeFiscalData = (e) => {
    const field = e.target.name;
    const value = getFieldValue(e);
    setFiscalData((prev) => ({ ...prev, [field]: value }));
    resetError(field);
    try {
      if (field === "rfc") {
        validateRfc(value);
      } else if (field === "idCIF") {
        validateIdCif(value);
      } else if (field === "fiscal_document") {
        validateFiscalDocument(value);
      } else if (field === "email") {
        validateEmail(value);
      }
    } catch (error) {
      setFiscalDataError((err) => ({
        ...err,
        [error.field]: [error.message],
      }));
    }
  };

  const validateForm = () => {
    let error_fisclaData = {};
    Object.keys(fiscalData).forEach((item) => {
      if (
        item !== "not_factura" &&
        (fiscalData[item] === null ||
          fiscalData[item] === undefined ||
          fiscalData[item].length === 0)
      ) {
        error_fisclaData[item] = ["Este campo es requerido"];
      }
    });
    console.log({ error_fisclaData })
    if (Object.keys(error_fisclaData).length) {
      setFiscalDataError((prev) => ({ ...prev, ...error_fisclaData }));
      return false;
    }
    try {
      validateRfc(fiscalData.rfc);
      validateIdCif(fiscalData.idCIF);
      validateEmail(fiscalData.email);
      //validateFiscalDocument(fiscalData.fiscal_document);
    } catch (error) {
      console.log({ error });
      error_fisclaData[error.field] = error.message;
      setFiscalDataError((prev) => ({ ...prev, ...error_fisclaData }));
      return false;
    }
    return true;
  };

  return {
    handleOnChangeFiscalData,
    validateForm,
    validateEmail,
    validateRfc,
    validateIdCif,
    validateFiscalDocument,
    fiscalData,
    fiscalDataError,
    setFiscalData,
    setFiscalDataError,
  };
};
