import React from 'react';
import ReactDOM from 'react-dom';

import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';

import reducers from './reducers/index';

import './index.scss';

import App from './components/App';
import { LOGIN } from './types/LoginTypes';
const user = JSON.parse(localStorage.getItem('current_user'));
const store = createStore(
  reducers, // todos los reducers
  {}, // estado inicial
  applyMiddleware(reduxThunk)
);
if (user){
  store.dispatch({type:LOGIN, payload: user});
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={ store }>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('app')
);
