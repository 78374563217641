import axios from 'axios';

import { LOADING, GET_TOKEN, ERROR, RESET_ERROR, LOGOUT, LOGIN } from "../types/LoginTypes";
const URL_SERVER = process.env.REACT_APP_SERVER_URL;

export const login = (credentials) => (dispatch) => {
  dispatch({
    type: LOADING
  });

  axios.post(URL_SERVER + '/api/token/', credentials)//,{headers:{"Content-Type": "application/json"}});
    .then(response => {
      const base64Url = response.data.access.split('.')[1]
      const base64 = base64Url.replace('-', '+').replace('_', '/')
      // what is window.atob ?
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/atob
      const user_data = JSON.parse(window.atob(base64));
      const data = {token:{...response.data}, user_data};
      dispatch({
        type: LOGIN,
        payload: data
      });
      localStorage.setItem('current_user',JSON.stringify(data));
    })
    .catch(({response}) => {
      console.log(response.data);
      dispatch({
        type: ERROR,
        payload: response.data
      });
    });
}

export const loginRefresh = () => (dispatch) => {
  dispatch({
    type: LOADING
  });

  axios.post(URL_SERVER + '/api/token/refresh/')//,{headers:{"Content-Type": "application/json"}});
    .then(response => {
      const base64Url = response.data.access.split('.')[1]
      const base64 = base64Url.replace('-', '+').replace('_', '/')
      // what is window.atob ?
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/atob
      const user_data = JSON.parse(window.atob(base64));
      dispatch({
        type: GET_TOKEN,
        payload: {token:{...response.data}, user_data}
      })
    })
    .catch(({response}) => {
      dispatch({
        type: LOGOUT
      });
    });
}

export const resetError = () => (dispatch) => dispatch({type: RESET_ERROR});

export const setErrorField = (error) => (dispatch) => dispatch({type:ERROR,payload:error});

export const logoutUser = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
}