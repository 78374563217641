import React from "react";
import Modal from "../utils/Modal";
import Icon from "@mdi/react";
import { mdiCheckBold } from "@mdi/js";
import { mdiCloudUpload } from "@mdi/js";
import { FormInput } from "../FormInput";
import IdCIFExampleImg from '../../styles/static/id_cif_example.png';

const FiscalDataForm = (props) => {
  return (
    <>
      <form onSubmit={props.handleOnSubmit}>
        <div className="columns is-multiline">
          <FormInput
            is={5}
            isMobile={12}
            label="RFC"
            name="rfc"
            value={props.fiscalData.rfc.toUpperCase()}
            onChange={props.handleOnChangeForm}
            type="text"
            placeholder="EWK2008158D7"
            error={props.fiscalDataError.rfc}
          />

          <FormInput
            is={5}
            isMobile={12}
            isOffset={1}
            label="idCIF"
            name="idCIF"
            value={props.fiscalData.idCIF.toUpperCase()}
            onChange={props.handleOnChangeForm}
            type="text"
            placeholder="16070424307"
            error={props.fiscalDataError.idCIF}
          />

          <FormInput
            is={5}
            isMobile={12}
            label="Email"
            placeholder="facturacion@correo.com"
            name="email"
            value={props.fiscalData.email}
            onChange={props.handleOnChangeForm}
            type="text"
            error={props.fiscalDataError.email}
          />
          {props.showNotFactura && (
            <div className="column is-6 is-12-mobile has-text-centered">
              <div className="field">
                <div className="control">
                  <label className="label">
                    <input
                      onChange={props.handleOnChangeForm}
                      name="not_factura"
                      checked={props.fiscalData.not_factura}
                      className={
                        props.fiscalDataError.not_factura ? "is-danger" : ""
                      }
                      type="checkbox"
                    />{" "}
                    No requiere Factura
                  </label>
                </div>
                {props.fiscalDataError.not_factura && (
                  <p className="help is-danger">
                    {props.fiscalDataError.not_factura}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
        <hr />
        <div className="field is-grouped is-grouped-right">
          <p className="control">
            <button type="submit" className="button is-primary">
              Aceptar
            </button>
          </p>
          <p className="control">
            <button
              onClick={props.cancelButtonPath}
              type="button"
              className="button is-light"
            >
              Cancel
            </button>
          </p>
        </div>
      </form>
      <Modal onClose={props.handleOnModalClose} isOpen={props.modalIsOpen}>
        <header className="modal-card-head">
          <p className="modal-card-title is-size-4">Establecimiento agregado</p>
          <button
            onClick={props.handleOnModalClose}
            className="delete jb-modal-close"
            aria-label="close"
          ></button>
        </header>
        <section className="modal-card-body">
          <div className="columns is-centered">
            <div className="column is-narrow">
              <span className="has-text-success">
                <Icon path={mdiCheckBold} title={"OK"} size={5} />
              </span>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button
            className="button jb-modal-close"
            onClick={props.handleOnModalClose}
          >
            Aceptar
          </button>
        </footer>
      </Modal>
    </>
  );
};

export default FiscalDataForm;
