import React from 'react';
import Icon from '@mdi/react';
import { mdiEmoticonSad } from '@mdi/js';


const Nothing = (props) => {

  return (
    <>
      <div className="card has-table">
        <div className="card-content">
          <section className="section">
            <div className="content has-text-grey has-text-centered">
              <p>
                <span className="icon is-large"><Icon path={mdiEmoticonSad} title={"Nothing's..."} size={2} /></span>
              </p>
              <p>Nothing's here…</p></div>
          </section>
        </div>
      </div>
    </>
  )
};

export default Nothing;