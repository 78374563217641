import React, { useState, useEffect } from 'react';

import * as httpService from '../../http-service/httpService';
import { isValidPhoneNumber, isValidShcedule, isValidZipCode } from '../../components/utils/Validators';
import Card from '../../components/Card';
import { connect } from 'react-redux';

import Loading from '../../components/utils/Loading';
import RegisterEstablishmentForm from '../../components/forms/RegisterEstablishmentForm';


const RegisterEstablishment = ({ httpGet, httpPost, ...props }) => {
  const [permissions, setPermissions] = React.useState(props.user_data.groups);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [subcategories, setSubCategories] = useState([]);
  const [establishment, setEstablishment] = useState({
    name: '',
    category: '',
    subcategories: [],
    tags: [],
    description: '',
    phone: '',
    address: '',
    address_number1: '',
    address_number2: '',
    district: '',
    city: '',
    state: '',
    zip_code: '',
    schedule: '',
    url_fb: '',
    url_tw: '',
    url_ins: '',
    url_web: '',
  });
  const [dataForm, setDataForm] = useState({
    categories: [],
    subcategories: { data: [], isLoading: false },
    states: [],
    positions: [],
  });
  const [establishmentError, setEstablishmentError] = useState({
    name: '',
    category: '',
    subcategories: '',
    tags: '',
    description: '',
    phone: '',
    address: '',
    address_number1: '',
    address_number2: '',
    district: '',
    city: '',
    state: '',
    zip_code: '',
    schedule: '',
    url_fb: '',
    url_tw: '',
    url_ins: '',
    url_web: '',
  });
  const [contact, setContact] = useState({
    name: '',
    email: '',
    position: '',
    cellphone: '',
  });
  const [contactError, setContactError] = useState({
    name: '',
    email: '',
    position: '',
    cellphone: '',
  });

  const [loading, setLoading] = useState(true);
  const resetErrorEstablishment = (field) => {
    setEstablishmentError(err => ({ ...err, [field]: '' }));
  }
  const resetErrorContact = (field) => {
    setContactError(err => ({ ...err, [field]: '' }));
  }
  const handleOnChangeEstablishment = (e) => {
    const establecimiento = { ...establishment };
    establecimiento[e.target.name] = e.target.value;
    resetErrorEstablishment(e.target.name);
    setEstablishment(establecimiento);
    if (e.target.name === 'phone' && !isValidPhoneNumber(e.target.value)) {
      setEstablishmentError(err => ({ ...err, phone: ['ingrese un numero a 10 digitos se permiten espacios y "-"'] }))
    }
    if (e.target.name === 'schedule' && !isValidShcedule(e.target.value)) {
      setEstablishmentError(err => ({ ...err, schedule: ['Recuerda que puedes registrar hasta 3 horarios, respetando alguno de los formatos del ejemplo:Lun - Vie 11:00 a 23:00, Sáb 12:00 a 24:00, Dom 13:00 a 18:00'] }))
    }
    if (e.target.name === 'zip_code' && !isValidZipCode(e.target.value)) {
      setEstablishmentError(err => ({ ...err, zip_code: ['El codigo postal debe ser de 5 digitos'] }))
    }
    if (e.target.name === 'category') {
      console.log(e.target.value);
      console.log("category");
      getSubCategories(e.target.value)
    }
  }

  const getSubCategories = (category) => {
    setEstablishment(dat => ({ ...dat, subcategories: [] }));
    httpGet(`/api/establishment/subcategory/list/${category}/`)
      .then(response => {
        setDataForm(dat => {
          const newValue = { ...dat };
          newValue['subcategories'] = {
            ...dat['subcategories'], isLoading: false, data: response.data
          }
          return newValue;
        });
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }

  const handleSubCategoriesChange = (option) => {
    resetErrorEstablishment('subcategories');
    var rawValues = [];
    option.forEach((item) => {
      rawValues.push(item.value);
    });
    setSubCategories(option);
    setEstablishment(dat => ({ ...dat, subcategories: rawValues }));
  }

  const handleOnChangeTags = (tags) => {
    if (!(tags.slice(0, -1).indexOf(tags[tags.length - 1]) > -1))
      setEstablishment(dat => ({ ...dat, tags: tags }));
  }

  const handleOnChangeContact = (e) => {
    const contacto = { ...contact };
    contacto[e.target.name] = e.target.value;
    resetErrorContact(e.target.name);
    setContact(contacto);
    if (e.target.name === 'cellphone' && !isValidPhoneNumber(e.target.value)) {
      setContactError(err => ({ ...err, cellphone: ['ingrese un numero a 10 digitos se permiten espacios y "-"'] }))
    }
  }

  useEffect(() => {
    setLoading(true);
    httpGet('/api/establishment/utils/')
      .then(response => {
        // setDataForm({ categories: response.data.categories, states: response.data.states, positions: response.data.positions });
        setDataForm(dat => {
          const newValue = { ...dat };
          newValue['categories'] = response.data.categories
          newValue['states'] = response.data.states
          newValue['positions'] = response.data.positions
          return newValue
        })
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }, [httpGet]);

  const validateForm = () => {
    let valid_est = true;
    let error_est = {};
    let valid_cont = true;
    let error_cont = {};
    Object.keys(establishment).forEach((item) => {
      if (
        establishment[item].length === 0 &&
        item !== 'schedule' &&
        item !== 'description' &&
        item !== 'address_number2' &&
        item !== 'phone' &&
        item !== 'url_fb' &&
        item !== 'url_tw' &&
        item !== 'url_ins' &&
        item !== 'url_web' &&
        item !== 'subcategories' &&
        item !== 'tags'
      ) {
        error_est[item] = ['Este campo es requerido']
        valid_est = false
      }
      if (item === 'phone') {
        error_est[item] = ['ingrese un numero a 10 digitos se permiten espacios y "-"'];
        valid_est = isValidPhoneNumber(establishment[item]);
      }
      if (establishment[item].length > 0 && item === 'schedule' && !isValidShcedule(establishment[item])) {
        error_est[item] = ['Recuerda que puedes registrar hasta 3 horarios, respetando alguno de los formatos del ejemplo:Lun - Vie 11:00 a 23:00, Sáb 12:00 a 24:00, Dom 13:00 a 18:00'];
        valid_est = false;
      }
      if (establishment[item].length > 0 && item === 'zip_code' && !isValidZipCode(establishment[item])) {
        error_est[item] = ['El codigo postal debe ser de 5 digitos'];
        valid_est = false;
      }
    });
    if (!valid_est) {
      setEstablishmentError(err => ({ ...err, ...error_est }));
    }

    Object.keys(contact).forEach((item) => {
      if (contact[item].length === 0) {
        error_cont[item] = ['Este campo es requerido']
        valid_cont = false
      }
      if (item === 'cellphone') {
        error_cont[item] = ['ingrese un numero a 10 digitos se permiten espacios y "-"'];
        valid_cont = isValidPhoneNumber(contact[item]);
      }
    });
    if (!valid_cont) {
      setContactError(err => ({ ...err, ...error_cont }));
    }

    return valid_cont && valid_est;
  }
  const handleOnSubmit = (e) => {
    e.preventDefault();
    let is_valid = validateForm();
    if (is_valid) {
      setLoading(true);
      httpPost('/api/establishment/create/', { establishment, contact })
        .then(response => {
          setModalIsOpen(true);
        })
        .catch(error => {
          setEstablishmentError(err => ({ ...err, ...error.response.data.establishment }))
          setContactError(err => ({ ...err, ...error.response.data.contact }))
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  const handleOnModalClose = () => {
    setModalIsOpen(false);
    props.history.goBack();
  }
  return (
    <>
      <section className="section is-main-section">
        {
          (loading) ?
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-narrow">
                  <Loading />
                </div>
              </div>
            </div> :
            <Card title={"Agregar Establecimiento"}>
              <RegisterEstablishmentForm
                handleOnChangeEstablishment={handleOnChangeEstablishment}
                establishment={establishment}
                handleOnSubmit={handleOnSubmit}
                handleOnChangeContact={handleOnChangeContact}
                contact={contact}
                establishmentError={establishmentError}
                contactError={contactError}
                dataForm={dataForm}
                modalIsOpen={modalIsOpen}
                handleOnModalClose={handleOnModalClose}
                cancelButtonPath={props.history.goBack}
                handleSubCategoriesChange={handleSubCategoriesChange}
                subcategories={subcategories}
                handleOnChangeTags={handleOnChangeTags}
                permissions={permissions}
              />
            </Card>
        }
      </section>
    </>
  )
};

const mapStateToProps = ({ LoginReducer }) => LoginReducer;

const { //actions
  httpGet, httpPost
} = httpService;

const mapDispatchToProps = { //actions
  httpGet, httpPost
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterEstablishment);