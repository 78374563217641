import React from 'react'
import { useAsyncDebounce } from 'react-table'
import { DateRangePicker } from 'react-dates';
import * as moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import isInclusivelyBeforeDay from 'react-dates/src/utils/isInclusivelyBeforeDay';

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    setDateRange,
    setFocus,
    focus,
    dateRange,
    dateRangeActive = true,
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 300)

    return (<>

        {dateRangeActive &&
            <div className="column is-7">

                <DateRangePicker
                    startDate={dateRange.startDate} // momentPropTypes.momentObj or null,
                    startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                    endDate={dateRange.endDate} // momentPropTypes.momentObj or null,
                    endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                    onDatesChange={({ startDate, endDate }) => setDateRange({ startDate, endDate })} // PropTypes.func.isRequired,
                    focusedInput={focus} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={focusedInput => setFocus(focusedInput)} // PropTypes.func.isRequired,
                    // para habilitar o deshabilitar dias del calendario
                    isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                    displayFormat={moment.localeData().postformat("yyyy/MM/DD")}
                />
            </div>
        }
        <div className="column is-5">
            <div className="columns is-gapless">
                <div className="column is-one-quarter">
                    <p className="subtitle">
                        <strong>Búsqueda</strong>
                    </p>
                </div>
                <div className="column">
                    <div className="field">
                        <p className="control">
                            <input className="input" value={value || ""}
                                onChange={e => {
                                    setValue(e.target.value);
                                    onChange(e.target.value);
                                }}
                                placeholder={`${count} records...`}
                            />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default GlobalFilter;