import React from 'react';
import { connect } from 'react-redux';

import Icon from '@mdi/react';
import {
  mdiLockOpenVariantOutline, mdiTrashCan, mdiAccountPlus,
  mdiLockOutline,
  mdiCheckCircleOutline
} from '@mdi/js';

import * as httpService from '../../http-service/httpService';

import PaginatedTable from '../../components/tables/PaginatedTable';


const LostReasonsList = ({ httpPost, ...props }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Razon',
        disableFilters: true,
        accessor: 'name'
      },
      {
        Header: 'Usuario',
        accessor: 'user.email',
        disableFilters: true,
      },
      {
        Header: 'Fecha de alta',
        accessor: 'registered_on',
        disableFilters: true,
      },
      {
        Header: 'Acciones',
        disableFilters: true,
        accessor: 'approved',
        Cell: ({ cell: { value, row: { original, index } } }) => {
          return (
            <div className="buttons is-right">
              {
                value ?
                  <button className="button is-small is-success" type="button" disabled={original.loading}
                    onClick={() => handleAuthorization(value, original.id, index)}>
                    <span className="icon"><Icon path={mdiLockOpenVariantOutline} spin={original.loading} title={"Aprovado"} size={'1rem'} /></span>
                  </button>
                  :
                  <button className="button is-small is-danger" type="button" disabled={original.loading}
                    onClick={() => handleAuthorization(value, original.id, index)}>
                    <span className="icon"><Icon path={mdiLockOutline} spin={original.loading} title={"Rechazado"} size={'1rem'} /></span>
                  </button>
              }
              {
                original.active ?
                  <button className="button is-small is-success" type="button" disabled={original.loading}
                    onClick={() => handleDelete(original.active, original.id, index)}>
                    <span className="icon"><Icon path={mdiCheckCircleOutline} spin={original.loading} title={'activar'} size={'1rem'} /></span>
                  </button>
                  :
                  <button className="button is-small is-danger" type="button" disabled={original.loading}
                    onClick={() => handleDelete(original.active, original.id, index)}>
                    <span className="icon"><Icon path={mdiTrashCan} spin={original.loading} title={'desactivar'} size={'1rem'} /></span>
                  </button>
              }
            </div>
          )
        }
      },
    ],
    []
  )

  const [data, setData] = React.useState([])
  // const fetchIdRef = React.useRef(0)
  const handleAuthorization = (value, id_reason, index) => {
    setData(prev => {
      const newValue = [...prev];
      newValue[index] = {
        ...newValue[index], loading: true
      }
      return newValue;
    });
    httpPost(`/api/opportunity/lostreason/edit/${id_reason}/`, { approved: !value })
      .then(response => {
        setData(prev => {
          const newValue = [...prev];
          newValue[index] = {
            ...newValue[index], approved: !value
          }
          return newValue;
        });
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setData(prev => {
          const newValue = [...prev];
          newValue[index] = {
            ...newValue[index], loading: false
          }
          return newValue;
        });
      });
  }
  const handleDelete = (value, id_reason, index) => {
    setData(prev => {
      const newValue = [...prev];
      newValue[index] = {
        ...newValue[index], loading: true
      }
      return newValue;
    });
    httpPost(`/api/opportunity/lostreason/edit/${id_reason}/`, { active: !value })
      .then(response => {
        setData(prev => {
          const newValue = [...prev];
          newValue[index] = {
            ...newValue[index], active: !value
          }
          return newValue;
        });
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setData(prev => {
          const newValue = [...prev];
          newValue[index] = {
            ...newValue[index], loading: false
          }
          return newValue;
        });
      });
  }

  return (
    <>
      <section className="section is-main-section">
        <PaginatedTable
          columns={columns}
          url={`/api/opportunity/lostreason/list/`}
          data={data}
          setData={setData}
        />
      </section>
    </>
  )
};

const { //actions
  httpPost
} = httpService;

const mapDispatchToProps = { //actions
  httpPost
};
export default connect(null, mapDispatchToProps)(LostReasonsList);