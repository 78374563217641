import React, { useState, useEffect } from 'react';

import * as httpService from '../../http-service/httpService';
import { isValidPhoneNumber, isValidAccount, isValidClabe } from '../../components/utils/Validators';
import Card from '../../components/Card';
import { connect } from 'react-redux';

import Loading from '../../components/utils/Loading';
import RegisterEstablishmentBankForm from '../../components/forms/RegisterEstablishmentBankForm';


const RegisterEstablishment = ({ httpGet, httpPost, ...props }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [establishment, setEstablishment] = useState({
    name: '',
  });
  const [dataForm, setDataForm] = useState({
    positions: [],
    banks: []
  });
  const [establishmentError, setEstablishmentError] = useState({
    name: '',
  });
  const [contact, setContact] = useState({
    name: '',
    email: '',
    position: '',
    cellphone: '',
  });
  const [contactError, setContactError] = useState({
    name: '',
    email: '',
    position: '',
    cellphone: '',
  });
  const [bankData, setBankData] = useState({
    bank_id: '',
    bank_name: '',
    account: '',
    clabe: '',
  });
  const [bankDataError, setBankDataError] = React.useState({
    bank_id: '',
    account: '',
    clabe: '',
  });

  const [loading, setLoading] = useState(true);
  const resetErrorEstablishment = (field) => {
    setEstablishmentError(err => ({ ...err, [field]: '' }));
  }
  const resetErrorContact = (field) => {
    setContactError(err => ({ ...err, [field]: '' }));
  }
  const resetErrorBankData = (field) => {
    setBankDataError(err => ({ ...err, [field]: '' }));
  }
  const handleOnChangeEstablishment = (e) => {
    const establecimiento = { ...establishment };
    establecimiento[e.target.name] = e.target.value;
    resetErrorEstablishment(e.target.name);
    setEstablishment(establecimiento);
    if (e.target.name === 'phone' && !isValidPhoneNumber(e.target.value)) {
      setEstablishmentError(err => ({ ...err, phone: ['ingrese un numero a 10 digitos se permiten espacios y "-"'] }))
    }
  }

  const handleOnChangeContact = (e) => {
    const contacto = { ...contact };
    contacto[e.target.name] = e.target.value;
    resetErrorContact(e.target.name);
    setContact(contacto);
    if (e.target.name === 'cellphone' && !isValidPhoneNumber(e.target.value)) {
      setContactError(err => ({ ...err, cellphone: ['ingrese un numero a 10 digitos se permiten espacios y "-"'] }))
    }
  }

  const handleOnChangeBankData = (e) => {
    const bank = { ...bankData };
    bank[e.target.name] = e.target.value;
    resetErrorBankData(e.target.name);
    setBankData(bank);
    if (e.target.name === 'bank_id' && parseInt(e.target.value) > 0){
      dataForm.banks.map((item) => {
        if (item.id === parseInt(e.target.value)) {
          bank['bank_name'] = item.name
        }
      });
    }
    if (e.target.name === 'account' && !isValidAccount(e.target.value)) {
      setContactError(err => ({ ...err, account: ['En este campo solo se permiten numeros. No debe de contener espacios.'] }))
    }
    if (e.target.name === 'clabe' && !isValidClabe(e.target.value)) {
      setContactError(err => ({ ...err, clabe: ['En este campo solo se permiten numeros. No debe de contener espacios.'] }))
    }
  }

  useEffect(() => {
    httpGet('/api/establishment/bankdata/utils/')
      .then(response => {
        setDataForm({ positions: response.data.positions, banks: response.data.banks });
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }, [httpGet]);

  const validateForm = () => {
    let valid_est = true;
    let error_est = {};
    let valid_cont = true;
    let error_cont = {};
    let valid_bank = true;
    let error_bank = {};
    Object.keys(establishment).forEach((item) => {
      if (establishment[item].length === 0) {
        error_est[item] = ['Este campo es requerido']
        valid_est = false
      }
    });
    if (!valid_est) {
      setEstablishmentError(err => ({ ...err, ...error_est }));
    }

    Object.keys(contact).forEach((item) => {
      if (contact[item].length === 0) {
        error_cont[item] = ['Este campo es requerido']
        valid_cont = false
      }
      if (item === 'cellphone') {
        error_cont[item] = ['ingrese un numero a 10 digitos se permiten espacios y "-"'];
        valid_cont = isValidPhoneNumber(contact[item]);
      }
    });
    if (!valid_cont) {
      setContactError(err => ({ ...err, ...error_cont }));
    }

    Object.keys(bankData).forEach((item) => {
      if (bankData[item].length === 0) {
        error_bank[item] = ['Este campo es requerido']
        valid_bank = false
      }
      if (item === 'account' && !isValidAccount(bankData[item])) {
        error_bank[item] = ['En este campo solo se permiten numeros. No debe de contener espacios.']
        valid_bank = false
      }
      if (item === 'clabe' && !isValidClabe(bankData[item])) {
        error_bank[item] = ['En este campo solo se permiten numeros. No debe de contener espacios.']
        valid_bank = false
      }
    });
    console.log(valid_bank);
    if (!valid_bank) {
      setBankDataError(err => ({ ...err, ...error_bank }));
    }

    return valid_cont && valid_est && valid_bank;
  }
  
  const handleOnSubmit = (e) => {
    e.preventDefault();
    let is_valid = validateForm();
    if (is_valid) {
      setConfirm(true);
      setModalIsOpen(true);
    }
  }

  const handleOnConfirmed = (e) => {
    setConfirm(false);
    setModalIsOpen(false);
    setLoading(true);
    httpPost('/api/establishment/createbybankdata/', { establishment, contact, bankData })
      .then(response => {
        setModalIsOpen(true);
      })
      .catch(error => {
        setEstablishmentError(err => ({ ...err, ...error.response.data.establishment }))
        setContactError(err => ({ ...err, ...error.response.data.contact }))
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleOnModalClose = () => {
    setModalIsOpen(false);
    if (!confirm) {
      props.history.goBack();
    }
  }
  return (
    <>
      <section className="section is-main-section">
        {
          (loading) ?
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-narrow">
                  <Loading />
                </div>
              </div>
            </div> :
            <Card title={"Agregar Establecimiento **Esta sección debe ser llenada por un contacto del establecimiento"}>
              <RegisterEstablishmentBankForm
                handleOnChangeEstablishment={handleOnChangeEstablishment}
                establishment={establishment}
                handleOnSubmit={handleOnSubmit}
                handleOnChangeContact={handleOnChangeContact}
                handleOnChangeBankData={handleOnChangeBankData}
                contact={contact}
                bankData={bankData}
                bankDataError={bankDataError}
                establishmentError={establishmentError}
                contactError={contactError}
                dataForm={dataForm}
                modalIsOpen={modalIsOpen}
                confirm={confirm}
                handleOnConfirmed={handleOnConfirmed} 
                handleOnModalClose={handleOnModalClose}
                cancelButtonPath={props.history.goBack}

              />
            </Card>
        }
      </section>
    </>
  )
};

const { //actions
  httpGet, httpPost
} = httpService;

const mapDispatchToProps = { //actions
  httpGet, httpPost
};

export default connect(null, mapDispatchToProps)(RegisterEstablishment);