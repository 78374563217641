import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Icon from '@mdi/react';
import { mdiPlus, mdiStar, mdiPencil, mdiTrashCan } from '@mdi/js';
import { useState } from 'react';

import * as httpService from '../../http-service/httpService';
import * as moment from 'moment';

import Loading from '../../components/utils/Loading';
import NewOpportunity from '../../components/opportunity/NewOppotunity';
import PaginatedTable from '../../components/tables/PaginatedTable';
import OpportunityLostForm from '../../components/opportunity/OpportunityLostForm';



const ManagerOpportunityList = ({ httpGet, httpPost, ...props }) => {
  const [showNewOpportunity, setShowNewOpportunity] = useState(false);

  const [opportunityLostForm, setOpportunityLostForm] = useState({
    show: false,
    data: {
      establishmentName: '',
      opportunityUuid: ''
    }
  });
  const handleOpportunityLostFormClose = () => {
    setOpportunityLostForm({
      show: false, data: {
        establishmentName: '',
        opportunityUuid: ''
      }
    });
  }

  const [opportunity, setOpportunity] = useState({
    establishment: '',
    rating: []
  });

  const [opportunityError, setOpportunityError] = useState({
    establishment: '',
    rating: ''
  });

  const [isLoading, setIsLoading] = useState(false);

  const [dataForm, setDataForm] = useState({
    establishments: { data: [], isLoading: false },
    ratings: { data: [], isLoading: false, checked: [], minLen:2 },
  });

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (opportunity.rating.length >= dataForm.ratings.minLen) {
      httpPost('/api/opportunity/create/', opportunity)
        .then(response => {
          console.log(response)
          const newDataFormEstablishments = dataForm.establishments.data.filter((item, key) => item.uuid !== opportunity.establishment);
          setDataForm(dat => {
            const newValue = { ...dat }
            newValue['establishments'] = {
              ...newValue['establishments'], data: newDataFormEstablishments
            }
            newValue['ratings'] = { data: [], isLoading: false, checked: [] };
            return newValue;
          });
          setData(prev => ([response.data, ...prev]));
        })
        .catch(error => {
          console.log(error)
          setOpportunityError(err => {
            const newValue = {
              establishment: '',
              rating: ''
            }
            error.response.data.errors.forEach(item => {
              if (item.field)
                newValue[item.field] = item.message
              return []
            });
            return newValue;
          })
        })
        .finally(() => {
          setIsLoading(false);
        })
    }
    else {
      setOpportunityError(err => ({ ...err, rating: 'No cumple con la evaluación minima' }));
      setIsLoading(false);

    }
  }

  const resetErrorOpportunity = (field) => {
    setOpportunityError(err => ({ ...err, [field]: '' }));
  }

  const handleOnChangeOpportunity = (e) => {
    if (e.target.value.length > 0) {
      resetErrorOpportunity(e.target.name);
      const establishmentUuid = dataForm.establishments.data
        .filter(item => item.uuid === e.target.value)
        .map(item => item.uuid);
      httpGet(`/api/opportunity/rating/list/${establishmentUuid[0]}/`)
        .then(response => {
          console.log(response)
          setDataForm(dat => {
            const newValue = { ...dat };
            newValue['ratings'] = {
              ...dat['ratings'], isLoading: false, data: response.data.data,minLen:response.data.min_len
            }
            const checked = {};
            response.data.data.forEach(item => {
              checked[item.id] = false
            });
            newValue['ratings']['checked'] = checked;
            return newValue;
          });
          setOpportunity(opp => ({ ...opp, rating: [] }));
        })
        .catch(error => console.log(error))
    }
    else {
      setOpportunity(opp => ({ ...opp, rating: [] }));
      setDataForm(dat => ({ ...dat, ratings: { data: [], isLoading: false, checked: [] } }));
      setOpportunityError({ establishment: '', rating: '' })
    }
    const newOpportunity = { ...opportunity, [e.target.name]: e.target.value };
    setOpportunity(newOpportunity);
  }

  const handleCheckChange = (e) => {
    resetErrorOpportunity(e.target.name);
    const newValueDataForm = { ...dataForm }
    newValueDataForm['ratings'] = {
      ...newValueDataForm['ratings']
    }
    newValueDataForm['ratings']['checked'] = {
      ...newValueDataForm['ratings']['checked'], [e.target.value]: e.target.checked
    }
    setDataForm(newValueDataForm);
    const newValueOpportunity = { ...opportunity }
    const datas = Object.keys(newValueDataForm['ratings']['checked']).filter(item => newValueDataForm['ratings']['checked'][item]).map(item => item);
    newValueOpportunity['rating'] = datas;
    setOpportunity(newValueOpportunity);
  }

  const handleOnClickNewOpportunity = () => {
    if (dataForm.establishments.data.length === 0) {
      setDataForm(dat => {
        const newValue = { ...dat }
        newValue['establishments'] = {
          ...dat['establishments'], isLoading: true
        }
        return newValue;
      });
      httpGet('/api/opportunity/establishment/list/')
        .then(response => {
          setDataForm(dat => {
            const newValue = { ...dat }
            newValue['establishments'] = {
              ...dat['establishments'], isLoading: false, data: response.data
            }
            return newValue;
          })
        })
        .catch(error => console.log(error.response));
    }
    setShowNewOpportunity(opp => !opp);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'U_estado',
        accessor: 'user_state'
      },
      {
        Header: 'Rol',
        accessor: 'user_rol'
      },
      {
        Header: 'Usuario',
        accessor: 'user'
      },
      {
        Header: 'E_estado',
        accessor: 'state'
      },
      {
        Header: 'Establecimiento',
        accessor: 'establishment'
      },
      {
        Header: 'Caracteristicas',
        disableFilters: true,
        accessor: 'rating',
        Cell: ({ cell: { value } }) => {
          return (
            value.map((item, key) =>
              <span key={key} className="icon has-text-primary">
                <Icon path={mdiStar} size={1} title={item} />
              </span>
            )
          )
        }
      },
      {
        Header: 'Avance',
        accessor: "stage",
        Cell: ({ cell: { value, row: { original } } }) => {
          let className = 'is-warning'
          if (!original.active) {
            className = 'is-danger'
          }
          else if (value > 3) {
            className = 'is-success'
          }
          let progressPercent = (value / 4) * 100;
          return (
            <div className="has-text-centered">
              <span className="mb-1 progress label-percent is-medium" data-percent={`${progressPercent}%`}>
                <progress max="1" className={"progress is-medium " + className} value={value / 4}>{progressPercent}</progress>
              </span>
              {!original.active &&
                <span className="">{original.lost_reason}</span>
              }
            </div>
          )
        },
        disableFilters: true,
        classNameCell: 'is-progress-col'
      },
      {
        Header: 'Perfil',
        disableFilters: true,
        accessor: 'perfil',
        Cell: ({ cell: { value } }) => {
          if (value.title) {
            return (
              <div className="has-text-centered">
                <span>{value.title}</span>
                <br />
                <span className="tag is-primary">{value.percent_text}</span>
              </div>
            )
          }
          else
            return '';
        }
      },
      {
        Header: 'Fecha',
        disableFilters: true,
        accessor: 'registered_on'
      },
      {
        Header: "",
        accessor: "uuid",
        disableFilters: true,
        Cell: ({ cell: { value, row: { original } } }) => {
          return (<div className="buttons is-right">
            <Link className="button is-small is-primary" to={{ pathname: `/opportunity/detail/${value}`, state: { from: props.location } }}>
              <span className="icon"><Icon path={mdiPencil} size={1} title={"Editar"} /></span>
            </Link>
            <button className="button is-small is-danger jb-modal" data-target="sample-modal" type="button"
              onClick={() => setOpportunityLostForm({ show: true, data: { establishmentName: original.establishment, opportunityUuid: value } })}
              disabled={!original.active}
            >
              <span className="icon"><Icon path={mdiTrashCan} size={'1rem'} /></span>
            </button>
          </div>)
        }
      }
    ],
    []
  )
  const [data, setData] = React.useState([])

  return (
    <>
      <section className="section is-main-section">
        <div className="columns">
          <div className="column is-12 has-text-right">
            <button className="button is-info" onClick={handleOnClickNewOpportunity}>
              <span>Crear Porceso</span>
              <span className="icon is-small">
                <Icon path={mdiPlus} size={'1rem'} />
              </span>
            </button>
          </div>
        </div>
        {showNewOpportunity &&
          <div className="columns">
            <div className="column is-narrow is-12-mobile is-6 is-offset-6 is-offset-0-mobile">
              {isLoading ? <Loading /> :
                <NewOpportunity
                  opportunity={opportunity}
                  handleOnChangeOpportunity={handleOnChangeOpportunity}
                  handleOnSubmit={handleOnSubmit}
                  opportunityError={opportunityError}
                  dataForm={dataForm}
                  handleOnClickNewOpportunity={handleOnClickNewOpportunity}
                  handleCheckChange={handleCheckChange}
                />
              }
            </div>
          </div>
        }
        <PaginatedTable
          columns={columns}
          url={`/api/managers/list/opportunity/`}
          data={data}
          setData={setData}
          url_excel={`/api/managers/excel/opportunity/?format=xlsx`}
          excelFileName={'Procesos_de_venta'}
        />
        <OpportunityLostForm
          opportunityData={opportunityLostForm.data}
          handleOnModalClose={handleOpportunityLostFormClose}
          modalIsOpen={opportunityLostForm.show}
        />
      </section>
    </>
  )
};
const { //actions
  httpGet, httpPost
} = httpService;

const mapDispatchToProps = { //actions
  httpGet, httpPost
};
export default connect(null, mapDispatchToProps)(ManagerOpportunityList);