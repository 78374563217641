import React from 'react';
import ReactDOM from 'react-dom';

function Modal(props) {
  if (props.isOpen) {
    return ReactDOM.createPortal(
      <div className="modal is-active">
        <div className="modal-background jb-modal-close"></div>
        <div className="modal-card" style={props.isForm ? {width:'85%'} : {}}>
          {props.children}
        </div>
        <button onClick={props.onClose} className="modal-close is-large jb-modal-close" aria-label="close"></button>
      </div>,
      document.getElementById('modal-super')
    );
  }
  else {
    return null;
  }
}

export default Modal;