import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import routes from '../routes';

import AsideMenu from '../components/base/AsideMenu';
import NavBar from '../components/base/NavBar';
import Footer from './base/Footer';
import TitleBarPage from './base/TitleBarPage';
import HeroBarPage from './base/HeroBarPage';
import PrivateRoute from '../authentication/PrivateRoute';

const Layout = (props) => {
  const routesFiltered = routes.filter(item => {
    return item.groupRequired === undefined || props.user_data.groups.includes(item.groupRequired);
  });
  const dashboardRoutes = routesFiltered.map((prop, key) => {
    if (prop.path === '/404')
      return (
        <React.Fragment key={key} >
          <PrivateRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
            groupRequired={prop.groupRequired}
          />
          <Redirect to={'/404'} />
        </React.Fragment>
      )
    else
      return (
        <PrivateRoute
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
          exact
          groupRequired={prop.groupRequired}
        />
      )
  });
  const makeHeroTitle = () => {
    let name;
    routesFiltered.map(prop => {
      const starts = (prop.layout + prop.path).split('/:')[0].replace(/\//gi, '\\/'); //.replace(/\*/gi,'\\*')
      const re = `^${starts}`;
      if (props.location.pathname.search(new RegExp(re)) !== -1) {
        name = prop.name;
      }
      return null;
    });
    return name;
  }

  const makeBarTitle = () => {
    let name;
    let fromLocation = props.location.state?.from?.pathname || null;
    let prevRoute = [];
    if (fromLocation !== null)
      prevRoute = routesFiltered.filter(item => {
        if (item.path !== '/') {
          const starts = (item.layout + item.path).split('/:')[0].replace(/\//gi, '\\/');//.replace(/\*/gi,'\\*')
          const re = `^${starts}`;
          return fromLocation.search(new RegExp(re)) !== -1
        }
        return false;
      }).map(item => item.name);
    routesFiltered.map(prop => {
      const starts = (prop.layout + prop.path).split('/:')[0].replace(/\//gi, '\\/');//.replace(/\*/gi,'\\*')
      const re = `^${starts}`;

      if (props.location.pathname.search(new RegExp(re)) !== -1) {
        name = [prop.section];
        if (prevRoute.length > 0) {
          name.push(prevRoute[prevRoute.length - 1]);
        }
        name.push(prop.name);
      }
      return null;
    });
    return name;
  }
  // let hasAuthorization = rest.groupRequired === undefined || rest.user_data.groups?.includes(rest.groupRequired);

  return (
    <>
      <NavBar title={makeBarTitle()} goBack={props.history.goBack} />

      <AsideMenu routes={routesFiltered} />

      <HeroBarPage title={makeHeroTitle()} />
      <Switch >
        {dashboardRoutes}
      </Switch>
      <Footer />
    </>
  )
};

const mapStateToProps = ({ LoginReducer }) => LoginReducer;

export default connect(mapStateToProps, null)(Layout);