import React from "react";
import { connect } from "react-redux";

import * as httpService from "../../http-service/httpService";

import Icon from "@mdi/react";
import { mdiCheckBold, mdiEmoticonDead } from "@mdi/js";

import AfiliationController from "../../components/afiliation/AfiliationController";
import Card from "../../components/Card";
import Loading from "../../components/utils/Loading";
import shopnetLogo from "../../styles/static/shopnetLogo.png";
import * as moment from "moment";
import { fiscalDataInitValues } from "../../components/fiscalData/initValues";

const RegisterAfiliation = ({
  httpGet,
  httpPost,
  match: {
    params: { token },
  },
  ...props
}) => {
  const urlPost = `/api/afiliation/create/tokenauth/${token}/`;
  const [customer, setCustomer] = React.useState({
    name: "",
    email: "",
    phone: "",
    receipt: false,
    invoice: false,
    plan_id: "",
    billing_cycle_start: moment().toISOString(),
    terms: false,
    state: "",
  });
  const paymentInfo = {
    name: "",
    account: "",
    month: "",
    year: "",
    cvc: "",
  };
  const paymentData = {
    tokenized_card: "",
    payment_type: "",
  };
  const [establishmentData, setEstablishment] = React.useState({
    name: "",
    state: "",
    branchs: 1,
  });
  const [fiscalData, setFiscalData] = React.useState(fiscalDataInitValues);
  const [loading, setLoading] = React.useState(true);
  const [aproved, setAproved] = React.useState(false);
  const [message, setMessage] = React.useState({ title: "", message: "" });
  const [limitAmount, setLimitAmount] = React.useState(4000000);
  React.useEffect(() => {
    httpPost(`/api/afiliation/tokenauth/${token}/`)
      .then((response) => {
        setMessage(response.data.message);
        setAproved(response.data.valid);
        const email = response.data.token_data.sent_to;
        setCustomer((dat) => {
          const newValue = { ...customer };
          newValue["plan_id"] = response.data.token_data.plan_id;
          newValue["state"] = response.data.token_data.state;
          newValue["email"] = response.data.token_data.sent_to;
          return newValue;
        });
        setFiscalData((data) => ({ ...data, email }));
        setEstablishment((dat) => {
          const newValue = { ...establishmentData };
          newValue["state"] = response.data.token_data.state;
          return newValue;
        });
      })
      .catch((error) => {
        console.log(error);
        setMessage({ title: "Error", message: "Esta ruta no existe." });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [httpPost, token]);

  const handleSubmitSuccess = () => {
    setAproved(false);
    setMessage({
      title: "Completado",
      message:
        "Su registro ha sido procesado, el cargo completo se realizará el día aquí colocado. Se iniciará con la alta del negocio en la aplicación.",
    });
  };
  return (
    <>
      <section className="section is-main-section">
        <div className="columns is-centered">
          <img src={shopnetLogo} alt="" height="100%" width="485px" />
        </div>
        {loading ? (
          <Loading />
        ) : (
          <Card
            title={
              message.title === "Error"
                ? message.title
                : `Afiliacion Nueva **Requeridos para afiliación del Negocio`
            }
          >
            {aproved ? (
              <AfiliationController
                paymentInf={paymentInfo}
                paymentDat={paymentData}
                estabDat={establishmentData}
                customerDat={customer}
                fiscalDat={fiscalData}
                handleSubmitSuccess={handleSubmitSuccess}
                cancelButtonPath={props.history.goBack}
                urlPost={urlPost}
                setMessage={setMessage}
                limitAmount={limitAmount}
              />
            ) : (
              <>
                <div className="columns is-mobile is-centered my-5">
                  <div className="column is-narrow ">
                    <h1
                      className={
                        message.title === "Completado"
                          ? "title has-text-success"
                          : "title has-text-danger"
                      }
                    >
                      <span className="">
                        <Icon
                          size={5}
                          path={
                            message.title === "Completado"
                              ? mdiCheckBold
                              : mdiEmoticonDead
                          }
                          title={"Error 404"}
                        />
                        <i></i>
                      </span>
                    </h1>
                  </div>
                </div>
                <div className="columns is-mobile is-centered">
                  <div className="column is-12 has-text-centered is-narrow is-centered">
                    <p
                      className={
                        message.title === "Completado"
                          ? "is-size-4 has-text-success"
                          : message.title === "Error" &&
                            "is-size-1 has-text-danger"
                      }
                    >
                      {message.message}
                    </p>
                  </div>
                </div>
              </>
            )}
          </Card>
        )}
      </section>
    </>
  );
};

const {
  //actions
  httpGet,
  httpPost,
} = httpService;

const mapDispatchToProps = {
  //actions
  httpGet,
  httpPost,
};

export default connect(null, mapDispatchToProps)(RegisterAfiliation);
