import React, { useMemo } from "react";
import { connect } from "react-redux";

import * as httpService from "../../http-service/httpService";

import Icon from "@mdi/react";
import {
  mdiPlus, mdiTrashCan, mdiCheckBold, mdiCloseCircle,
  mdiAlertCircleOutline
} from '@mdi/js';

import Modal from '../../components/utils/Modal';
import Loading from '../../components/utils/Loading';
import ActivityNew from "../../components/opportunity/ActivityNew";
import PaginatedTable from "../../components/tables/PaginatedTable";

const ActivityList = ({ httpGet, httpPost, match: { params: { opportunityUuid }, }, ...props }) => {
  const [showNewActivity, setShowNewActivity] = React.useState(false);
  const [activity, setActivity] = React.useState({
    name: "",
    description: "",
    date: new Date(),
    activity_type: "",
    contact: "",
  });

  const [activityError, setActivityError] = React.useState({
    name: "",
    description: "",
    date: new Date(),
    activity_type: "",
    contact: "",
  });
  const [dataForm, setDataForm] = React.useState({
    activityTypes: [],
    contacts: [],
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [date, setDate] = React.useState(new Date());
  const [focus, setFocus] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [globalError, setGlobalError] = React.useState([]);
  const [rowToDelete, setRowToDelete] = React.useState({
    name: ''
  });

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("submit");
    console.log(activity);
    let is_valid = validateForm();
    if (activity["contact"] === null || activity["contact"].length === 0) {
      activity["contact"] = null;
    }
    if (is_valid) {
      httpPost(`/api/opportunity/activity/create/${opportunityUuid}/`, activity)
        .then((response) => {
          console.log(response);
          const newDataFormActivityTypes = dataForm.activityTypes;
          const newDataFormContacts = dataForm.contacts;
          console.log("NewDataForm");
          console.log(newDataFormActivityTypes);
          console.log(newDataFormContacts);
          const newValue = {
            name: '',
            description: '',
            date: new Date(),
            activity_type: '',
            contact: '',
          }
          setActivity(newValue)
          handleOnClickNewActivity()
          setData((prev) => [response.data, ...prev]);
          props.history.goBack()
        })
        .catch(error => {
          console.log(error)
          setActivityError(err => {
            const newValue = {
              name: '',
              description: '',
              date: new Date(),
              activity_type: '',
              contact: '',
            }
            error.response.data.errors.forEach(item => {
              if (item.field)
                newValue[item.field] = item.message;
              return [];
            });
            return newValue;
          })
        })
        .finally(() => {
        })
    }
    setIsLoading(false);
  };

  const resetErrorActivity = (field) => {
    setActivityError((err) => ({ ...err, [field]: '' }));
  };

  const handleOnChangeActivity = (e) => {
    const newActivity = { ...activity, [e.target.name]: e.target.value };
    setActivity(newActivity);
    resetErrorActivity(e.target.name);
  };

  React.useEffect(() => {
    setIsLoading((prev) => prev + 1);
    httpGet(`/api/opportunity/activity/utils/${opportunityUuid}/`)
      .then((response) => {
        setDataForm({
          activityTypes: response.data.activity_types,
          contacts: response.data.contacts,
        });
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading((prev) => prev - 1);
      });
  }, [httpGet, opportunityUuid]);

  const validateForm = () => {
    let valid_est = true;
    let error_est = {};
    console.log(activity);
    Object.keys(activity).forEach(item => {
      console.log(activity[item]);
      console.log(item);
      if (
        activity[item].length === 0 &&
        item !== "description" &&
        item !== "date" &&
        item !== "contact"
      ) {
        error_est[item] = "Este campo es requerido";
        valid_est = false;
      }
    });
    console.log(error_est);
    if (!valid_est) {
      setActivityError((err) => ({ ...err, ...error_est }));
    }
    console.log(valid_est);
    return valid_est;
  };

  const handleOnClickNewActivity = () => {
    setShowNewActivity(opp => !opp);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Nombre",
        disableFilters: true,
        accessor: "name",
      },
      {
        Header: "Fecha de actividad",
        disableFilters: true,
        accessor: "date",
      },
      {
        Header: "Tipo de Actividad",
        disableFilters: true,
        accessor: "activity_type",
      },
      {
        Header: "Contacto",
        disableFilters: true,
        accessor: "contact",
      },
      {
        Header: "",
        disableFilters: true,
        accessor: "uuid",
        Cell: ({ row: { values }, cell: { value } }) => (
          <div className="buttons is-right">
            <button
              className="button is-small is-danger jb-modal"
              data-target="sample-modal"
              type="button"
              onClick={(e) => handleOnClickDelete(values)}
            >
              <span className="icon is-small">
                <Icon
                  path={mdiTrashCan}
                  size={1}
                  title={"Eliminar Actividad"}
                />
              </span>
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const handleOnClickDelete = (row) => {
    setRowToDelete(row)
    setModalIsOpen(true)
  }

  const handleOnModalClose = () => {
    setModalIsOpen(false);
    setGlobalError([]);
    setRowToDelete({ name: '' });
  }

  const deleteEstablishment = () => {
    setIsLoading(true);
    var url = `/api/opportunity/activity/delete/${rowToDelete.uuid}/`;
    httpPost(url)
      .then((response) => {
        handleOnModalClose();
        setIsLoading(false);
        setModalIsOpen(true);
      })
      .catch((error) => console.log(error))
  }

  return (
    <>
      <section className="section is-main-section">
        <div className="columns">
          <div className="column is-12 has-text-right">
            <button
              className="button is-info"
              onClick={handleOnClickNewActivity}
            >
              <span>Crear Actividad</span>
              <span className="icon is-small">
                <Icon path={mdiPlus} size={'1rem'} />
              </span>
            </button>
          </div>
        </div>
        {showNewActivity && (
          <div className="columns">
            <div className="column is-narrow is-8 is-offset-4 is-offset-0-tablet">
              {isLoading ? <Loading /> :
                <ActivityNew
                  activity={activity}
                  handleOnChangeActivity={handleOnChangeActivity}
                  handleOnSubmit={handleOnSubmit}
                  activityError={activityError}
                  dataForm={dataForm}
                  handleOnClickNewActivity={handleOnClickNewActivity}
                  setDate={setDate}
                  date={date}
                  setFocus={setFocus}
                  focused={focus}
                />
              }
            </div>
          </div>
        )}
        {isLoading ? <Loading /> :
          <PaginatedTable
            columns={columns}
            url={`/api/opportunity/activity/list/${opportunityUuid}/`}
            data={data}
            setData={setData}
          />
        }
        <Modal onClose={handleOnModalClose} isOpen={modalIsOpen || globalError.length > 0} >
          <header className="modal-card-head">
            <p className="modal-card-title is-size-4">{globalError.length > 0 ? "Error" : "Actividad Eliminada"}</p>
            <button onClick={handleOnModalClose} className="delete jb-modal-close" aria-label="close"></button>
          </header>
          <section className="modal-card-body">
            <div className="columns is-centered">
              <div className="column has-text-centered">
                {(globalError.length > 0 ?
                  <span className="has-text-danger">
                    <Icon path={mdiCloseCircle} title={'OK'} size={5} />
                    <p className="is-size-1">{globalError}</p>
                  </span>
                  : rowToDelete.name.length > 0 ?
                    <span className="has-text-danger">
                      <Icon path={mdiAlertCircleOutline} title={'OK'} size={5} />
                      <p className="is-size-5">{"Seguro que quires eliminar la actividad " + rowToDelete.name + "?"}</p>
                    </span>
                    : <span className="has-text-success">
                      <Icon path={mdiCheckBold} title={'OK'} size={5} />
                    </span>)
                }
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            {rowToDelete.name.length > 0 ? <>
              <button className="button jb-modal-close" onClick={deleteEstablishment}>Si, Eliminar</button>
              <button className="button jb-modal-close is-danger" onClick={handleOnModalClose}>No, Gracias</button>
            </> :
              <button className="button jb-modal-close" onClick={handleOnModalClose}>Aceptar</button>
            }
          </footer>
        </Modal>
      </section>
    </>
  );
};
const {
  //actions
  httpGet,
  httpPost,
} = httpService;

const mapDispatchToProps = {
  //actions
  httpGet,
  httpPost,
};
export default connect(null, mapDispatchToProps)(ActivityList);
