import React from 'react';
import FiscalDataController from '../../components/fiscalData/FiscalDataController';
import Card from '../../components/Card';
import { fiscalDataInitValues } from '../../components/fiscalData/initValues';


const FiscalDataCreate = ({ match: { params: { opportunityUuid } }, ...props }) => {
  const urlPost = `/api/opportunity/fiscalData/create/${opportunityUuid}/`
  const handleSubmitSuccess = () => {
    props.history.goBack()
  }

  return (
    <>
      <section className="section is-main-section">
        <Card title={'Agregar Datos Fiscales'}>
          <FiscalDataController
            fiscalDat={fiscalDataInitValues}
            handleSubmitSuccess={handleSubmitSuccess}
            cancelButtonPath={props.history.goBack}
            urlPost={urlPost}
          />
        </Card>
      </section>
    </>
  )
};

export default FiscalDataCreate;
