import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

import * as httpService from '../../http-service/httpService';
import * as moment from 'moment';

import Icon from '@mdi/react';
import {
  mdiPencil, mdiTrashCan, mdiAccountPlus, mdiCurrencyUsd,
  mdiCheckBold, mdiCloseCircle, mdiAlertCircleOutline
} from '@mdi/js';

import PaginatedTable from '../../components/tables/PaginatedTable';
import Modal from '../../components/utils/Modal';
import Loading from '../../components/utils/Loading';

const ContactList = ({ httpPost, match: { params: { uuid } }, ...props }) => {

  const [data, setData] = React.useState([]);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [mailSent, setMailSent] = React.useState(false);
  const [globalError, setGlobalError] = React.useState([]);
  const [reload, setReload] = React.useState(0);
  const [rowToDelete, setRowToDelete] = React.useState({
    name: ''
  });
  const [sendTo, setSendTo] = React.useState({
    name: '',
    actions_data: {
      not_bank_data: false,
      token_sent_on: false
    },
  });
  const columns = React.useMemo(
    () => [
      {
        Header: 'Nombre',
        disableFilters: true,
        accessor: 'name'
      },
      {
        Header: 'Puesto',
        disableFilters: true,
        accessor: 'position',
      },
      {
        Header: 'Establecimiento',
        disableFilters: true,
        accessor: 'establishment',
      },
      {
        Header: 'Celular',
        disableFilters: true,
        accessor: 'cellphone',
      },
      {
        Header: 'Email',
        disableFilters: true,
        accessor: 'email',
      },
      {
        Header: 'Fecha',
        disableFilters: true,
        accessor: 'registered_on'
      },
      {
        Header: "",
        disableFilters: true,
        accessor: "actions_data",
        Cell: ({ row: { values }, cell: { value } }) => (
          <div className="buttons is-right">
            <Link className="button is-small is-primary" to={{ pathname: `/contact/edit/${value.uuid}`, state: { from: props.location } }}>
              <span className="icon"><Icon path={mdiPencil} size={1} title={"Editar"} /></span>
            </Link>
            {value.not_bank_data ?
              <button className="button is-small is-warning jb-modal" onClick={() => handleOnClickSendForm(values)} type="button">
                <span className="icon"><Icon path={mdiCurrencyUsd} size={'1rem'} title="Enviar liga de Datos Bancarios" /></span>
              </button>
              :
              <button className="button is-small is-primary jb-modal" onClick={() => handleOnClickSendForm(values)} type="button">
                <span className="icon"><Icon path={mdiCurrencyUsd} size={'1rem'} title="Datos Bancarios Cargados" /></span>
              </button>
            }
            <button className="button is-small is-danger jb-modal" onClick={() => handleOnClickDelete(values)} type="button">
              <span className="icon"><Icon path={mdiTrashCan} size={'1rem'} title={"Eliminar"} /></span>
            </button>
          </div>
        )
      }
    ],
    [props.location]
  )

  const handleOnClickSendForm = (values) => {
    console.log(values);
    setSendTo(values);
    setModalIsOpen(true);
  }
  const sendBankDataForm = () => {
    var url = `/api/establishment/contact/sendbankdataform/${sendTo.actions_data.uuid}/`;
    httpPost(url, {})
      .then((response) => {
        handleOnModalClose();
        setMailSent(true);
        setModalIsOpen(true);
      })
      .catch(error => {
        // console.log(error.response.data.errors);
        error.response.data.errors.forEach((item) => {
          setGlobalError([item.message]);
        })
      })
      .finally(() => {
      });
  }

  const handleOnClickDelete = (row) => {
    setRowToDelete(row)
    setModalIsOpen(true)
  }

  const handleOnModalClose = () => {
    setModalIsOpen(false);
    setMailSent(false);
    setGlobalError([]);
    setRowToDelete({ name: '' });
    setSendTo({
      name: '',
      actions_data: {
        not_bank_data: false,
        token_sent_on: false
      },
    });
  }

  const deleteEstablishment = () => {
    console.log(rowToDelete.name + " Eliminado");
    var url = `/api/establishment/contact/delete/${rowToDelete.actions_data.uuid}/`;
    setReload(true);
    httpPost(url)
      .then((response) => {
        handleOnModalClose();
        setReload(false);
        setModalIsOpen(true);
      })
      .catch((error) => console.log(error))
  }

  return (
    <>
      <section className="section is-main-section">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              {' '}
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <Link className="button is-info" to={{ pathname: `/contact/create/${uuid}`, state: { from: props.location } }}>
                <span>Agregar Contacto</span>
                <span className="icon is-small">
                  <Icon path={mdiAccountPlus} size={'1rem'} />
                </span>
              </Link>
            </div>
          </div>
        </div>
        {reload ? <Loading /> :
          <PaginatedTable
            columns={columns}
            url={`/api/establishment/contact/list/${uuid}/`}
            data={data}
            setData={setData}
            url_excel={`/api/establishment/contact/excel/${uuid}/?format=xlsx`}
            excelFileName={'Contactos'}
          />
        }
        <Modal onClose={handleOnModalClose} isOpen={modalIsOpen || globalError.length > 0} >
          <header className="modal-card-head">
            <p className="modal-card-title is-size-4">{globalError.length > 0 ? "Error" : rowToDelete.name.length > 0 ? "Contacto Eliminado" : "Correo Enviado"}</p>
            <button onClick={handleOnModalClose} className="delete jb-modal-close" aria-label="close"></button>
          </header>
          <section className="modal-card-body">
            <div className="columns is-centered">
              <div className="column has-text-centered">
                {(globalError.length > 0 ?
                  <span className="has-text-danger">
                    <Icon path={mdiCloseCircle} title={'OK'} size={5} />
                    <p className="is-size-1">{globalError}</p>
                  </span>
                  : rowToDelete.name.length > 0 ?
                    <span className="has-text-danger">
                      <Icon path={mdiAlertCircleOutline} title={'OK'} size={5} />
                      <p className="is-size-5">{`Seguro que quires eliminar el contacto ${rowToDelete.name}?`}</p>
                    </span>
                    : sendTo.name.length > 0 ? <>
                      <span className="has-text-warning">
                        <Icon path={mdiAlertCircleOutline} title={'OK'} size={5} />
                      </span>
                      {sendTo.actions_data.not_bank_data ? <>
                        {sendTo.actions_data.token_sent_on &&
                          <p className="is-size-5">{`Este contacto ya ha recibido un correo el dia:
                        ${moment(sendTo.actions_data.token_sent_on).format("DD/MM/yyyy")}`}
                          </p>}
                        <div className="is-size-5">{`Seguro que quieres enviar el correo al contacto:`}
                          <ul>
                            <li> {sendTo.name} </li>
                            <li> {sendTo.email} </li>
                          </ul>
                        </div>
                      </> :
                        <p className="is-size-5">{`Los datos bancarios de este establecimiento ya han sido llenados`}</p>
                      }
                    </> : <>
                        <span className="has-text-success">
                          <Icon path={mdiCheckBold} title={'OK'} size={5} />
                        </span>
                        {mailSent && <>
                          <p className="is-size-5">El correo fue enviado correctamente </p>
                          <p className="is-size-5">Recuerda que el correo puede aparecer en correos no deseado</p>
                        </>}
                      </>)
                }
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            {rowToDelete.name.length > 0 ? <>
              <button className="button jb-modal-close" onClick={deleteEstablishment}>Si, Eliminar</button>
              <button className="button jb-modal-close is-danger" onClick={handleOnModalClose}>No, Gracias</button>
            </> : sendTo.name.length > 0 & sendTo.actions_data.not_bank_data ? <>
              <button className="button jb-modal-close" onClick={sendBankDataForm}>Si, Enviar</button>
              <button className="button jb-modal-close is-danger" onClick={handleOnModalClose}>No, Gracias</button>
            </> :
                <button className="button jb-modal-close" onClick={handleOnModalClose}>Aceptar</button>
            }
          </footer>
        </Modal>
      </section>
    </>
  )
};

const { //actions
  httpPost
} = httpService;

const mapDispatchToProps = { //actions
  httpPost
};

export default connect(null, mapDispatchToProps)(ContactList);