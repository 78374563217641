import React from 'react';
import { connect } from 'react-redux';

import * as httpService from '../../http-service/httpService';
import Select from 'react-select';
import Loading from '../utils/Loading';


const SendAfiliationForm = ({ httpGet, httpPost, ...props }) => {
    const [sendTo, setSendTo] = React.useState({
        send_to: '',
        plan_id: '',
        state: '',
    });
    const [errorSendTo, setErrorSendTo] = React.useState({
        send_to: '',
        plan_id: '',
        state: '',
    });
    const [loading, setLoading] = React.useState(false);
    const [dataForm, setDataForm] = React.useState({
        plans: { data: [], isLoading: false },
        states: { data: [], isLoading: false }
    });

    const resetErrorSendTo = (field) => {
        setErrorSendTo(err => ({ ...err, [field]: '' }));
    }

    React.useEffect(() => {
        httpGet('/api/afiliation/utils/')
            .then(response => {
                setDataForm(dat => {
                    const newValue = { ...dat };
                    newValue['plans'] = {
                        ...dat['plans'], isLoading: false, data: response.data.plans
                    }
                    newValue['states'] = {
                        ...dat['states'], isLoading: false, data: response.data.states
                    }
                    return newValue;
                });
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }, [httpGet]);

    const handleOnChange = (e) => {
        resetErrorSendTo(e.target.name);
        const sendingTo = { ...sendTo };
        const fieldName = e.target.name;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        sendingTo[fieldName] = value;
        if (e.target.value === null && e.target.value.length > 0) {
            setErrorSendTo({ send_to: ['Este campo es requerido'] })
        }
        setSendTo(sendingTo)
    }

    const validateForm = () => {
        let valid_form = true;
        let error_form = {};
        Object.keys(sendTo).forEach((item) => {
            resetErrorSendTo(item);
            if (sendTo[item] === null || sendTo[item] === undefined || sendTo[item].length === 0) {
                error_form[item] = ['Este campo es requerido'];
                valid_form = false
            }
            if (item === 'plan_id' && parseInt(sendTo[item]) === 0) {
                error_form[item] = ['Seleccione un plan de Pagos'];
                valid_form = false
            }
            if (item === 'state' && parseInt(sendTo[item]) === 0) {
                error_form[item] = ['Seleccione un plan de Pagos'];
                valid_form = false
            }
        });
        if (!valid_form) {
            setErrorSendTo(err => ({ ...err, ...error_form }));
        }
        return valid_form;
    }

    const handleOnSubmit = (e) => {
        setLoading(true);
        resetErrorSendTo();
        e.preventDefault();
        if (validateForm()) {
            console.log(sendTo);
            httpPost('/api/afiliation/sendafiliationform/', { sendTo })
                .then(response => {
                    props.setOkMessage(response.data.message);
                    props.onSuccess();
                })
                .catch(error => {
                    error.response.data.errors.forEach((item) => {
                        if (item.field) {
                            setErrorSendTo(prev => ({ ...prev, [item.field]: item.message }));
                        }
                        else {
                            props.setGlobalError([item.message]);
                        }
                    })
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        setLoading(false);
    }
    const handlePlanChange = (option) => {
        resetErrorSendTo('plan_id');
        var value = null
        if (option) {
            value = Number(option.value)
        }
        if (value === null) {
            setErrorSendTo(prev => {
                const newValue = { ...prev };
                newValue['plan_id'] = ['Seleccione un plan de Pagos'];
                return newValue;
            });
        }
        setSendTo(dat => ({ ...dat, plan_id: value }));
    }
    const handleStateChange = (option) => {
        resetErrorSendTo('state');
        var value = null
        if (option) {
            value = Number(option.value)
        }
        if (value === null) {
            setErrorSendTo(prev => {
                const newValue = { ...prev };
                newValue['state'] = ['Seleccione un Estado'];
                return newValue;
            });
            setSendTo(dat => ({ ...dat, plan_id: '' }))
            setDataForm(dat => {
                const newValue = { ...dat };
                newValue['plans'] = {
                    ...dat['plans'], isLoading: false, data: []
                }
                return newValue
            })
        }
        setSendTo(dat => ({ ...dat, state: value }));
        getFilteredPlans(value)
    }

    const getFilteredPlans = (stateValue) => {
        if (stateValue != null) {
            httpGet(`/api/afiliation/utils/?state=${stateValue}`)
                .then(response => {
                    setDataForm(dat => {
                        const newValue = { ...dat };
                        newValue['plans'] = {
                            ...dat['plans'], isLoading: false, data: response.data.plans
                        }
                        newValue['states'] = {
                            ...dat['states'], isLoading: false, data: response.data.states
                        }
                        return newValue;
                    });
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                });
        }
    }

    const getIndexValue = (arr, value) => {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i]['value'] === value) {
                return i;
            }
        }
        return -1; //to handle the case where the value doesn't exist
    }

    const controlStylesHasError = (hasError) => (
        hasError ? { borderColor: 'red' } : {}
    )
    return (
        <>{loading ? <Loading></Loading> :
            <form onSubmit={handleOnSubmit}>
                <div className="columns is-multiline">
                    <div className="column is-6">
                        <div className="field">
                            <label className="label">Estado</label>
                            <div className="control">
                                <Select
                                    className="basic-single is-danger"
                                    classNamePrefix="select"
                                    name="state"
                                    value={dataForm.states.data[getIndexValue(dataForm.states.data, sendTo.state)]}
                                    defaultValue={dataForm.states.data[getIndexValue(dataForm.states.data, sendTo.state)]}
                                    onChange={handleStateChange}
                                    isSearchable={true}
                                    isClearable={true}
                                    isLoading={dataForm.states.isLoading}
                                    options={dataForm.states.data}
                                    placeholder={"...Seleccione un Estado"}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            ...controlStylesHasError(errorSendTo.state.length > 0)
                                        })
                                    }}
                                >
                                </Select>
                            </div>
                            {
                                errorSendTo.state && <p className="help is-danger">{errorSendTo.state[0]}</p>
                            }
                        </div>
                    </div>
                    <div className="column is-6">
                        <div className="field">
                            <label className="label">Esquema de Pago</label>
                            <div className="control">
                                <Select
                                    className="basic-single is-danger"
                                    classNamePrefix="select"
                                    name="plan_id"
                                    value={dataForm.plans.data[getIndexValue(dataForm.plans.data, sendTo.plan_id)]}
                                    defaultValue={dataForm.plans.data[getIndexValue(dataForm.plans.data, sendTo.plan_id)]}
                                    onChange={handlePlanChange}
                                    isSearchable={true}
                                    isClearable={true}
                                    isLoading={dataForm.plans.isLoading}
                                    isDisabled={dataForm.plans.data.length == 0}
                                    options={dataForm.plans.data}
                                    placeholder={"Seleccione un Esquema/Costo/Reembolso Cliente/Redencion puntos"}
                                    styles={{
                                        control: base => ({
                                            ...base,
                                            ...controlStylesHasError(errorSendTo.plan_id.length > 0)
                                        })
                                    }}
                                >
                                </Select>
                            </div>
                            {dataForm.plans.data.length == 0 ?
                                <p className={errorSendTo.plan_id ? "help is-danger" : "help"}>Seleccione un estado para activar los planes</p> :
                                <p className={errorSendTo.plan_id ? "help is-danger" : "help"}>Esquema /Costo /Reembolso Cliente /Redencion puntos</p>
                            }
                            {
                                errorSendTo.plan_id && <p className="help is-danger">{errorSendTo.plan_id[0]}</p>
                            }
                        </div>
                    </div>
                    <div className="column is-6">
                        <div className="field">
                            <label className="label">Email</label>
                            <div className="control">
                                <input onChange={handleOnChange} name='send_to' value={sendTo.send_to}
                                    className={errorSendTo.send_to ? "input is-danger" : "input"} type="text" placeholder="contacto@correo.com.mx"
                                />
                            </div>
                            {
                                errorSendTo.send_to && <p className="help is-danger">{errorSendTo.send_to}</p>
                            }
                        </div>
                    </div>
                </div>
                <div className="field is-grouped is-grouped-right">
                    <p className="control">
                        <button type="submit" className="button is-primary">Aceptar</button>
                    </p>
                    <p className="control">
                        <button onClick={props.onClickCancelButton} type="button" className="button is-light">Cancel</button>
                    </p>
                </div>
            </form>}
        </>
    )
};

const { //actions
    httpGet, httpPost
} = httpService;

const mapDispatchToProps = { //actions
    httpGet, httpPost
};

export default connect(null, mapDispatchToProps)(SendAfiliationForm);