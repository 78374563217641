import React from 'react';
import '../../styles/utils/loading.css';

const Base = ({className}) => {

  
  
  return(
    <div className="lds-facebook">
      <div className={className ? className:''}></div>
      <div className={className ? className:''}></div>
      <div className={className ? className:''}></div>
    </div>
)};

export default Base;