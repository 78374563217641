import React from 'react';


const ContactEditForm = (props) => {


  return (
    <>
      <form onSubmit={props.handleOnSubmit}>
        <div className="columns is-multiline">
          <div className="column is-12">
            <div className="field">
              <label className="label">Nombre del Contacto</label>
              <div className="control">
                <input onChange={props.handleOnChangeContact} name='name' value={props.contact.name}
                  className={props.contactError.name ? "input is-danger" : "input"} type="text" placeholder="Juan Perez"
                />
              </div>
              {
                props.contactError.name && <p className="help is-danger">{props.contactError.name}</p>
              }
            </div>
          </div>
          <div className="column is-6 is-12-mobile">
            <div className="field">
              <label className="label">email</label>
              <div className="control">
                <input onChange={props.handleOnChangeContact} value={props.contact.email} name='email'
                  className={props.contactError.email ? "input is-danger" : "input"} type="email" placeholder="contacto@correo.com.mx"
                />
              </div>
              {
                props.contactError.email && <p className="help is-danger">{props.contactError.email}</p>
              }
            </div>
          </div>
          <div className="column is-6 is-12-mobile">
            <div className="field">
              <label className="label">Celular</label>
              <div className="control">
                <input onChange={props.handleOnChangeContact} value={props.contact.cellphone} name='cellphone'
                  className={props.contactError.cellphone ? "input is-danger" : "input"} type="text" placeholder="8125362405"
                />
              </div>
              {
                props.contactError.cellphone && <p className="help is-danger">{props.contactError.cellphone}</p>
              }
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label className="label">Puesto</label>
              <div className="control">
                <div className={props.contactError.position ? "select is-danger" : "select"}>
                  <select name="position" value={props.contact.position} onChange={props.handleOnChangeContact} >
                    <option value="">------------</option>
                    {
                      props.dataForm.positions.map((item) => (
                        <option key={item.id} value={item.id} >{item.title}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              {
                props.contactError.position && <p className="help is-danger">{props.contactError.position}</p>
              }
            </div>
          </div>
        </div>
        <hr />
        <div className="field is-grouped is-grouped-right">
          <p className="control">
            <button type="submit" className="button is-primary">Aceptar</button>
          </p>
          <p className="control">
            <button onClick={props.cancelButtonPath} type="button" className="button is-light">Cancel</button>
          </p>
        </div>
      </form>
    </>
  )
};

export default ContactEditForm;