export const fiscalDataInitValues = {
  rfc: "",
  idCIF: "",
  not_factura: false,
  //fiscal_document: null,
  email: "",
};

export const fiscalDataInitErrors = {
  rfc: "",
  idCIF: "",
  not_factura: false,
  //fiscal_document: "",
  email: "",
};
