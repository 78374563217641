import React from 'react';
import { connect } from 'react-redux';
import * as httpService from '../../http-service/httpService';

import Icon from '@mdi/react';
import { mdiCheckBold } from '@mdi/js';

import Loading from '../utils/Loading';
import Modal from '../utils/Modal';



const OpportunityLostForm = ({ httpGet, httpPost, ...props }) => {
  const [lostReason, setLostReason] = React.useState('');
  const [lostReasonError, setLostReasonError] = React.useState({
    lost_reason: '',
    other: ''
  });
  const [requestStatus, setRequestStatus] = React.useState({
    loading: false, updated: false
  });
  const [other, setOther] = React.useState('');
  const [dataForm, setDataForm] = React.useState({ reason: [] });
  React.useEffect(() => {
    setRequestStatus(prev => ({ ...prev, loading: true }));
    httpGet(`/api/opportunity/lostreason/select/list/`)
      .then(response => {
        setDataForm({ reason: response.data });
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setRequestStatus(prev => ({ ...prev, loading: false }));
      });
  }, [httpGet,requestStatus.updated])
  const handleCancel = () => {
    setLostReason('');
    setOther('');
    setLostReasonError({
      lost_reason: '',
      other: ''
    });
    setRequestStatus({
      loading: false, updated: false
    });
    props.handleOnModalClose();
  }

  const handleAcceptLostOpportunity = () => {
    if (lostReason === -1 && other.length === 0) {
      setLostReasonError(prev => ({
        ...prev,
        other: 'Escribe un motivo'
      }));
    }
    else if (lostReason === -1 && other.length > 254){
      setLostReasonError(prev => ({
        ...prev,
        other: 'El motivo tiene que ser mas corto'
      }));
    }
    else {
      setRequestStatus(prev => ({ ...prev, loading: true }));
      httpPost(`/api/opportunity/lostreason/${props.opportunityData.opportunityUuid}/`, { lost_reason: lostReason, other })
        .then(response => {
          setRequestStatus(prev => ({ ...prev, updated: true }));
          setLostReasonError({
            lost_reason: '',
            other: ''
          });
        })
        .catch(error => {
          setRequestStatus(prev => ({ ...prev, updated: false }));
          error.response.data.errors.forEach(item => {
            if (item.field) {
              setLostReasonError(prev => ({ ...prev, [item.field]: item.message }));
            }
          });
        })
        .finally(() => {
          setRequestStatus(prev => ({ ...prev, loading: false }));
        });
    }
  }
  return (
    <Modal onClose={props.handleOnModalClose} isOpen={props.modalIsOpen} >
      <header className="modal-card-head has-background-danger">
        <p className="modal-card-title is-size-4 has-text-white">{props.opportunityData.establishmentName}, cancelar proceso</p>
        <button onClick={props.handleOnModalClose} className="delete jb-modal-close" aria-label="close"></button>
      </header>
      {requestStatus.loading ? (<section className="modal-card-body">
        <div className="columns is-centered">
          <div className="column is-narrow">
            <Loading />
          </div>
        </div>
      </section>)
        : (requestStatus.updated ?
          <section className="modal-card-body">
            <div className="columns is-centered">
              <div className="column is-narrow">
                <span className="has-text-success">
                  <Icon path={mdiCheckBold} title={'OK'} size={5} />
                </span>
              </div>
            </div>
          </section>
          : <section className="modal-card-body">
            <article className="message is-danger">
              <div className="message-body">
                El proceso de afiliacion para,"{props.opportunityData.establishmentName}" sera finalizado y se marcara como perdido.
              </div>
            </article>
            <div className="field">
              <label className="label">Motivo</label>
              <div className="control">
                {/* //classSelectPercent += props.opportunityError.perfil ? " is-danger" : ""; */}
                <div className={'select' + (lostReasonError.lost_reason ? " is-danger" : "")}>
                  <select name="lostReason" value={lostReason} onChange={(e) => setLostReason(Number(e.target.value))}>
                    <option value="">------------</option>
                    {
                      dataForm.reason.map((item) => (
                        <option key={item.id} value={item.id} >{item.name}</option>
                      ))
                    }
                    <option value="-1">Otro</option>
                  </select>
                </div>
              </div>
              {
                lostReasonError.lost_reason && <p className="help is-danger">{lostReasonError.lost_reason}</p>
              }
            </div>
            {lostReason === -1 &&
              (<div className="field">
                <label className="label">Escriba el motivo</label>
                <div className="control">
                  <input name='other' value={other} onChange={(e) => setOther(e.target.value)}
                    className={lostReasonError.other ? "input is-danger" : "input"} type="text" placeholder="Esta muy dificil"
                  />
                </div>
                {
                  lostReasonError.other && <p className="help is-danger">{lostReasonError.other}</p>
                }
              </div>)
            }
          </section>)}
      {requestStatus.updated ?
        <footer className="modal-card-foot">
          <button className="button jb-modal-close" onClick={handleCancel}>Aceptar</button>
        </footer>
        : <footer className="modal-card-foot">
          <button className="button jb-modal-close" onClick={handleAcceptLostOpportunity}>Aceptar</button>
          <button className="button jb-modal-close is-danger" onClick={handleCancel}>Cancelar</button>
        </footer>
      }
    </Modal>
  )
};

const { //actions
  httpGet, httpPost
} = httpService;

const mapDispatchToProps = { //actions
  httpGet, httpPost
};
export default connect(null, mapDispatchToProps)(OpportunityLostForm);