import React from 'react';
import { connect } from 'react-redux';

import * as httpService from '../../http-service/httpService';

import BankDataDetailV from '../../components/bankData/BankDataDetailV';
import Loading from '../../components/utils/Loading';


const BankDataDetail = ({ httpGet, match: { params: { establishmentUuid } }, ...props }) => {

  const [loading, setIsLoading] = React.useState(false);
  const [bankData, setBankData] = React.useState({
    bank_name: '',
    account: '',
    clabe: '',
    contact: {
      name: ''
    },
    establishment: {
      name:''
    }
  });

  React.useEffect(() => {
    setIsLoading(true);
    httpGet(`/api/establishment/bankdata/detail/${establishmentUuid}/`)
      .then(response => {
        console.log(response.data);
        setBankData(response.data)
        console.log(bankData);
      })
      .catch(error => console.log(error.response))
      .finally(() => {
        setIsLoading(false);
      });
  }, [establishmentUuid, httpGet])


  return (
    <>
      <section className="section is-main-section">
        {loading > 0 ?
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-narrow">
                <Loading />
              </div>
            </div>
          </div>
          : (<BankDataDetailV
            bankData={bankData}
            cancelButtonPath={props.history.goBack}
          />)
        }
      </section>
    </>
  )
};
const { //actions
  httpGet
} = httpService;

const mapDispatchToProps = { //actions
  httpGet
};
export default connect(null, mapDispatchToProps)(BankDataDetail);