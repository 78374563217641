import React from 'react';
import { connect } from 'react-redux';

import * as httpService from '../../http-service/httpService';

import Icon from '@mdi/react';
import { mdiCheckBold, mdiEmoticonDead } from '@mdi/js';

import BankDataController from '../../components/bankData/BankController';
import Card from '../../components/Card';
import Loading from '../../components/utils/Loading';
import shopnetLogo from '../../styles/static/shopnetLogo.png';

const BankDataTokenCreate = ({ httpGet, httpPost, match: { params: { token } }, ...props }) => {
  const urlPost = `/api/establishment/tokenauth/create/bankdata/${token}/`
  const bankData = {
    bank_id: '',
    bank_name: '',
    account: '',
    clabe: '',
  }
  const contact = {
    name: '',
    email: '',
    position: '',
    cellphone: '',
  }
  const [estabName, setEstabName] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [aproved, setAproved] = React.useState(false);
  const [message, setMessage] = React.useState({})
  React.useEffect(() => {
    httpPost(`/api/establishment/tokenauth/${token}/`)
      .then(response => {
        setLoading(false);
        setMessage(response.data.message)
        setAproved(response.data.valid)
        setEstabName(`de ${response.data.estab_name}`)
      })
      .catch(error => {
        setLoading(false);
      });
  }, [httpPost, token]);

  const handleSubmitSuccess = () => {
    setAproved(false);
    setMessage({
      'title': 'Completado',
      'message': 'Los datos bancarios ya han sido completados correctamente.'
    });
  }
  return (
    <>
      <section className="section is-main-section">
        <div className="columns is-centered">
          <img src={shopnetLogo} alt="" />
        </div>
        {loading ? <Loading /> :
          <Card title={`Agregar Datos Bancarios ${estabName} **Requeridos para afiliación del Negocio`}>
            {aproved ?
              <BankDataController
                bankDat={bankData}
                contactDat={contact}
                handleSubmitSuccess={handleSubmitSuccess}
                cancelButtonPath={props.history.goBack}
                urlPost={urlPost}
              /> : <>
                <div className="columns is-mobile is-centered my-5">
                  <div className="column is-narrow ">
                    <h1 className={message.title === "Completado" ? "title has-text-success" : "title has-text-danger"}>
                      <span className=""><Icon size={5} path={message.title === "Completado" ? mdiCheckBold : mdiEmoticonDead} title={"Error 404"} />
                        <i></i>
                      </span>
                    </h1>
                  </div>
                </div>
                <div className="columns is-mobile is-centered">
                  <div className="column is-narrow is-centered">
                    <h1>{message.message}</h1>
                  </div>
                </div>
              </>
            }
          </Card>
        }
      </section>
    </>
  )
};

const { //actions
  httpGet, httpPost
} = httpService;

const mapDispatchToProps = { //actions
  httpGet, httpPost
};

export default connect(null, mapDispatchToProps)(BankDataTokenCreate);