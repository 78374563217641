import { LOADING, ERROR, GET_TOKEN, RESET_ERROR, LOGOUT, LOGIN } from "../types/LoginTypes";

const INITIAL_STATE = {
  token: {
    access: null,
    refresh: null
  },
  errors: null,
  loading: false,
  user_data: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN:
      return { ...state, ...action.payload, loading: false, errors: null };
    case GET_TOKEN:
      return { ...state, token: action.payload, loading: false, errors: null };
    case LOADING:
      return { ...state, loading: true };
    case ERROR:
      return { ...state, ...action.payload, loading: false };
    case RESET_ERROR:
      return { ...state, errors: null, loading: false };
    case LOGOUT:
      localStorage.removeItem('current_user');
      return INITIAL_STATE;
    default: return state;
  }
}