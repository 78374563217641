import { mdiStore, mdiStorePlus, mdiStoreRemove } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { connect } from 'react-redux';


import { DateRangePicker } from 'react-dates';
import * as moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import isInclusivelyBeforeDay from 'react-dates/src/utils/isInclusivelyBeforeDay';

import * as httpService from '../../http-service/httpService';

import Nothing from '../utils/Nothing';
import OpportunityDashboardChart from '../charts/OpportunityDashboardChart';

const DashboardEjecutivo = ({ httpGet, ...props }) => {
  const [resumeEstablishment, setResumeEstablishment] = React.useState({
    "total": '##',
    "proceso": '##',
    "sin_proceso": '##'
  });
  const [dateRange, setDateRange] = React.useState({ startDate: moment().startOf('month'), endDate: moment() });
  const [focus, setFocus] = React.useState(null);

  const [resumeOpportunity, setResumeOpportunity] = React.useState(
    { config: {}, data: [] }
  );
  const [percentOpportunity, setPercentOpportunity] = React.useState(
    { config: {}, data: [] }
  );
  React.useEffect(() => {
    if (moment.isMoment(dateRange.endDate)) {
      httpGet(`/api/charts/resume/establishment/?start_date=${moment(dateRange.startDate).format("YYYY-MM-DD")}&end_date=${moment(dateRange.endDate).format("YYYY-MM-DD")}`)
        .then(response => {
          setResumeEstablishment(response.data);
        })
        .catch(error => {
          console.log(error);
        });
      httpGet(`/api/charts/resume/opportunity/?start_date=${moment(dateRange.startDate).format("YYYY-MM-DD")}&end_date=${moment(dateRange.endDate).format("YYYY-MM-DD")}`)
        .then(response => {
          console.log(response.data);
          setResumeOpportunity(response.data);
        })
        .catch(error => {
          console.log(error);
        });
      httpGet(`/api/charts/percent/opportunity/?start_date=${moment(dateRange.startDate).format("YYYY-MM-DD")}&end_date=${moment(dateRange.endDate).format("YYYY-MM-DD")}`)
        .then(response => {
          console.log(response.data);
          setPercentOpportunity(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [httpGet, dateRange]);
  return (
    <>
      <section className="section is-main-section">
        <nav className="level">
          <div className="level-left">
          </div>
          <div className="level-right">
            <div className="level-item">
              <DateRangePicker
                startDate={dateRange.startDate} // momentPropTypes.momentObj or null,
                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                endDate={dateRange.endDate} // momentPropTypes.momentObj or null,
                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => setDateRange({ startDate, endDate })} // PropTypes.func.isRequired,
                focusedInput={focus} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => setFocus(focusedInput)} // PropTypes.func.isRequired,
                // para habilitar o deshabilitar dias del calendario
                isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                displayFormat={moment.localeData().postformat("yyyy/MM/DD")}
              />
            </div>
          </div>
        </nav>
        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content">
                <div className="level is-mobile">
                  <div className="level-item">
                    <div className="is-widget-label">
                      <h3 className="subtitle is-spaced">
                        Est. Totales
                      </h3>
                      <h1 className="title">
                        {resumeEstablishment.total}
                      </h1>
                    </div>
                  </div>
                  <div className="level-item has-widget-icon">
                    <div className="is-widget-icon">
                      <span className="icon has-text-primary is-large">
                        <Icon path={mdiStore} size={'48px'} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content">
                <div className="level is-mobile">
                  <div className="level-item">
                    <div className="is-widget-label">
                      <h3 className="subtitle is-spaced">
                        Est. en Proceso
                      </h3>
                      <h1 className="title">
                        {resumeEstablishment.proceso}
                      </h1>
                    </div>
                  </div>
                  <div className="level-item has-widget-icon">
                    <div className="is-widget-icon">
                      <span className="icon has-text-success is-large">
                        <Icon path={mdiStorePlus} size={'48px'} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content">
                <div className="level is-mobile">
                  <div className="level-item">
                    <div className="is-widget-label">
                      <h3 className="subtitle is-spaced">
                        Est. sin Proceso
                      </h3>
                      <h1 className="title">
                        {resumeEstablishment.sin_proceso}
                      </h1>
                    </div>
                  </div>
                  <div className="level-item has-widget-icon">
                    <div className="is-widget-icon">
                      <span className="icon has-text-danger is-large">
                        <Icon path={mdiStoreRemove} size={'48px'} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="columns is-multiline">
          <div className="column is-6 is-12-mobile">
            <div className="tile is-ancestor">
              <div className="tile is-parent">
                <div className="tile is-child card">
                  <div className="card-header">
                    <p className="card-header-title">Resumen de Procesos</p>
                  </div>
                  <div className="card-content">
                    {resumeOpportunity.data.length > 0
                      ? <OpportunityDashboardChart responseData={resumeOpportunity} />
                      : <Nothing />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-6 is-12-mobile">
            <div className="tile is-ancestor">
              <div className="tile is-parent">
                <div className="tile is-child card">
                  <div className="card-header">
                    <p className="card-header-title">Avance de Procesos</p>
                  </div>
                  <div className="card-content">
                    {percentOpportunity.data.length > 0
                      ? <OpportunityDashboardChart responseData={percentOpportunity} />
                      : <Nothing />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
};

const { //actions
  httpGet
} = httpService;

const mapDispatchToProps = { //actions
  httpGet
};
export default connect(null, mapDispatchToProps)(DashboardEjecutivo);