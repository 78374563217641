import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Icon, { Stack } from '@mdi/react';
import { mdiPencil, mdiAccountKey, mdiAccountPlus, mdiBlockHelper, mdiLockReset, mdiCheckBold } from '@mdi/js';

import * as httpService from '../../http-service/httpService';

import PaginatedTable from '../../components/tables/PaginatedTable';
import Modal from '../../components/utils/Modal';


const UserList = ({ httpGet, httpPost, ...props }) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [data, setData] = React.useState([])

  const handlePasswordReset = (userUuid) => {
    // post asignpassword
    httpPost(`/api/user/assignpassword/${userUuid}/`)
      .then(response => {
        setModalIsOpen(true);
        console.log("ok");
      })
      .catch(error => {
        console.log(error);
      })
  }
  const handleActive = (userUuid, active, index) => {
    setData(prev => {
      const newValue = [...prev];
      newValue[index] = {
        ...newValue[index], loading: true
      }
      return newValue;
    });
    httpPost(`/api/user/delete/${userUuid}/`, { is_active: !active })
      .then(response => {
        setData(prev => {
          const newValue = [...prev];
          newValue[index] = {
            ...newValue[index], is_active: !active
          }
          return newValue;
        });
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setData(prev => {
          const newValue = [...prev];
          newValue[index] = {
            ...newValue[index], loading: false
          }
          return newValue;
        });
      });
  }
  const columns = React.useMemo(
    () => [
      {
        Header: 'Nombre',
        disableFilters: true,
        accessor: 'full_name'
      },
      {
        Header: 'Correo',
        disableFilters: true,
        accessor: 'email',
      },
      {
        Header: 'Fecha de alta',
        disableFilters: true,
        accessor: 'date_joined',
      },
      {
        Header: 'Encargado',
        disableFilters: true,
        accessor: 'in_charge',
      },
      {
        Header: 'Permisos',
        disableFilters: true,
        accessor: 'groups',
        Cell: ({ cell: { value } }) => {
          return (
            <>
              {
                value.map((item, key) => <span key={key} className="tag is-dark" >{item.name}</span>)
              }
            </>
          )
        }
      },
      {
        Header: "",
        disableFilters: true,
        accessor: "uuid",
        Cell: ({ cell: { value, row: { original, index } } }) => (
          <div className="buttons is-right">
            <button className="button is-small is-info" type="button"
              onClick={() => handlePasswordReset(value, index)} title="Asignar Contraseña"
            >
              <span className="icon">
                <Icon path={mdiLockReset} size={1.5} spin={original.loading} />
              </span>
            </button>
            <Link className="button is-small is-primary" to={{ pathname: `/users/edit/${value}`, state: { from: props.location } }}>
              <span className="icon"><Icon path={mdiPencil} title={"Editar"} size={'1rem'} /></span>
            </Link>
            {
              original.is_active ?
                <button className="button is-small is-success" type="button" disabled={original.loading}
                  onClick={() => handleActive(value, original.is_active, index)}
                >
                  <span className="icon">
                    <Icon path={mdiAccountKey} size={1.5} spin={original.loading} />
                  </span>
                </button>
                :
                <button className="button is-small is-danger" type="button" disabled={original.loading}
                  onClick={() => handleActive(value, original.is_active, index)}
                >
                  <span className="icon">
                    <Stack size={2} >
                      <Icon path={mdiAccountKey} size={1.5} spin={original.loading} />
                      <Icon path={mdiBlockHelper} spin={original.loading} />
                    </Stack>
                  </span>
                </button>
            }
          </div >
        )
      }
    ],
    [handleActive]
  )

  const handleOnModalClose = () => {
    setModalIsOpen(false);
  }
  return (
    <>
      <section className="section is-main-section">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              {' '}
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <Link className="button is-info" to={{ pathname: `/users/create`, state: { from: props.location } }}>
                <span>Agregar Usuario</span>
                <span className="icon is-small">
                  <Icon path={mdiAccountPlus} size={'1rem'} />
                </span>
              </Link>
            </div>
          </div>
        </div>
        <PaginatedTable
          columns={columns}
          url={`/api/user/list/`}
          data={data}
          setData={setData}
          url_excel={`/api/user/excel/?format=xlsx`}
          excelFileName={'usuarios'}
          dateRangeActive={false}
        />
        <Modal onClose={handleOnModalClose} isOpen={modalIsOpen} >
          <header className="modal-card-head">
            <p className="modal-card-title is-size-4">Contraseña Asignada</p>
            <button onClick={handleOnModalClose} className="delete jb-modal-close" aria-label="close"></button>
          </header>
          <section className="modal-card-body">
            <div className="columns is-centered">
              <div className="column is-narrow">
                <span className="has-text-success">
                  <Icon path={mdiCheckBold} title={'OK'} size={5} />
                </span>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button className="button jb-modal-close" onClick={handleOnModalClose}>Aceptar</button>
          </footer>
        </Modal>
      </section>
    </>
  )
};

const { //actions
  httpGet, httpPost
} = httpService;

const mapDispatchToProps = { //actions
  httpGet, httpPost
};
export default connect(null, mapDispatchToProps)(UserList);