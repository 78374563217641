import React from 'react';
import Icon from '@mdi/react';
import { mdiUpload } from '@mdi/js';

import MapLocation from "../utils/MapLocation"

const MarkImageForm = (props) => {

    return (
        <form onSubmit={props.handleOnSubmit}>
            <div className="columns is-multiline">
                <div className="column is-6 is-12-movile">

                    <div className="field">
                        <div className="control">
                            <h2>{props.address}</h2>
                            {props.clickedLatLng === null ?
                                <button
                                    type="button"
                                    className="button is-primary"
                                    onClick={props.onClickGenerateMap}
                                > Generar Puntero</button>
                                :
                                <MapLocation
                                    clickedLatLng={props.clickedLatLng}
                                    onClick={props.onClickMap}
                                />
                            }
                        </div>
                    </div>
                </div>
                <div className="column is-6 is-12-movile">
                    <div className="field">
                        <div className="control ml-3">
                            <div className="file">
                                <label className="file-label">
                                    <input accept="image/png, image/jpeg" type="file" className="file-input" onChange={props.handleOnChangeImg} />
                                    <span className="file-cta">
                                        <span className="file-icon">
                                            <Icon path={mdiUpload} size={'1rem'} />
                                        </span>
                                        <span className="file-label">
                                            Seleccione imagen… </span>
                                    </span>
                                </label>
                            </div>
                            <div>
                                {props.logo && <div><img width="50%" src={props.logo} alt="..." /> </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="field is-grouped is-grouped-right">
                <button disabled={props.logo === null || props.clickedLatLng === null} type="submit" className="button mr-1" > Actualizar </button>
                <button
                    type="button"
                    className="button is-danger"
                    onClick={props.cancelButtonPath}>
                    Regresar
                </button>
            </div>
        </form>
    )
}

export default MarkImageForm;

