import React from "react";

export const ModalBody = ({ children = null }) => {
  return (
    <section className="modal-card-body">
      <div className="columns is-centered">
        <div className={"column is-12 has-text-centered"}>{children}</div>
      </div>
    </section>
  );
};
