import React from 'react';

const HeroBarPage = ({title}) => {
  return (
    <section className="hero is-hero-bar">
      <div className="hero-body">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <h1 className="title">
                {title}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroBarPage;