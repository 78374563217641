import React, { useState } from 'react';

import Icon from '@mdi/react';

import { NavLink } from 'react-router-dom';

const AsideMenu = ({ routes }) => {
  const [dropDown, setDropDown] = useState(true);

  React.useEffect(() => {
    document.documentElement.classList.add("has-aside-left");
    document.documentElement.classList.add("has-navbar-fixed-top");
    document.documentElement.classList.add("has-aside-expanded");
  }, []);

  const handledropDown = (event) => {
    setDropDown(!dropDown);
  };
  const norm_routes = {};
  routes.filter((route) => route.showMenu).map((route) => (
    norm_routes[route.section] = {
      ...norm_routes[route.section],
      [route.name]: {
        ...route
      }
    }
  ));

  const mostrarMenu = () => {
    return Object.keys(norm_routes).map((section, key) => (
      <React.Fragment key={key}>
        {section !== "null" && <p className="menu-label">{section}</p>}
        <ul className="menu-list">
          {ponerEntrada(section)}
        </ul>
      </React.Fragment>
    ));
  }

  const ponerEntrada = (section) => {
    const bySection = {
      ...norm_routes[section]
    }

    return Object.keys(bySection).map((entry, key) => (
      <li key={key}>
        <NavLink exact activeClassName="is-active" to={bySection[entry].layout + bySection[entry].path} className="has-icon">
          <span className="icon"><Icon path={bySection[entry].icon} size={'1rem'} title={bySection[entry].name} /></span>
          <span className="menu-item-label">{bySection[entry].name}</span>
        </NavLink>
      </li>
    ));
  }
  return (
    <aside className="aside is-placed-left is-expanded">
      <div className="aside-tools">
        <div className="aside-tools-label">
          <span><b>SHOPNET</b> Work CRM</span>
        </div>
      </div>
      <div className="menu is-menu-main">
        {mostrarMenu()}
      </div>
    </aside>
  )
};

export default AsideMenu;