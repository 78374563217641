import React from 'react';
import BankDataController from '../../components/bankData/BankController';
import Card from '../../components/Card';


const BankDataCreate = ({ match: { params: { establishmentUuid } }, ...props }) => {
  const urlPost = `/api/establishment/bankdata/create/${establishmentUuid}/`
  const bankData = {
    bank_id: '',
    bank_name: '',
    account: '',
    clabe: '',
  }
  const contact = {
    name: '',
    email: '',
    position: '',
    cellphone: '',
  }
  const handleSubmitSuccess = () => {
    props.history.goBack()
  }
  return (
    <>
      <section className="section is-main-section">
        <Card title={'Agregar Datos Bancarios **Esta sección debe ser llenada por un contacto del establecimiento'}>
        <BankDataController
            bankDat={bankData}
            contactDat={contact}
            handleSubmitSuccess={handleSubmitSuccess}
            cancelButtonPath={props.history.goBack}
            urlPost={urlPost}
          />
        </Card>
      </section>
    </>
  )
};

export default BankDataCreate;