import React from 'react';

import Select from 'react-select';

const UserForm = (props) => {

  // ticket datos
  // parser_data -> parsed_data
  return (
    <>
      <form onSubmit={props.handleOnSubmit}>
        <div className="columns is-multiline">
          <div className="column is-6 is-12-movile">
            <div className="field">
              <label className="label">Nombre</label>
              <div className="control">
                <input onChange={props.handleOnChangeForm} name='name' value={props.user.name}
                  className={props.userError.name ? "input is-danger" : "input"} type="text" placeholder="Juan"
                />
              </div>
              {
                props.userError.name && <p className="help is-danger">{props.userError.name}</p>
              }
            </div>
          </div>
          <div className="column is-6 is-12-movile">
            <div className="field">
              <label className="label">Apellido</label>
              <div className="control">
                <input onChange={props.handleOnChangeForm} name='lastname' value={props.user.lastname}
                  className={props.userError.lastname ? "input is-danger" : "input"} type="text" placeholder="Perez Castro"
                />
              </div>
              {
                props.userError.lastname && <p className="help is-danger">{props.userError.lastname}</p>
              }
            </div>
          </div>
          <div className="column is-6 is-12-mobile">
            <div className="field">
              <label className="label">email</label>
              <div className="control">
                <input onChange={props.handleOnChangeForm} value={props.user.email} name='email'
                  className={props.userError.email ? "input is-danger" : "input"} type="email" placeholder="usuario@shopnet.com.mx"
                />
              </div>
              {
                props.userError.email && <p className="help is-danger">{props.userError.email}</p>
              }
            </div>
          </div>
          <div className="column is-6 is-12-mobile">
            <div className="field">
              <label className="label">Celular</label>
              <div className="control">
                <input onChange={props.handleOnChangeForm} value={props.user.cellphone} name='cellphone'
                  className={props.userError.cellphone ? "input is-danger" : "input"} type="text" placeholder="81152369505"
                />
              </div>
              {
                props.userError.cellphone && <p className="help is-danger">{props.userError.cellphone}</p>
              }
            </div>
          </div>
          <div className="column is-4 is-12-mobile">
            <div className="field">
              <label className="label">Permiso</label>
              <div className="control">
                <div className={"select" + (props.userError.groups ? " is-danger" : "") + (props.dataForm.groups.loading ? " is-loading" : "")}>
                  <select name="groups" value={props.user.groups} onChange={props.handleOnChangeForm} id="">
                    <option value="">--------------</option>
                    {
                      props.dataForm.groups.data.map((item) => (
                        <option key={item.id} value={item.id}>{item.name}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              {
                props.userError.groups && <p className="help is-danger">{props.userError.groups}</p>
              }
            </div>
          </div>
          <div className="column is-4 is-12-mobile">
            <div className="field">
              <label className="label">Estado</label>
              <div className="control">
                <div className={"select" + (props.userError.state ? " is-danger" : "") + (props.dataForm.states.loading ? " is-loading" : "")}>
                  <select name="state" value={props.user.state} onChange={props.handleOnChangeForm} id="">
                    <option value="">------------</option>
                    {
                      props.dataForm.states.data.map((item) => (
                        <option key={item.id} value={item.id} >{item.estado}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              {
                props.userError.state && <p className="help is-danger">{props.userError.state}</p>
              }
            </div>
          </div>
          <div className="column is-6 is-12-mobile">
            <div className="field">
              <label className="label">Encargado</label>
              <div className="control has-icons-left">
                <div>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    name="users"
                    value={props.dataForm.users.data[props.getIndexValue(props.user.in_charge)]}
                    defaultValue={props.dataForm.users.data[props.getIndexValue(props.user.in_charge)]}
                    onChange={props.handleOnChangeExecutive}
                    isSearchable={true}
                    isClearable={true}
                    isLoading={props.dataForm.users.isLoading}
                    options={props.dataForm.users.data}
                  >
                  </Select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="field is-grouped is-grouped-right">
          <p className="control">
            <button type="submit" className="button is-primary">Aceptar</button>
          </p>
          <p className="control">
            <button onClick={props.cancelButtonPath} type="button" className="button is-light">Cancel</button>
          </p>
        </div>
      </form>
    </>
  )
};

export default UserForm;