import {
  mdiSquareEditOutline, mdiViewDashboardOutline,
  mdiAccountCircle,  mdiViewList,mdiDomainPlus,mdiFormatListBulleted,
  mdiAccount,mdiBookAccount,mdiCurrencyUsd, mdiAccountGroup,
  mdiAccountPlus, mdiCancel
} from '@mdi/js';

import Base from './pages/Base';
import Error404 from './pages/Error404';
import OpportunityList from './pages/oportunity/OpportunityList';
import ManagerOpportunityList from './pages/oportunity/ManagerOpportunityList';
// import Dragable from './pages/Dragable';
import RegisterEstablishment from './pages/establishment/RegisterEstablishment';
import ListEstablishments from './pages/establishment/ListEstablishments';
import ManagerListEstablishments from './pages/establishment/ManagerListEstablishments';
import EstablishmentEdit from './pages/establishment/EstablishmentEdit';
import ContactCreate from './pages/establishment/ContactCreate';
import ContactList from './pages/establishment/ContactList';
import ContactEdit from './pages/establishment/ContactEdit';
import OpportunityDetail from './pages/oportunity/OpportunityDetail';
import UserList from './pages/user/UserList';
import UserCreate from './pages/user/UserCreate';
import ActivityList from './pages/oportunity/ActivityList';
import UserEdit from './pages/user/UserEdit';
import LostReasonsList from './pages/oportunity/LostReasonsList';
import FiscalDataCreate from './pages/oportunity/FiscalDataCreate';
import FiscalDataEdit from './pages/oportunity/FiscalDataEdit';
import Dashboard from './pages/dashboard/Dashboard';
import BankDataCreate from './pages/establishment/BankDataCreate';
import RegisterEstablishmentBank from './pages/establishment/RegisterEstablishmentBank'
import BankDataDetail from './pages/establishment/BankDataDetail';
import MarkImgAdd from './pages/establishment/MarkImgAdd';
import MarkImgEdit from './pages/establishment/MarkImgEdit';
import AfiliationList from './pages/afiliation/AfiliationList';

const dashboardRoutes = [
  {
    path: '/',
    icon: mdiViewDashboardOutline,
    component: Dashboard,
    name: 'Dashboard',
    section: "General",
    showMenu: true,
    layout: ""
  },
  {
    path: '/afiliations/list',
    icon: mdiDomainPlus,
    component: AfiliationList,
    name: 'Afiliaciones',
    section: "Admin",
    showMenu: true,
    layout: "",
    groupRequired: 'Admin'
  },
  {
    path: '/establishment/new',
    icon: mdiDomainPlus,
    component: RegisterEstablishment,
    name: 'Nuevo Establecimiento',
    section: "General",
    showMenu: false,
    layout: ""
  },
  {
    path: '/establishmentBank/new',
    icon: mdiDomainPlus,
    component: RegisterEstablishmentBank,
    name: 'Nuevo Establecimiento',
    section: "General",
    showMenu: false,
    layout: ""
  },
  {
    path: '/establishment/list',
    icon: mdiFormatListBulleted,
    component: ListEstablishments,
    name: 'Mis Establecimientos',
    section: "General",
    showMenu: true,
    layout: "",
  },
  {
    path: '/admin/establishment/list',
    icon: mdiFormatListBulleted,
    component: ManagerListEstablishments,
    name: 'Establecimientos',
    section: "Admin",
    showMenu: true,
    layout: "",
    groupRequired:"Admin"
  },
  {
    path: '/opportunity/list',
    icon: mdiCurrencyUsd,
    component: OpportunityList,
    name: 'Proceso de Venta',
    section: "General",
    showMenu: true,
    layout: "",
  },
  {
    path: '/admin/opportunity/list',
    icon: mdiCurrencyUsd,
    component: ManagerOpportunityList,
    name: 'Procesos de Ventas',
    section: "Admin",
    showMenu: true,
    layout: "",
    groupRequired:"Admin"
  },
  {
    path: '/establishment/edit/:uuid',
    icon: mdiFormatListBulleted,
    component: EstablishmentEdit,
    name: 'Editar Establecimiento',
    section: "General",
    showMenu: false,
    layout: ""
  },
  {
    path: '/establishment/addMarkImg/:establishmentUuid',
    icon: mdiFormatListBulleted,
    component: MarkImgAdd,
    name: 'Agregar Puntero e Imagen',
    section: "General",
    showMenu: false,
    layout: ""
  },
  {
    path: '/establishment/editMarkImg/:establishmentUuid',
    icon: mdiFormatListBulleted,
    component: MarkImgEdit,
    name: 'Editar Puntero e Imagen',
    section: "General",
    showMenu: false,
    layout: ""
  },
  {
    path: '/contact/list/:uuid',
    icon: mdiBookAccount,
    component: ContactList,
    name: 'Lista de Contactos',
    section: "General",
    showMenu: false,
    layout: ""
  },
  {
    path: '/contact/create/:uuid',
    icon: mdiAccount,
    component: ContactCreate,
    name: 'Agregar Contacto',
    section: "General",
    showMenu: false,
    layout: ""
  },
  {
    path: '/contact/edit/:uuid',
    icon: mdiAccount,
    component: ContactEdit,
    name: 'Editar Contacto',
    section: "General",
    showMenu: false,
    layout: ""
  },
  {
    path: '/opportunity/detail/:opportunityUuid',
    icon: mdiFormatListBulleted,
    component: OpportunityDetail,
    name: 'Detalle del proceso',
    section: "General",
    showMenu: false,
    layout: ""
  },
  {
    path: '/opportunity/activity/:opportunityUuid',
    icon: mdiFormatListBulleted,
    component: ActivityList,
    name: 'Lista de actividades',
    section: "General",
    showMenu: false,
    layout: ""
  },
  {
    path: '/users/list',
    icon: mdiAccountGroup,
    component: UserList,
    name: 'Usuarios',
    section: "Admin",
    showMenu: true,
    layout: "",
    groupRequired: 'Admin'
  },
  {
    path: '/users/create',
    icon: mdiAccountPlus,
    component: UserCreate,
    name: 'Agregar Usuario',
    section: "Admin",
    showMenu: false,
    layout: "",
    groupRequired: 'Admin'
  },
  {
    path: '/users/edit/:userUuid',
    icon: mdiAccountPlus,
    component: UserEdit,
    name: 'Editar Usuario',
    section: "Admin",
    showMenu: false,
    layout: "",
    groupRequired: 'Admin'
  },
  {
    path: '/lostreasons/list',
    icon: mdiCancel,
    component: LostReasonsList,
    name: 'Reason Code',
    section: "Admin",
    showMenu: true,
    layout: "",
    groupRequired: 'Admin'
  },
  {
    path: '/fiscalData/create/:opportunityUuid',
    icon: mdiAccountPlus,
    component: FiscalDataCreate,
    name: 'Agregar Datos Fiscales',
    section: "General",
    showMenu: false,
    layout: ""
  },
  {
    path: '/bankData/create/:establishmentUuid',
    icon: mdiAccountPlus,
    component: BankDataCreate,
    name: 'Agregar Datos Bancarios',
    section: "General",
    showMenu: false,
    layout: "",
  },
  {
    path: '/bankData/detail/:establishmentUuid',
    icon: mdiAccountPlus,
    component: BankDataDetail,
    name: 'Datos Bancarios',
    section: "General",
    showMenu: false,
    layout: ""
  },
  {
    path: '/fiscalData/edit/:opportunityUuid',
    icon: mdiAccountPlus,
    component: FiscalDataEdit,
    name: 'Editar Datos Fiscales',
    section: "General",
    showMenu: false,
    layout: ""
  },
  {
    path: '/forms',
    icon: mdiSquareEditOutline,
    component: Base,
    name: 'Forms',
    section: "Other",
    showMenu: true,
    layout: ""
  },
  {
    path: '/profile',
    icon: mdiAccountCircle,
    component: Base,
    name: 'Profile',
    section: "Other",
    showMenu: true,
    layout: ""
  },
  {
    path: '/submenus',
    icon: mdiViewList,
    component: Base,
    name: 'Submenus',
    section: null,
    showMenu: true,
    layout: ""
  },
  {
    path: '/top/secret',
    icon: mdiViewDashboardOutline,
    component: Base,
    name: 'Admin view',
    section: "Admin",
    showMenu: true,
    layout: "",
    groupRequired: 'Root'
  },
  {
    path: '/404',
    icon: mdiViewList,
    component: Error404,
    name: 'Not Found',
    section: null,
    showMenu: false,
    layout: ""
  },
]

export default dashboardRoutes;