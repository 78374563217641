import React from "react";
import Icon from "@mdi/react";
import {
  mdiAccount,
  mdiMapMarkerRadius,
  mdiPhoneClassic,
  mdiBookAccount,
  mdiClipboardCheck,
  mdiEmail,
  mdiPlus,
  mdiPencil,
  mdiCurrencyUsd,
} from "@mdi/js";
import { Link } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Select from 'react-select';

const OpportunityEditForm = ({ opportunity, dataForm, ...props }) => {
  return (
    <form onSubmit={props.handleOnSubmit}>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header
              className={
                "card-header" +
                (opportunity.active
                  ? ""
                  : " has-background-danger has-text-white") +
                (props.completed ? " has-background-success" : "")
              }
            >
              <div
                className={
                  "card-header-title" +
                  (opportunity.active && !props.completed ? "" : " has-text-white")
                }
                style={{ justifyContent: "space-between" }}
              >
                <div>
                  <span className="icon">
                    <Icon path={mdiAccount} size={'1rem'} />
                  </span>
                  Detalle del Proceso {props.completed && (
                    !opportunity.admin_estab_id ? "FOLIO: " + opportunity.folio : "Admin id: " + opportunity.admin_estab_id
                  )
                  }
                </div>
                {props.completed & !opportunity.admin_estab_id ?
                  <div className="ml-3">
                    <CopyToClipboard text={opportunity.folio}>
                      <button className="button is-info" type="button" data-tooltip={"Copiar Folio: " + opportunity.folio}>Copiar Folio</button>
                    </CopyToClipboard>
                  </div> : ""
                }
              </div>
            </header>
            <div className="card-content">
              <div className="columns is-multiline">

                <div className="column is-6 is-12-movile">
                  <div className="field-label"></div>
                  <div className="field-body">
                    <div className="field">
                      <label className="label">1°- Calificacion</label>
                      <div className="field is-grouped-multiline">
                        {dataForm.ratings.data.map((item, key) => (
                          <div className="control" key={key}>
                            <label
                              className="b-checkbox checkbox"
                              disabled={!opportunity.active || props.completed}
                            >
                              <input
                                type="checkbox"
                                value={item.id}
                                name="rating"
                                checked={dataForm.ratings.checked[item.id]}
                                onChange={props.handleCheckChange}
                                disabled={
                                  !opportunity.active || props.completed
                                }
                              />
                              <span className="check is-primary"></span>
                              <span
                                className={
                                  props.opportunityError.rating
                                    ? "control-label has-text-danger"
                                    : "control-label"
                                }
                              >
                                {item.name}
                              </span>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  {props.opportunityError.rating && (
                    <p className="help is-danger">
                      {props.opportunityError.rating}
                    </p>
                  )}
                  <div className="field">
                    <label className="label">2°-Perfiles </label>
                    <div className="control has-icons-left">
                      <label>Perfil Establecimientos: </label>
                      <div>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          name="perfil"
                          value={dataForm.percents.data[props.getIndexValue(dataForm.percents.data, opportunity.perfil)]}
                          defaultValue={dataForm.percents.data[props.getIndexValue(dataForm.percents.data, opportunity.perfil)]}
                          onChange={props.handlePercentChange}
                          isDisabled={!opportunity.active || props.completed}
                          isSearchable={true}
                          isClearable={true}
                          isLoading={dataForm.percents.isLoading}
                          options={dataForm.percents.data}
                        >
                        </Select>
                      </div>
                      {/* <span className="icon is-small is-left">
                        <Icon path={mdiBrightnessPercent} size={'1rem'} />
                      </span> */}
                    </div>
                    {props.opportunityError.perfil && (
                      <p className="help is-danger">
                        {props.opportunityError.perfil}
                      </p>
                    )}
                    {
                      (
                        props.permissions.includes("Admin") ||
                        props.permissions.includes("Ejecutivo") ||
                        props.permissions.includes('Agencia Distribuidor')
                      ) && <>
                        <div className="control has-icons-left">
                          <label>Perfil Meseros: </label>
                          <div>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              name="perfil_meseros"
                              value={dataForm.waiterspercents.data[props.getIndexValue(dataForm.waiterspercents.data, opportunity.perfil_meseros)]}
                              defaultValue={dataForm.waiterspercents.data[props.getIndexValue(dataForm.waiterspercents.data, opportunity.perfil_meseros)]}
                              onChange={props.handlePercentWaiterChange}
                              isDisabled={!opportunity.active || props.completed}
                              isSearchable={true}
                              isClearable={true}
                              isLoading={dataForm.waiterspercents.isLoading}
                              options={dataForm.waiterspercents.data}
                            >
                            </Select>
                          </div>
                        </div>
                        <div className="control has-icons-left column is-3">
                          <label>Redencion: </label>
                          <div>
                            <input onChange={props.handleOpportunityChange} name='redemption' value={opportunity.redemption}
                              className={props.opportunityError.redemption ? "input is-danger" : "input"} type="number"
                            />
                          </div>
                        </div>
                      </>
                    }
                  </div>
                  <div className="field">
                    <div className="columns is-vcentered">
                      <div className="column is-narrow">
                        <label className="label">3°- Datos Fiscales</label>
                      </div>
                      {!opportunity.admin_estab_id &&
                        <div className="column">
                          {!opportunity.fiscal_data ?
                            <Link to={{ pathname: `/fiscalData/create/${opportunity.uuid}` }} className="button is-info" >
                              <span>Agregar</span>
                              <span className="icon is-small">
                                <Icon path={mdiPlus} size={'1rem'} />
                              </span>
                            </Link>
                            :
                            <Link to={{ pathname: `/fiscalData/edit/${opportunity.uuid}` }} className="button is-info" >

                              <span>Editar</span>
                              <span className="icon is-small">
                                <Icon path={mdiPencil} size={'1rem'} />
                              </span>
                            </Link>
                          }
                        </div>
                      }
                    </div>
                    {opportunity.fiscal_data &&
                      <div className="content">
                        <p>Razon Social: {opportunity.fiscal_data.fiscalname}</p>
                        <p>RFC: {opportunity.fiscal_data.rfc}</p>
                        <p>
                          <span className="icon is-small">
                            <Icon path={mdiEmail} size={'1rem'} />
                          </span>
                          Email: {opportunity.fiscal_data.email}
                        </p>
                        <p>
                          <span className="icon is-small">
                            <Icon path={mdiMapMarkerRadius} size={'1rem'} />
                          </span>
                          Direccion: {opportunity.fiscal_data.full_address}
                        </p>
                      </div>
                    }
                  </div>
                </div>
                <div className="column is-6 is-12-movile">
                  <div className="field">
                    <div className="columns is-vcentered">
                      <div className="column is-narrow">
                        <label className="label">4°-Puntero e Imagen</label>
                      </div>
                      {!opportunity.admin_estab_id &&
                        <div className="column">
                          {opportunity.establishment.img_logo.length === 0 ?
                            <Link to={{ pathname: `/establishment/addMarkImg/${opportunity.establishment.uuid}` }} className="button is-info" >
                              <span>Agregar</span>
                              <span className="icon is-small">
                                <Icon path={mdiPlus} size={'1rem'} />
                              </span>
                            </Link>
                            :
                            <Link to={{ pathname: `/establishment/editMarkImg/${opportunity.establishment.uuid}` }} className="button is-info" >
                              <span>Editar</span>
                              <span className="icon is-small">
                                <Icon path={mdiPencil} size={'1rem'} />
                              </span>
                            </Link>
                          }
                        </div>
                      }
                    </div>
                    {opportunity.establishment.img_logo.length > 0 &&
                      <div className="content">
                        <div className="columns is-centered">
                          <img width="50%" src={props.imageURL} alt="..." />
                        </div>
                      </div>
                    }
                  </div>
                  <div className="field">
                    <div className="columns is-vcentered">
                      <div className="column is-narrow">
                        <label className="label">Establecimiento</label>
                      </div>
                      <div className="column">
                        {!opportunity.bank_data ?
                          <Link to={{ pathname: `/bankData/create/${opportunity.establishment.uuid}` }} className="button is-warning" >
                            <span>Agregar Datos Bancarios</span>
                            <span className="icon is-small">
                              <Icon path={mdiPlus} size={'1rem'} />
                            </span>
                          </Link> :
                          <Link to={{ pathname: `/bankData/detail/${opportunity.establishment.uuid}` }} className="button is-info" >
                            <span>Ver Datos Bancarios</span>
                            <span className="icon is-small">
                              <Icon path={mdiCurrencyUsd} size={'1rem'} />
                            </span>
                          </Link>
                        }
                      </div>
                    </div>
                    <div className="content">
                      <p>{opportunity.establishment.name}</p>
                      <p>
                        <span className="icon is-small">
                          <Icon path={mdiMapMarkerRadius} size={'1rem'} />
                        </span>
                        {opportunity.establishment.full_address}
                      </p>
                      <p>
                        <span className="icon is-small">
                          <Icon path={mdiPhoneClassic} size={'1rem'} />
                        </span>
                        {opportunity.establishment.phone}
                      </p>
                      <p>
                        <Link to={{ pathname: `/opportunity/activity/${opportunity.uuid}`, state: { from: props.location } }}>
                          <span className="icon is-small">
                            <Icon path={mdiClipboardCheck} size={'1rem'} />
                          </span>
                          {opportunity.activity_count === 0 ? (
                            <>{"+ Agregar Actividad"}</>
                          ) : (
                            <>
                              ({opportunity.activity_count}) Ver{" "}
                              {true ? "Actividades" : "Actividad"}{" "}
                            </>
                          )}
                        </Link>
                      </p>
                      <p>
                        <Link
                          to={{ pathname: `/contact/list/${opportunity.establishment.uuid}`, state: { from: props.location } }}
                        >
                          <span className="icon is-small">
                            <Icon path={mdiBookAccount} size={'1rem'} />
                          </span>
                          ({opportunity.establishment.contacts_count}) Ver
                          Contactos
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="field is-grouped is-grouped-right">
                <button
                  type={
                    opportunity.active || props.completed ? "submit" : "button"
                  }
                  disabled={!opportunity.active || props.completed}
                  className="button mr-1"
                >
                  Actualizar
                </button>
                <button
                  type="button"
                  className="button is-danger"
                  onClick={props.cancelButtonPath}
                >
                  Regresar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default OpportunityEditForm;
