import React from 'react';
import { connect } from 'react-redux';

import Icon from '@mdi/react';
import { mdiCloseCircle, mdiCheckBold } from '@mdi/js';

import * as httpService from '../../http-service/httpService';
import { isValidPhoneNumber } from '../utils/Validators';

import UserForm from './UserForm';
import Modal from '../utils/Modal';
import Loading from '../utils/Loading';


const UserController = ({ user, handleSubmitSuccess, cancelButtonPath, httpGet, httpPost, urlPost, ...props }) => {

  const [userData, setUserData] = React.useState(user);
  const [isLoading, setIsLoading] = React.useState(false);
  const resetError = (field) => {
    setUserError(prev => ({ ...prev, [field]: '' }));
  }
  React.useEffect(() => {
    setUserData(user);
  }, [user])
  const [userError, setUserError] = React.useState({
    name: '',
    lastname: '',
    email: '',
    cellphone: '',
    state: '',
    groups: '',
    in_charge: '',
  });
  const [dataForm, setDataForm] = React.useState({
    states: {
      data: [],
      loading: true,
    },
    groups: {
      data: [],
      loading: true,
    },
    users: {
      data: [],
      loading: true
    }
  });
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [globalError, setGlobalError] = React.useState([]);

  const handleOnChangeForm = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    setUserData(prev => ({ ...prev, [field]: value }));
    resetError(field);
    if (field === 'cellphone' && !isValidPhoneNumber(value)) {
      setUserError(err => ({ ...err, cellphone: ['ingrese un numero a 10 digitos se permiten espacios y "-"'] }));
    }
  }

  const handleOnChangeExecutive = (option) => {
    var value = ''
    if (option) {
      value = option.value
    }
    setUserData(dat => ({ ...dat, in_charge: value }));
  }
  const validateForm = () => {
    let error_user = {};
    let isValid = true;
    Object.keys(userData).forEach((item) => {
      if (userData[item] === null || userData[item] === undefined || userData[item].length === 0) {
        error_user[item] = ['Este campo es requerido']
        isValid = false
      }
      if (item === 'cellphone' && !isValidPhoneNumber(userData[item])) {
        error_user[item] = ['ingrese un numero a 10 digitos se permiten espacios y "-"'];
        isValid = isValidPhoneNumber(userData[item]);
      }
    });
    setUserError(prev => ({ ...prev, ...error_user }));
    return isValid;
  }
  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      httpPost(urlPost, userData)
        .then((response) => {
          setIsLoading(false);
          setGlobalError([]);
          setModalIsOpen(true);
        })
        .catch(error => {
          setIsLoading(false);
          error.response.data.errors.forEach((item) => {
            if (item.field) {
              setUserError(prev => {
                const newValue = { ...prev };
                newValue[item.field] = item.message;
                return newValue;
              });
            }
            else {
              setGlobalError([item.message]);
            }
          })
        })
        .finally(() => {
        });
    }
  }
  const handleOnModalClose = () => {
    setModalIsOpen(false);
    if (globalError.length === 0) {
      handleSubmitSuccess();
    }
    setGlobalError([]);
  }

  React.useEffect(() => {
    httpGet('/api/utils/list/')
      .then(response => {
        setDataForm(prev => {
          const newValue = { ...prev }
          newValue['states'] = {
            ...newValue['states'], data: response.data.states, loading: false
          }
          newValue['groups'] = {
            ...newValue['groups'], data: response.data.groups, loading: false
          }
          newValue['users'] = {
            ...newValue['users'], data: response.data.users, loading: false
          }
          return newValue;
        });
      })
      .catch(error => {
        setDataForm(prev => {
          const newValue = { ...prev }
          newValue['states'] = {
            ...newValue['states'], loading: false
          }
          return newValue;
        });
      });
  }, [httpGet]);

  const getIndexValue = (value) => {
    for (var i = 0; i < dataForm.users.data.length; i++) {
      if (dataForm.users.data[i]['value'] === value) {
        return i;
      }
    }
    return -1; //to handle the case where the value doesn't exist
  }

  return (
    <>
      {isLoading ?
        <div className="container">
          <div className="columns is-centered">
            <div className="columns is-narrow">
              <Loading className='has-background-dark' />
            </div>
          </div>
        </div>
        :
        <UserForm
          handleOnSubmit={handleOnSubmit}
          handleOnChangeForm={handleOnChangeForm}
          handleOnChangeExecutive={handleOnChangeExecutive}
          user={userData}
          userError={userError}
          dataForm={dataForm}
          cancelButtonPath={cancelButtonPath}
          getIndexValue={getIndexValue}
        />
      }
      <Modal onClose={handleOnModalClose} isOpen={modalIsOpen || globalError.length > 0} >
        <header className="modal-card-head">
          <p className="modal-card-title is-size-4">{globalError.length > 0 ? "Error" : "Usuario Correcto"}</p>
          <button onClick={handleOnModalClose} className="delete jb-modal-close" aria-label="close"></button>
        </header>
        <section className="modal-card-body">
          <div className="columns is-centered">
            <div className="column has-text-centered">
              {(globalError.length > 0 ?
                <span className="has-text-danger">
                  <Icon path={mdiCloseCircle} title={'OK'} size={5} />
                  <p className="is-size-1">{globalError}</p>
                </span>
                : <span className="has-text-success">
                  <Icon path={mdiCheckBold} title={'OK'} size={5} />
                </span>)}
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button jb-modal-close" onClick={handleOnModalClose}>Aceptar</button>
        </footer>
      </Modal>
    </>
  )
};
const { //actions
  httpGet, httpPost
} = httpService;

const mapDispatchToProps = { //actions
  httpGet, httpPost
};

export default connect(null, mapDispatchToProps)(UserController);