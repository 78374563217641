import React from 'react';
import { connect } from 'react-redux';
import * as httpService from '../../http-service/httpService';
import OpportunityEditForm from '../../components/opportunity/OpportunityEditForm';
import Icon from '@mdi/react';
import { mdiCheckBold, mdiCloseCircle } from '@mdi/js';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Modal from '../../components/utils/Modal';
import Loading from '../../components/utils/Loading';
const URL_IMAGE = process.env.REACT_APP_IMAGE_URL


const OpportunityDetail = ({ httpGet, httpPost, match: { params: { opportunityUuid } }, ...props }) => {

  const [permissions, setPermissions] = React.useState(props.user_data.groups);
  const [loading, setIsLoading] = React.useState(0);

  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const [establishmentUuid, setEstablishmentUuid] = React.useState('')

  const [dataForm, setDataForm] = React.useState({
    percents: { data: [], isLoading: false },
    waiterspercents: { data: [], isLoading: false },
    ratings: { data: [], isLoading: false, checked: [], minLen: 2 },
  });

  const [opportunityError, setOpportunityError] = React.useState({
    establishment: '',
    rating: '',
    perfil: '',
  });
  const [globalError, setGlobalError] = React.useState([]);
  const [opportunity, setOpportunity] = React.useState({
    establishment: {
      uuid: '',
      name: '',
      img_logo: '',
    },
    rating: [],
    registered_on: '',
    perfil: 0,
    perfil_meseros: 0,
    activity_count: 0,
    active: true,
    lost_reason: '',
    folio: '',
    admin_estab_id: '',
    fiscal_data: {
      email: '',
    },
    bank_data: null,
    user_group: '',
    redemption: 0,
  });
  const [completed, setCompleted] = React.useState(false)
  const [imageURL, setImageURL] = React.useState(null);

  React.useEffect(() => {
    setIsLoading(prev => prev + 1);
    httpGet(`/api/opportunity/detail/${opportunityUuid}/`)
      .then(response => {
        setCompleted(response.data.stage === 4);
        setOpportunity(response.data);
        let perfil = window.sessionStorage.getItem("perfil");
        let opUuid = window.sessionStorage.getItem("opUuid")
        if (response.data.perfil === 0 && perfil !== null && opUuid === opportunityUuid) {
          setOpportunity(dat => ({ ...dat, perfil: parseInt(perfil) }));
        }
        if (response.data.establishment.img_logo.length > 0) {
          setImageURL(`${URL_IMAGE}/${response.data.establishment.img_logo}`)
        }
        setEstablishmentUuid(response.data.establishment.uuid);
      })
      .catch(error => console.log(error.response))
      .finally(() => {
        setIsLoading(prev => prev - 1);
      });
  }, [opportunityUuid, httpGet])
  React.useEffect(() => {
    if (establishmentUuid) {
      setIsLoading(prev => prev + 1);
      httpGet(`/api/opportunity/rating/list/${establishmentUuid}/`)
        .then(response => {
          setDataForm(dat => {
            const newValue = { ...dat };
            newValue['ratings'] = {
              ...dat['ratings'], isLoading: false, data: response.data.data, minLen: response.data.min_len
            }
            const checked = {};
            response.data.data.forEach(item => {
              checked[item.id] = opportunity.rating.includes(item.id)
            });
            newValue['ratings']['checked'] = checked;
            return newValue;
          });
        })
        .catch(error => console.log(error))
        .finally(() => {
          setIsLoading(prev => prev - 1);
        });
      setIsLoading(prev => prev + 1);
      httpGet(`/api/opportunity/percent/list/`)
        .then(response => {
          setDataForm(dat => {
            const newValue = { ...dat };
            newValue['percents'] = {
              ...dat['percents'], isLoading: false, data: response.data
            }
            return newValue;
          });
        })
        .catch(error => console.log(error))
        .finally(() => {
          setIsLoading(prev => prev - 1);
        });
      setIsLoading(prev => prev + 1);
      httpGet(`/api/opportunity/percentwaiter/list/`)
        .then(response => {
          setDataForm(dat => {
            const newValue = { ...dat };
            newValue['waiterspercents'] = {
              ...dat['waiterspercents'], isLoading: false, data: response.data
            }
            return newValue;
          });
        })
        .catch(error => console.log(error))
        .finally(() => {
          setIsLoading(prev => prev - 1);
        });
    }
  }, [establishmentUuid, httpGet]);
  const resetErrorOpportunity = (field) => {
    setOpportunityError(err => ({ ...err, [field]: '' }));
  }

  const handleOpportunityChange = (e) => {
    const opportunityData = { ...opportunity };
    const fieldName = e.target.name;
    const value = e.target.value;
    opportunityData[fieldName] = value;
    setOpportunity(opportunityData);
  }

  const handlePercentChange = (option) => {
    resetErrorOpportunity('perfil');
    var value = null
    if (option) {
      value = Number(option.value)
    }
    if (value === null && (opportunity.stage === null || opportunity.stage === 4)) {
      setOpportunityError(prev => {
        const newValue = { ...prev };
        newValue['perfil'] = 'No puedes quitar el perfil por el proceso en el que esta tu establecimiento.';
        return newValue;
      });
    }
    else {
      setOpportunity(dat => ({ ...dat, perfil: value }));
      window.sessionStorage.setItem("opUuid", opportunityUuid);
      window.sessionStorage.setItem("perfil", value);
    }
  }

  const handlePercentWaiterChange = (option) => {
    resetErrorOpportunity('perfil_meseros');
    var value = null
    if (option) {
      value = Number(option.value)
    }
    if (value !== null) {
      setOpportunity(dat => ({ ...dat, perfil_meseros: value }));
      window.sessionStorage.setItem("opUuid", opportunityUuid);
      window.sessionStorage.setItem("perfil_meseros", value);
    }
  }

  const handleCheckChange = (e) => {
    resetErrorOpportunity(e.target.name);
    const newValueDataForm = { ...dataForm }
    newValueDataForm['ratings'] = {
      ...newValueDataForm['ratings']
    }
    newValueDataForm['ratings']['checked'] = {
      ...newValueDataForm['ratings']['checked'], [e.target.value]: e.target.checked
    }
    setDataForm(newValueDataForm);
    const newValueOpportunity = { ...opportunity }
    const datas = Object.keys(newValueDataForm['ratings']['checked']).filter(item => newValueDataForm['ratings']['checked'][item]).map(item => item);
    newValueOpportunity['rating'] = datas;
    setOpportunity(newValueOpportunity);
  }
  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (opportunity.active) {
      setIsLoading(prev => prev + 1);
      httpPost(`/api/opportunity/edit/${opportunityUuid}/`, opportunity)
        .then(response => {
          let folio = response.data.folio;
          const newValueOpportunity = { ...opportunity };
          newValueOpportunity['folio'] = folio ? folio : '';
          setOpportunity(newValueOpportunity)
          setModalIsOpen(true);
        })
        .catch(error => {
          console.log(error)
          setOpportunityError({
            establishment: '',
            rating: '',
            stage: '',
            perfil: '',
          });
          error.response.data.errors.forEach(item => {
            if (item.field)
              setOpportunityError(prevErr => ({ ...prevErr, [item.field]: item.message }));
            else {
              setGlobalError([item.message]);
            }
          });
        })
        .finally(() => {
          setIsLoading(prev => prev - 1);
        });
    }
  }
  const handleOnModalClose = () => {
    setModalIsOpen(false);
    setGlobalError([]);
    window.sessionStorage.setItem("opUuid", null);
    window.sessionStorage.setItem("perfil", null);
    props.history.goBack()
  }

  const getIndexValue = (arr, value) => {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i]['value'] === value) {
        return i;
      }
    }
    return -1; //to handle the case where the value doesn't exist
  }

  return (
    <>
      <section className="section is-main-section">
        {loading > 0 ?
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-narrow">
                <Loading />
              </div>
            </div>
          </div>
          : <OpportunityEditForm
            opportunity={opportunity}
            dataForm={dataForm}
            handleCheckChange={handleCheckChange}
            handleOnSubmit={handleOnSubmit}
            opportunityError={opportunityError}
            cancelButtonPath={props.history.goBack}
            handlePercentChange={handlePercentChange}
            handlePercentWaiterChange={handlePercentWaiterChange}
            handleOpportunityChange={handleOpportunityChange}
            completed={completed}
            location={props.location}
            getIndexValue={getIndexValue}
            imageURL={imageURL}
            permissions={permissions}
          />
        }
        <Modal onClose={handleOnModalClose} isOpen={modalIsOpen || globalError.length > 0} >
          <header className="modal-card-head">
            <p className="modal-card-title is-size-4">{globalError.length > 0 ? "Error" : "Proceso Editado"}</p>
            <button onClick={handleOnModalClose} className="delete jb-modal-close" aria-label="close"></button>
          </header>
          <section className="modal-card-body">
            <div className="columns is-centered">
              <div className="column has-text-centered">
                {(globalError.length > 0 ?
                  <span className="has-text-danger">
                    <Icon path={mdiCloseCircle} title={'OK'} size={5} />
                    <p className="is-size-1">{globalError}</p>
                  </span>
                  : <span className="has-text-success">
                    <Icon path={mdiCheckBold} title={'OK'} size={5} />
                  </span>)}
                {opportunity.folio ?
                  <div className="ml-3">
                    <h2 className="has-text-success">FOLIO: {opportunity.folio}</h2>
                    <CopyToClipboard text={opportunity.folio}>
                      <button className="button is-info" type="button" data-tooltip={"Copiar Folio: " + opportunity.folio}>Copiar Folio</button>
                    </CopyToClipboard>
                  </div> : ""
                }
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button className="button jb-modal-close" onClick={handleOnModalClose}>Aceptar</button>
          </footer>
        </Modal>
      </section>
    </>
  )
};

const mapStateToProps = ({ LoginReducer }) => LoginReducer;

const { //actions
  httpGet, httpPost
} = httpService;

const mapDispatchToProps = { //actions
  httpGet, httpPost
};
export default connect(mapStateToProps, mapDispatchToProps)(OpportunityDetail);