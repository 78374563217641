import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import PrivateRoute from "../authentication/PrivateRoute";

import Layout from "./Layout";
import Login from "../pages/Login";
import BankDataTokenCreate from "../pages/establishment/BankDataTokenCreate";
import RegisterAfiliation from "../pages/afiliation/RegisterAfiliation";
import SuccessPublicPage from "../pages/SuccessPublicPage";
import useScript from "./utils/useScript";
import { useEffect } from "react";
import { render } from "@testing-library/react";

function App() {
  useScript("https://cdn.conekta.io/js/latest/conekta.js");
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={Login} exact />
        <Route path="/secure/:token" component={BankDataTokenCreate} exact />
        <Route path="/afiliation/:token" component={RegisterAfiliation} exact />
        <Route path="/success" component={SuccessPublicPage} exact />
        <PrivateRoute path="" component={Layout} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;

