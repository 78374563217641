import React from 'react';
import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import Icon from '@mdi/react';
import { mdiCheckBold, mdiCloseCircle } from '@mdi/js';

import * as httpService from '../../http-service/httpService';
import Geocode from "react-geocode";
import MarkImgForm from './MarkImgForm';

import Loading from '../../components/utils/Loading';
import Modal from '../utils/Modal';
const URL_IMAGE = process.env.REACT_APP_IMAGE_URL

const MarkImgController = ({ urlPost, httpGet, httpPost, establishmentUuid, handleSubmitSuccess, cancelButtonPath, ...props }) => {
    const [loading, setIsLoading] = React.useState(0);
    const [clickedLatLng, setClickedLatLng] = useState(null);
    const [address, setAddress] = useState("José F. Muguerza, Lomas, 64030 Monterrey, N.L.");
    const [logo, setLogo] = useState(null);
    const [file, setFile] = useState(null);
    const [isLoadingFile, setIsLoadingFile] = React.useState(false);

    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [globalError, setGlobalError] = React.useState([]);

    useEffect(() => {
        setIsLoading(prev => prev + 1);
        httpGet(`/api/establishment/detail/markImg/${establishmentUuid}/`)
            .then(response => {
                var data = response.data
                setAddress(data.full_address);
                if (data.latitude !== null && data.longitude !== null) {
                    setClickedLatLng({ lat: data.latitude, lng: data.longitude })
                }
                if (data.img_logo.length > 0) {
                    setLogo(`${URL_IMAGE}/${data.img_logo}`)
                }
            })
            .catch(error => {
                error.response.data.errors.forEach(item => {
                    setGlobalError([item.message]);
                });
            })
            .finally(() => {
                setIsLoading(prev => prev - 1);
            });
    }, [httpGet])

    const handleOnChangeImg = (e) => {
        if (e.target.files.length > 0) {
            setLogo(URL.createObjectURL(e.target.files[0]))
            var img = e.target.files[0]
            setFile(img)
        }
    }

    const handleOnSubmit = (e) => {
        e.preventDefault();
        const fd = new FormData();
        if (file !== null) {
            fd.append('logo', file, file.name)
        }
        fd.append('latitude', clickedLatLng.lat)
        fd.append('longitude', clickedLatLng.lng)
        setModalIsOpen(true);

        httpPost(urlPost, fd, { headers: { 'content-type': 'multipart/form-data' } })
            .then(response => {
                setIsLoadingFile(false);
                setModalIsOpen(true);
            })
            .catch(error => {
                error.response.data.errors.forEach(item => {
                    setGlobalError([item.message]);
                });
            })
            .finally(() => {
            });
    }

    const onClickGenerateMap = (e) => {
        Geocode.setApiKey("AIzaSyDQiqdGICBINHjEj0AKfDxTc8-f7UMrRnE");
        Geocode.fromAddress(address).then(
            response => {
                const { lat, lng } = response.results[0].geometry.location;
                setClickedLatLng({ lat: lat, lng: lng })
            },
            error => {
                console.error(error);
            }
        );
    }

    const onClickMap = (e) => {
        setClickedLatLng(e.latLng.toJSON())
    }

    const handleOnModalClose = () => {
        setModalIsOpen(false);
        if (globalError.length === 0) {
            handleSubmitSuccess();
        }
        setGlobalError([]);
    }

    return (<>
        {loading > 0 ?
            <div className="container">
                <div className="columns is-centered">
                    <div className="column is-narrow">
                        <Loading />
                    </div>
                </div>
            </div>
            :
            <MarkImgForm
                handleOnSubmit={handleOnSubmit}
                handleOnChangeImg={handleOnChangeImg}
                onClickGenerateMap={onClickGenerateMap}
                onClickMap={onClickMap}
                address={address}
                clickedLatLng={clickedLatLng}
                logo={logo}
                cancelButtonPath={cancelButtonPath}
            />
        }
        <Modal onClose={handleOnModalClose} isOpen={modalIsOpen || globalError.length > 0} >
            <header className="modal-card-head">
                <p className="modal-card-title is-size-4">{globalError.length > 0 ? "Error" : "Puntero e Imagen Agregados"}</p>
                <button onClick={handleOnModalClose} className="delete jb-modal-close" aria-label="close"></button>
            </header>
            <section className="modal-card-body">
                <div className="columns is-centered">
                    <div className="column has-text-centered">
                        {(globalError.length > 0 ?
                            <span className="has-text-danger">
                                <Icon path={mdiCloseCircle} title={'OK'} size={5} />
                                <p className="is-size-1">{globalError}</p>
                            </span>
                            : <>{isLoadingFile ? <Loading /> :
                                <span className="has-text-success">
                                    <Icon path={mdiCheckBold} title={'OK'} size={5} />
                                </span>}
                            </>)}
                    </div>
                </div>
            </section>
            <footer className="modal-card-foot">
                <button className="button jb-modal-close" onClick={handleOnModalClose}>Aceptar</button>
            </footer>
        </Modal>
    </>)
}


const { //actions
    httpGet, httpPost
} = httpService;

const mapDispatchToProps = { //actions
    httpGet, httpPost
};
export default connect(null, mapDispatchToProps)(MarkImgController);