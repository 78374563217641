import React from 'react';

const NewOpportunity = (props) => {
  let classSelectEstablishment = 'select';
  classSelectEstablishment += props.opportunityError.establishment ? " is-danger" : "";
  classSelectEstablishment += props.dataForm.establishments.isLoading ? ' is-loading' : '';
  return (
    <>
      <article className="panel is-primary">
        <p className="panel-heading">
          Nuevo Proceso
        </p>
        <form onSubmit={props.handleOnSubmit}>
          <div className="panel-block">
            <div className="field">
              <label className="label">Establecimiento</label>
              <div className="control">
                <div className={classSelectEstablishment}>
                  <select name="establishment" value={props.opportunity.establishment} onChange={props.handleOnChangeOpportunity} id="">
                    <option value="">------------</option>
                    {
                      props.dataForm.establishments.data.map((item,key) => (
                        <option key={key} value={item.uuid} >{item.name}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              {
                props.opportunityError.establishment && <p className="help is-danger">{props.opportunityError.establishment}</p>
              }
            </div>
          </div>
          {props.dataForm.ratings.data.length > 0 &&
            <>
              <div className="panel-block">
                <div className="field has-check is-horizontal">
                  <div className="field-label"><label className="label">Calificacion</label></div>
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped-multiline">
                        {
                          props.dataForm.ratings.data.map((item) => (
                            <div className="control" key={item.id}>
                              <label className="b-checkbox checkbox">
                                <input type="checkbox" value={item.id} name="rating" 
                                  checked={props.dataForm.ratings.checked[item.id]}
                                  onChange={props.handleCheckChange}
                                />
                                <span className="check is-primary"></span>
                                <span className={props.opportunityError.rating ? "control-label has-text-danger" :"control-label"}>{item.name}</span>
                              </label>
                            </div>
                          ))
                        }
                        {
                          props.opportunityError.rating && <p className="help is-danger">{props.opportunityError.rating}</p>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-block">
                <button type="submit" className="button is-primary mr-1">Agregar</button>
                <button type="button" className="button" onClick={props.handleOnClickNewOpportunity}>
                  Cancelar
                </button>
              </div>
            </>
          }
        </form>
      </article>
    </>
  )
};

export default NewOpportunity;