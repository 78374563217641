import React from "react";

export const ModalFooter = ({
  justifyContent = "flex-end",
  children = null,
}) => {
  return (
    <footer className="modal-card-foot" style={{ justifyContent }}>
      {children}
    </footer>
  );
};
