import React from 'react';
import UserController from '../../components/user/UserController';
import Card from '../../components/Card';


const UserCreate = (props) => {
  const urlPost = '/api/user/create/'
  const user = {
    name: '',
    lastname: '',
    email: '',
    cellphone: '',
    state: '',
    groups: '',
    in_charge: '',
  }
  const handleSubmitSuccess = () => {
    props.history.goBack()
  }
  return (
    <>
      <section className="section is-main-section">
        <Card title={'Agregar Usuario'}>
          <UserController
            user={user}
            handleSubmitSuccess={handleSubmitSuccess}
            cancelButtonPath={props.history.goBack}
            urlPost={urlPost}
          />
        </Card>
      </section>
    </>
  )
};

export default UserCreate;