import React, { useState } from 'react';
import { connect } from 'react-redux';

import * as LoginActions from '../actions/LoginActions';
import { Redirect } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiLock } from '@mdi/js';

import Loading from '../components/utils/Loading';


const Login = (props) => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({
    password: '',
    email: ''
  });

  const handleOnChangeEmail = (event) => {
    setEmail(event.target.value);
    props.resetError();
  }

  const handleOnChangePassword = (event) => {
    setPassword(event.target.value);
    props.resetError();
  }

  const handleClick = () => {
    let error = [];
    if (!email.length) {
      let errordet = {
        message: 'Required',
        field: 'email'
      }
      error.push(errordet)
    }
    if (!password.length) {
      let errordet = {
        message: 'Required',
        field: 'password'
      }
      error.push(errordet)
    }
    if (Object.keys(error).length) {
      props.setErrorField({ errors: error });
    }
    else
      props.login({ email, password });
  }
  React.useEffect(() => {
    if (props.errors) {
      let newValue = {
        password: '',
        email: ''
      }
      props.errors.forEach(item => {
        if (item.field)
          newValue[item.field] = item.message
      });
      setError(newValue);
    }
  }, [props.errors])

  React.useEffect(() => {
    document.documentElement.classList.remove("has-aside-left")
    document.documentElement.classList.remove("has-navbar-fixed-top")
    document.documentElement.classList.remove("has-aside-expanded")
  })
  return (
    <>
      {!props.token.access ?
        <section className="section hero is-error-section">
          <div className="hero-body">
            <div className="container">
              <div className="columns is-centered">
                {props.loading ? <Loading /> :
                  <div className="column is-two-fifths">
                    <div className="card has-card-header-background">
                      <header className="card-header">
                        <p className="card-header-title">
                          <span className="icon"><Icon path={mdiLock} size={1} /></span>
                          <span>Login</span>
                        </p>
                      </header>
                      <div className="card-content">
                        {
                          props.errors?.filter(item => item.field === undefined || item.field === null).map(error => (
                            <div className="notification is-danger is-light">
                              {error.message}
                            </div>
                          ))
                        }
                        <form >
                          <div className="field">
                            <label className="label">E-mail Address</label>
                            <div className="control">
                              <input type="email" autoComplete="on" value={email}
                                onChange={handleOnChangeEmail} name="email"
                                className={(props.errors && error.email) ? "input is-danger" : "input"}
                                required autoFocus

                              />
                            </div>
                            {props.errors && error.email &&
                              <p className="help is-danger">{error.email}</p>
                            }
                          </div>
                          <div className="field">
                            <label className="label">Password</label>
                            <div className="control">
                              <input type="password" autoComplete="on" value={password}
                                onChange={handleOnChangePassword} name="password"
                                className={(props.errors && error.password) ? "input is-danger" : "input"}
                                required
                              />
                            </div>
                            {props.errors && error.password &&
                              <p className="help is-danger">{error.password}</p>
                            }
                          </div>
                          <hr />
                          <div className="field is-grouped">
                            <div className="control">
                              <button type="button" className="button is-black" onClick={handleClick}>
                                Login
                              </button>
                            </div>
                            <div className="control">
                              <a href="password-recovery.html" className="button is-outlined is-black">
                                Forgot Password?
                              </a>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>}
              </div>
            </div>
          </div>
          <div className="hero-foot has-text-centered">
            <div className="logo"></div>
          </div>
        </section>
        : <Redirect to="/" />
      }
    </>
  )
};

const mapStateToProps = ({ LoginReducer }) => LoginReducer;

export default connect(mapStateToProps, LoginActions)(Login);