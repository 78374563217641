import React from 'react';
import Modal from '../utils/Modal';
import Icon from '@mdi/react';
import { mdiCheckBold } from '@mdi/js';
import Select from 'react-select';
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";


const RegisterEstablishmentForm = (props) => {


  return (
    <>
      <form onSubmit={props.handleOnSubmit}>
        <div className="columns is-multiline">
          <div className="column is-12-mobile is-10">
            <div className="field">
              <label className="label">Nombre del Establecimiento</label>
              <div className="control">
                <input onChange={props.handleOnChangeEstablishment} value={props.establishment.name} type="text"
                  className={props.establishmentError.name ? "input is-danger" : "input"} name='name' placeholder="El work"
                />
              </div>
              {
                props.establishmentError.name && <p className="help is-danger">{props.establishmentError.name[0]}</p>
              }
            </div>
          </div>
          <div className="column is-12-mobile is-3">
            <div className="field">
              <label className="label">Categoria</label>
              <div className="control">
                <div className={props.establishmentError.category ? "select is-danger" : "select"}>
                  <select name="category" value={props.establishment.category} onChange={props.handleOnChangeEstablishment} >
                    <option value="">------------</option>
                    {
                      props.dataForm.categories.map((item) => (
                        <option key={item.id} value={item.id} >{item.name}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              {
                props.establishmentError.category && <p className="help is-danger">{props.establishmentError.category[0]}</p>
              }
            </div>
          </div>
          {
            (
              props.permissions.includes("Admin") ||
              props.permissions.includes("Ejecutivo") ||
              props.permissions.includes("Corporativo") ||
              props.permissions.includes('Agencia Distribuidor')
            ) && <>
              <div className="column is-12-mobile is-4">
                <div className="field">
                  <label className="label">SubCategoria</label>
                  <div className="control">
                    <Select
                      name="subcategories"
                      value={props.subcategories}
                      onChange={props.handleSubCategoriesChange}
                      isLoading={props.dataForm.subcategories.isLoading}
                      options={props.dataForm.subcategories.data}
                      isMulti
                    ></Select>
                  </div>
                  {
                    props.establishmentError.subcategories && <p className="help is-danger">{props.establishmentError.subcategories[0]}</p>
                  }
                </div>
              </div>
              <div className="column is-12-mobile is-5">
                <div className="field">
                  <label className="label">Tags de búsqueda</label>
                  <div className="control">
                    <ReactTagInput
                      name='tags'
                      tags={props.establishment.tags}
                      onChange={props.handleOnChangeTags}
                      className={props.establishmentError.description ? "input is-danger" : "input"}
                      removeOnBackspace={true}
                    />
                  </div>
                  {
                    props.establishmentError.description && <p className="help is-danger">{props.establishmentError.description[0]}</p>
                  }
                </div>
              </div>
            </>
          }
          <div className="column is-7 is-12-mobile">
            <div className="field">
              <label className="label">Descripción</label>
              <div className="control">
                <input onChange={props.handleOnChangeEstablishment} name='description' value={props.establishment.description}
                  className={props.establishmentError.description ? "input is-danger" : "input"} type="text" placeholder="El restaurante para comer..."
                />
              </div>
              {
                props.establishmentError.description && <p className="help is-danger">{props.establishmentError.description[0]}</p>
              }
            </div>
          </div>
          <div className="column is-5 is-12-mobile">
            <div className="field">
              <label className="label">Teléfono</label>
              <div className="control">
                <input onChange={props.handleOnChangeEstablishment} name='phone' value={props.establishment.phone}
                  className={props.establishmentError.phone ? "input is-danger" : "input"} type="text" placeholder="8115423605"
                />
              </div>
              {
                props.establishmentError.phone && <p className="help is-danger">{props.establishmentError.phone[0]}</p>
              }
            </div>
          </div>
          <div className="column is-12 is-12-mobile">
            <div className="field">
              <label className="label">Calle</label>
              <div className="control">
                <input onChange={props.handleOnChangeEstablishment} name='address' value={props.establishment.address}
                  className={props.establishmentError.address ? "input is-danger" : "input"} type="text" placeholder="av. Jose F. Muguerza"
                />
              </div>
              {
                props.establishmentError.address && <p className="help is-danger">{props.establishmentError.address[0]}</p>
              }
            </div>
          </div>
          <div className="column is-3 is-12-mobile">
            <div className="field">
              <label className="label">No. Exterior</label>
              <div className="control">
                <input onChange={props.handleOnChangeEstablishment} name='address_number1' value={props.establishment.address_number1}
                  className={props.establishmentError.address_number1 ? "input is-danger" : "input"} type="text" placeholder="2212"
                />
              </div>
              {
                props.establishmentError.address_number1 && <p className="help is-danger">{props.establishmentError.address_number1[0]}</p>
              }
            </div>
          </div>
          <div className="column is-3 is-12-mobile">
            <div className="field">
              <label className="label">No. Interior</label>
              <div className="control">
                <input onChange={props.handleOnChangeEstablishment} name='address_number2' value={props.establishment.address_number2}
                  className={props.establishmentError.address_number2 ? "input is-danger" : "input"} type="text" placeholder=""
                />
              </div>
              {
                props.establishmentError.address_number2 && <p className="help is-danger">{props.establishmentError.address_number2[0]}</p>
              }
            </div>
          </div>
          <div className="column is-6 is-12-mobile">
            <div className="field">
              <label className="label">Colonia/Fraccionamiento</label>
              <div className="control">
                <input onChange={props.handleOnChangeEstablishment} name='district' value={props.establishment.district}
                  className={props.establishmentError.district ? "input is-danger" : "input"} type="text" placeholder="Chepevera"
                />
              </div>
              {
                props.establishmentError.district && <p className="help is-danger">{props.establishmentError.district[0]}</p>
              }
            </div>
          </div>
          <div className="column is-5 is-12-mobile">
            <div className="field">
              <label className="label">Municipio/Alcaldia</label>
              <div className="control">
                <input onChange={props.handleOnChangeEstablishment} name='city' value={props.establishment.city}
                  className={props.establishmentError.city ? "input is-danger" : "input"} type="text" placeholder="Monterrey"
                />
              </div>
              {
                props.establishmentError.city && <p className="help is-danger">{props.establishmentError.city[0]}</p>
              }
            </div>
          </div>
          <div className="column is-4 is-12-mobile">
            <div className="field">
              <label className="label">Estado</label>
              <div className="control">
                <div className={props.establishmentError.state ? "select is-danger" : "select"}>
                  <select name="state" value={props.establishment.state} onChange={props.handleOnChangeEstablishment} >
                    <option value="">------------</option>
                    {
                      props.dataForm.states.map((item) => (
                        <option key={item.id} value={item.id} >{item.estado}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              {
                props.establishmentError.state && <p className="help is-danger">{props.establishmentError.state[0]}</p>
              }
            </div>
          </div>
          <div className="column is-3 is-12-mobile">
            <div className="field">
              <label className="label">Código postal</label>
              <div className="control">
                <input onChange={props.handleOnChangeEstablishment} name='zip_code' value={props.establishment.zip_code}
                  className={props.establishmentError.zip_code ? "input is-danger" : "input"} type="text" placeholder="66058"
                  maxLength={5}
                />
              </div>
              {
                props.establishmentError.zip_code && <p className="help is-danger">{props.establishmentError.zip_code[0]}</p>
              }
            </div>
          </div>
          <div className="column is-12">
            <div className="field">
              <label className="label">Horario(s)</label>
              <div className="control">
                <input onChange={props.handleOnChangeEstablishment} name='schedule' value={props.establishment.schedule}
                  className={props.establishmentError.schedule ? "input is-danger" : "input"} type="text"
                  placeholder="Lun - Vie 11:00 a 23:00, Sáb 12:00 a 24:00, Dom 13:00 a 18:00"
                />
              </div>
              {
                props.establishmentError.schedule && <p className="help is-danger">{props.establishmentError.schedule}</p>
              }
              <p>Los días deberán colocarse solo sus primeras 3 letras, de un día a otro deberá estar separado por un guion medio, las horas deberán estar en modelo de 24hrs y entre horas deberá colocarse la letra “a”.  Y en horarios distintos de la semana separarse por comas. Ver ejemplo: Lun - Vie  11:00 a 23:00, Sáb 12:00 a 24:00, Dom 13:00 a 18:00</p>
            </div>
          </div>
          <div className="column is-6 is-12-mobile">
            <div className="field">
              <label className="label">Facebook</label>
              <div className="control">
                <input onChange={props.handleOnChangeEstablishment} name='url_fb' value={props.establishment.url_fb}
                  className={props.establishmentError.url_fb ? "input is-danger" : "input"} type="text"
                  placeholder="http://www.facebook.com/establecimiento"
                />
              </div>
              {
                props.establishmentError.url_fb && <p className="help is-danger">{props.establishmentError.url_fb}</p>
              }
            </div>
          </div>
          <div className="column is-6 is-12-mobile">
            <div className="field">
              <label className="label">Twitter</label>
              <div className="control">
                <input onChange={props.handleOnChangeEstablishment} name='url_tw' value={props.establishment.url_tw}
                  className={props.establishmentError.url_tw ? "input is-danger" : "input"} type="text"
                  placeholder="http://www.twitter.com/establecimiento"
                />
              </div>
              {
                props.establishmentError.url_tw && <p className="help is-danger">{props.establishmentError.url_tw}</p>
              }
            </div>
          </div>
          <div className="column is-6 is-12-mobile">
            <div className="field">
              <label className="label">Instagram</label>
              <div className="control">
                <input onChange={props.handleOnChangeEstablishment} name='url_ins' value={props.establishment.url_ins}
                  className={props.establishmentError.url_ins ? "input is-danger" : "input"} type="text"
                  placeholder="http://www.instagram.com/establecimiento"
                />
              </div>
              {
                props.establishmentError.url_ins && <p className="help is-danger">{props.establishmentError.url_ins}</p>
              }
            </div>
          </div>
          <div className="column is-6 is-12-mobile">
            <div className="field">
              <label className="label">Web</label>
              <div className="control">
                <input onChange={props.handleOnChangeEstablishment} name='url_web' value={props.establishment.url_web}
                  className={props.establishmentError.url_web ? "input is-danger" : "input"} type="text"
                  placeholder="http://www.establecimiento.com/"
                />
              </div>
              {
                props.establishmentError.url_web && <p className="help is-danger">{props.establishmentError.url_web}</p>
              }
            </div>
          </div>
        </div>
        <hr />
        <div className="columns is-multiline">
          <div className="column is-12">
            <div className="field">
              <label className="label">Nombre del Contacto</label>
              <div className="control">
                <input onChange={props.handleOnChangeContact} name='name' value={props.contact.name}
                  className={props.contactError.name ? "input is-danger" : "input"} type="text" placeholder="Juan Perez"
                />
              </div>
              {
                props.contactError.name && <p className="help is-danger">{props.contactError.name[0]}</p>
              }
            </div>
          </div>
          <div className="column is-6 is-12-mobile">
            <div className="field">
              <label className="label">email</label>
              <div className="control">
                <input onChange={props.handleOnChangeContact} value={props.contact.email} name='email'
                  className={props.contactError.email ? "input is-danger" : "input"} type="email" placeholder="contacto@correo.com.mx"
                />
              </div>
              {
                props.contactError.email && <p className="help is-danger">{props.contactError.email[0]}</p>
              }
            </div>
          </div>
          <div className="column is-6 is-12-mobile">
            <div className="field">
              <label className="label">Celular</label>
              <div className="control">
                <input onChange={props.handleOnChangeContact} value={props.contact.cellphone} name='cellphone'
                  className={props.contactError.cellphone ? "input is-danger" : "input"} type="text" placeholder="8125362405"
                />
              </div>
              {
                props.contactError.cellphone && <p className="help is-danger">{props.contactError.cellphone[0]}</p>
              }
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label className="label">Puesto</label>
              <div className="control">
                <div className={props.contactError.position ? "select is-danger" : "select"}>
                  <select name="position" value={props.contact.position} onChange={props.handleOnChangeContact} >
                    <option value="">------------</option>
                    {
                      props.dataForm.positions.map((item) => (
                        <option key={item.id} value={item.id} >{item.title}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              {
                props.contactError.position && <p className="help is-danger">{props.contactError.position[0]}</p>
              }
            </div>
          </div>
        </div>
        <hr />
        <div className="field is-grouped is-grouped-right">
          <p className="control">
            <button type="submit" className="button is-primary">Aceptar</button>
          </p>
          <p className="control">
            <button onClick={props.cancelButtonPath} type="button" className="button is-light">Cancel</button>
          </p>
        </div>
      </form>
      <Modal onClose={props.handleOnModalClose} isOpen={props.modalIsOpen} >
        <header className="modal-card-head">
          <p className="modal-card-title is-size-4">Establecimiento agregado</p>
          <button onClick={props.handleOnModalClose} className="delete jb-modal-close" aria-label="close"></button>
        </header>
        <section className="modal-card-body">
          <div className="columns is-centered">
            <div className="column is-narrow">
              <span className="has-text-success">
                <Icon path={mdiCheckBold} title={'OK'} size={5} />
              </span>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button jb-modal-close" onClick={props.handleOnModalClose}>Aceptar</button>
        </footer>
      </Modal>
    </>
  )
};

export default RegisterEstablishmentForm;