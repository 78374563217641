import React from "react";
import { connect } from "react-redux";

import Icon from "@mdi/react";
import { mdiCloseCircle, mdiCheckBold } from "@mdi/js";

import * as httpService from "../../http-service/httpService";

import FiscalDataForm from "../fiscalData/FiscalDataForm";
import Modal from "../utils/Modal";
import Loading from "../utils/Loading";
import { fiscalDataInitErrors } from "./initValues";
import { useFiscalDataForm } from "./useFiscalDataForm";

const FiscalDataController = ({
  fiscalDat,
  handleSubmitSuccess,
  cancelButtonPath,
  showNotFactura = true,
  httpGet,
  httpPost,
  urlPost,
  ...props
}) => {
  const {
    validateForm,
    handleOnChangeFiscalData,
    fiscalData,
    fiscalDataError,
    setFiscalDataError,
  } = useFiscalDataForm({
    initialValues: fiscalDat,
    initialErrors: fiscalDataInitErrors,
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [dataForm, setDataForm] = React.useState({
    states: {
      data: [],
      loading: true,
    },
  });
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [globalError, setGlobalError] = React.useState([]);

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (validateForm() && urlPost.length > 0) {
      setIsLoading(true);
      const formData = new FormData();
      for (let key in fiscalData) {
        formData.append(key, fiscalData[key]);
      }
      httpPost(urlPost, formData)
        .then((response) => {
          setIsLoading(false);
          setGlobalError([]);
          setModalIsOpen(true);
        })
        .catch((error) => {
          setIsLoading(false);
          error.response.data.errors.forEach((item) => {
            if (item.field) {
              setFiscalDataError((prev) => {
                const newValue = { ...prev };
                newValue[item.field] = item.message;
                return newValue;
              });
            } else {
              setGlobalError([item.message]);
            }
          });
        })
        .finally(() => {});
    } else if (validateForm()) {
      handleSubmitSuccess(fiscalData);
    }
  };

  const handleOnModalClose = () => {
    setModalIsOpen(false);
    if (globalError.length === 0) {
      handleSubmitSuccess();
    }
    setGlobalError([]);
  };

  React.useEffect(() => {
    httpGet("/api/establishment/fiscaldata/utils/")
      .then((response) => {
        setDataForm((prev) => {
          const newValue = { ...prev };
          newValue["states"] = {
            ...newValue["states"],
            data: response.data.states,
            loading: false,
          };
          return newValue;
        });
      })
      .catch((error) => {
        setDataForm((prev) => {
          const newValue = { ...prev };
          newValue["states"] = {
            ...newValue["states"],
            loading: false,
          };
          return newValue;
        });
      });
  }, [httpGet]);

  return (
    <>
      {isLoading ? (
        <div className="container">
          <div className="columns is-centered">
            <div className="columns is-narrow">
              <Loading className="has-background-dark" />
            </div>
          </div>
        </div>
      ) : (
        <FiscalDataForm
          handleOnSubmit={handleOnSubmit}
          handleOnChangeForm={handleOnChangeFiscalData}
          fiscalData={fiscalData}
          fiscalDataError={fiscalDataError}
          dataForm={dataForm}
          cancelButtonPath={cancelButtonPath}
          showNotFactura={showNotFactura}
        />
      )}
      <Modal
        onClose={handleOnModalClose}
        isOpen={modalIsOpen || globalError.length > 0}
      >
        <header className="modal-card-head">
          <p className="modal-card-title is-size-4">
            {globalError.length > 0 ? "Error" : "Datos Fiscales Agregados"}
          </p>
          <button
            onClick={handleOnModalClose}
            className="delete jb-modal-close"
            aria-label="close"
          ></button>
        </header>
        <section className="modal-card-body">
          <div className="columns is-centered">
            <div className="column has-text-centered">
              {globalError.length > 0 ? (
                <span className="has-text-danger">
                  <Icon path={mdiCloseCircle} title={"OK"} size={5} />
                  <p className="is-size-1">{globalError}</p>
                </span>
              ) : (
                <span className="has-text-success">
                  <Icon path={mdiCheckBold} title={"OK"} size={5} />
                </span>
              )}
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button
            className="button jb-modal-close"
            onClick={handleOnModalClose}
          >
            Aceptar
          </button>
        </footer>
      </Modal>
    </>
  );
};
const {
  //actions
  httpGet,
  httpPost,
} = httpService;

const mapDispatchToProps = {
  //actions
  httpGet,
  httpPost,
};

export default connect(null, mapDispatchToProps)(FiscalDataController);
