import React from 'react'
import { useAsyncDebounce } from 'react-table'

const ColumnFilterDefault = ({ column: { filterValue, setFilter, filter } }) => {
  const onChange = useAsyncDebounce(value => {
    setFilter(value || undefined)
  }, 500)
  const [value, setValue] = React.useState(filterValue)
  return (
    <input className="input"
      value={value || ""}
      onChange={e => {
        onChange(e.target.value); // Set undefined to remove the filter entirely
        setValue(e.target.value);
      }}
      placeholder={`Buscar...`}
    />
  );
};

export default ColumnFilterDefault;