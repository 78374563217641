import React from 'react';
import Modal from '../utils/Modal';
import Icon from '@mdi/react';
import {
  mdiPhoneClassic,
  mdiEmail,
} from "@mdi/js";


const bankDataDetailV = (props) => {
  console.log("Hola");
  console.log(props.bankData);
  return (
    <>
      <form>
        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <div className="card tile is-child">
              <header className={"card-header"}>
                <div className={"card-header-title"}>
                  Datos Bancarios
              </div>
              </header>
              <div className="card-content">
                <div className="columns is-multiline">
                  <div className="column is-6 is-12-movile">
                    <div className="field">
                      <label className="label">Informacion Genereal</label>
                      <div className="content">
                        <p>Establecimiento: {props.bankData.estab_name}</p>
                        <p>Contacto: {props.bankData.contact.name}</p>
                        <p>Puesto: {props.bankData.contact.position}</p>
                        <p>Telefono: {props.bankData.contact.cellphone}</p>
                        <p>
                          <span className="icon is-small">
                            <Icon path={mdiEmail} size={'1rem'} />
                          </span>
                            Email: {props.bankData.contact.email}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="column is-6 is-12-movile">
                    <div className="field">
                      <label className="label">Informacion de la Cuenta</label>
                      <div className="content">
                        <p>Banco: {props.bankData.bank_name}</p>
                        <p>Cuenta: {props.bankData.account}</p>
                        <p>Clabe: {props.bankData.clabe}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="field is-grouped is-grouped-right">
                  <button onClick={props.cancelButtonPath} type="button" className="button is-light">Regresar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
};

export default bankDataDetailV;