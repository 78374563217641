import {fiscalDocument} from "../utils/ValidationConstants";
import { ValidationException } from "../utils/ValidationException";
import { isValidEmail, isValidFilename, isValidFileSize, isValidIdCif, isValidRFC } from "../utils/Validators";

export const useFiscalDataValidators = () => {
  const validateIdCif = (value) => {
    if (isValidIdCif(value)) return true;
    throw new ValidationException(
      "idCIF",
      "El idCIF no parece ser valido verifique que este correcto"
    );
  };

  const validateRfc = (value) => {
    if (isValidRFC(value)) {
      return true;
    }
    throw new ValidationException(
      "rfc",
      "El rfc no parece ser valido verifique que este correcto"
    );
  };

  const validateEmail = (value) => {
    if (isValidEmail(value)) return true;
    throw new ValidationException(
      "email",
      "El email no parece ser válido verifique que esté correcto"
    );
  };

  const validateFiscalDocument = (file) => {
    if (!file) {
      throw new ValidationException(
        "fiscal_document",
        "Este campo es requerido"
      );
    }
    if (!isValidFilename(file.name, fiscalDocument.extensions)) {
      throw new ValidationException(
        "fiscal_document",
        `El tipo de archivo seleccionado es inválido, tipos permitidos: ${fiscalDocument.extensions.join(
          ", "
        )}`
      );
    }
    if (!isValidFileSize(file.size, fiscalDocument.maxSize)) {
      throw new ValidationException(
        "fiscal_document",
        `El archivo seleccionado es muy grande, máximo: ${fiscalDocument.maxSizeLabel}`
      );
    }
    return true;
  };


  return { validateEmail, validateRfc, validateIdCif, validateFiscalDocument };
};
