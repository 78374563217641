import axios from 'axios';
import { LOGOUT } from '../types/LoginTypes';
const URL_SERVER = process.env.REACT_APP_SERVER_URL;

export const httpGet = (url, config = {}) => (dispatch, getState) => {
  const { token } = getState().LoginReducer;
  const axios_config = {
    ...config, headers: {
      authorization: 'Bearer ' + token.access
    }
  }
  return axios.get(URL_SERVER + url, axios_config)
    .then(response => new Promise((resolve, reject) => resolve(response)))
    .catch((error) => {
      console.log(error.response);
      if (error.response.status !== 404) {
        dispatch({ type: LOGOUT });
      }
      return new Promise((resolve, reject) => reject(error));
    });
}

export const httpPost = (url, data , config = {}) => (dispatch, getState) => {
  const { token } = getState().LoginReducer;
  const axios_config = {
    ...config, headers: {
      authorization: 'Bearer ' + token.access
    }
  }
  return axios.post(URL_SERVER + url, data, axios_config)
    .then(response => new Promise((resolve, reject) => resolve(response)))
    .catch((error) => {
      console.log(error.response);
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch({ type: LOGOUT });
      }
      return new Promise((resolve, reject) => reject(error));
    });
}
