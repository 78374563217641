import React from 'react'
import { useTable, usePagination, useGlobalFilter, useFilters } from 'react-table'
import { connect } from 'react-redux';

import * as httpService from '../../http-service/httpService';
import * as moment from 'moment';

import Icon from '@mdi/react';
import { mdiLoading } from '@mdi/js';
import ColumnFilterDefault from './ColumnFilter'
import GlobalFilter from './GlobalFilter';
import Nothing from '../utils/Nothing';

// Let's add a fetchData method to our Table component that will be used to fetch
// new data when pagination state changes
// We can also add a loading state to let our table know it's loading new data

function PaginatedTable({
  columns,
  url,
  data,
  setData,
  httpGet,
  url_excel = null,
  excelFileName = "sinNombre",
  dateRangeActive = true,
}) {
  const [loading, setLoading] = React.useState(false);
  const [controlledPageCount, setControlledPageCount] = React.useState(0);
  const [resultCount, setResultCount] = React.useState(0);

  const defaultColumn = {
    // Let's set up our default Filter UI
    Filter: ColumnFilterDefault
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize, globalFilter, filters },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, globalFilter: '' }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
      manualGlobalFilter: true,
      manualFilters: true,
      defaultColumn,
    },
    useGlobalFilter,
    useFilters,
    usePagination,
  )

  const [dateRange, setDateRange] = React.useState({ startDate: moment().startOf('month'), endDate: moment() });
  const [focus, setFocus] = React.useState(null);

  React.useEffect(() => {
    // fetchData({ pageIndex, pageSize, globalFilter, dateRange, filters })
    console.log(filters);
    setLoading(true)
    if (globalFilter !== undefined && globalFilter.length > 0) {
      url = `${url}?q=${globalFilter}`
    }
    else {
      // We'll even set a delay to simulate a server here
      url = `${url}?page=${pageIndex + 1}&page_size=${pageSize}`
      if (dateRangeActive) {
        url = `${url}&start_date=${moment(dateRange.startDate).toISOString()}&end_date=${moment(dateRange.endDate).toISOString()}`
      }
      if (filters !== undefined && filters.length > 0) {
        url += '&fts=1'
        filters.forEach(item => {
          url += `&fts_${item.id}=${item.value}`
        });
      }
    }
    if (moment.isMoment(dateRange.endDate)) {
      httpGet(url)
        .then(response => {
          console.log(response);
          setData(response.data.results);
          // Your server could send back total page count.
          // For now we'll just fake it, too
          setControlledPageCount(Math.ceil(response.data.count / pageSize))
          setResultCount(response.data.count);
          setLoading(false);
        });
    }
  }, [pageIndex, pageSize, globalFilter, dateRange, filters])

  const handleOnClickExcel = () => {
    if (dateRangeActive) {
      url_excel = `${url_excel}&start_date=${moment(dateRange.startDate).toISOString()}&end_date=${moment(dateRange.endDate).toISOString()}`;
    }
    httpGet(url_excel, { responseType: 'blob' })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `work_${excelFileName}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };
  // Render the UI for your table
  return (
    <>
      <div className="card has-table has-table-container-upper-radius">
        <div className="columns is-multiline mx-3 my-3">
          {url_excel &&
            <div className="column is-7">
              <button
                className="button is-success jb-modal"
                data-target="sample-modal"
                type="button"
                onClick={(e) => handleOnClickExcel()}
              >
                Export to excel
              </button>
            </div>
          }
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            setDateRange={setDateRange}
            dateRange={dateRange}
            focus={focus}
            setFocus={setFocus}
            dateRangeActive={dateRangeActive}
          />
        </div>
        <hr className="my-0" />
        <div>
          <div className="card-content">
            <div className="table-container">
              <table {...getTableProps()}
                className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
                <thead>
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()}>
                          {column.render('Header')}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : ''}
                          </span>
                          <div>{column.canFilter ? column.render("Filter") : null}</div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                {data.length === 0 ?
                  <tbody>
                    <tr>
                      <td colSpan={columns.length}>
                        <Nothing />
                      </td>
                    </tr>
                  </tbody> :
                  <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                      prepareRow(row)
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map(cell => {
                            return <td {...cell.getCellProps({ className: cell.column.classNameCell })}>{cell.render('Cell')}</td>
                          })}
                        </tr>
                      )
                    })}
                    <tr>
                      {loading ? (
                        // Use our custom loading state to show a loading indicator
                        <td colSpan="1000" className="has-text-centered">
                          <span className="icon is-large">
                            <Icon path={mdiLoading} spin size={'1rem'} />
                          </span>
                        </td>
                      ) : (
                          <td colSpan="10000">
                            Showing {page.length} of {resultCount}{' '}results
                          </td>
                        )}
                    </tr>
                  </tbody>
                }
              </table>
            </div>
          </div>
          {/*
              Pagination can be built however you'd like.
              This is just a very basic UI implementation:
            */}
          <div className="notification">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <div className="buttons has-addons">
                    <button className="button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                      {'<<'}
                    </button>
                    <button className="button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                      {'<'}
                    </button>
                    <button className="button" onClick={() => nextPage()} disabled={!canNextPage}>
                      {'>'}
                    </button>
                    <button className="button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                      {'>>'}
                    </button>
                  </div>
                </div>
                <div className="level-item">
                  <span>
                    Page
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                  </span>
                </div>
                <div className="level-item">
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Go to page:</label>
                    </div>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input className="input"
                          type="number"
                          defaultValue={pageIndex + 1}
                          onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                          }}

                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="level-right">
                <div className="level-item">
                  <div className="filed">
                    <div className="control">
                      <div className="select">
                        <select
                          value={pageSize}
                          onChange={e => {
                            setPageSize(Number(e.target.value))
                          }}
                        >
                          {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                              Show {pageSize}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

const {
  //actions
  httpGet
} = httpService;

const mapDispatchToProps = {
  //actions
  httpGet
};

export default connect(null, mapDispatchToProps)(PaginatedTable);