import React, { useState } from "react";
import { ViewInputButton } from "../ViewInputButton";

export const FormInput = ({
  is = 5,
  isMobile = 12,
  isOffset = "",
  label = "",
  onChange = () => {},
  value = "",
  inputClassName = "",
  type = "text",
  placeholder = "",
  name = "",
  error = "",
  showToggleView = false,
  initToggleView = false,
  autoComplete = "",
  minLength = '',
  maxLength = ''
}) => {
  const [view, setView] = useState(initToggleView);
  const onClickToggleView = () => setView(!view);

  const getType = () => {
    if (showToggleView && !view) return "password";
    return type;
  };

  return (
    <div
      className={`column is-${is} is-${isMobile}-mobile is-offset-${isOffset}`}
    >
      <div className="field">
        <label className="label">{label}</label>
        <div className="control">
          <input
            onChange={onChange}
            name={name}
            value={value}
            className={`form-input ${inputClassName} ${
              error ? "input is-danger" : "input"
            }`}
            type={getType()}
            placeholder={placeholder}
            autoComplete={autoComplete}
            minLength={minLength}
            maxLength={maxLength}
          />
          {showToggleView && (
            <ViewInputButton view={view} onClick={onClickToggleView} />
          )}
        </div>
        {error && <p className="help is-danger">{error}</p>}
      </div>
    </div>
  );
};
