import React from "react";
import { connect } from "react-redux";

import * as httpService from "../../http-service/httpService";
import { fiscalDataInitValues } from "../../components/fiscalData/initValues";
import Card from "../../components/Card";
import FiscalDataController from "../../components/fiscalData/FiscalDataController";

const FiscalDataEdit = ({
  httpGet,
  match: {
    params: { opportunityUuid },
  },
  ...props
}) => {
  const urlPost = `/api/opportunity/fiscalData/edit/${opportunityUuid}/`;
  const [fiscalData, setFiscalData] = React.useState(fiscalDataInitValues);
  const handleSubmitSuccess = () => {
    props.history.goBack();
  };
  React.useEffect(() => {
    httpGet(`/api/opportunity/fiscalData/detail/${opportunityUuid}/`)
      .then((response) => {
        console.log(response.data);
        const fiscalData = response.data;
        setFiscalData((prev) => ({
          ...prev,
          rfc: fiscalData.rfc,
          not_factura: fiscalData.not_factura,
          email: fiscalData.email
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [httpGet]);
  return (
    <>
      <section className="section is-main-section">
        <Card title={"Editar Datos Fiscales"}>
          <FiscalDataController
            fiscalDat={fiscalData}
            handleSubmitSuccess={handleSubmitSuccess}
            cancelButtonPath={props.history.goBack}
            urlPost={urlPost}
          />
        </Card>
      </section>
    </>
  );
};
const {
  //actions
  httpGet,
} = httpService;

const mapDispatchToProps = {
  //actions
  httpGet,
};

export default connect(null, mapDispatchToProps)(FiscalDataEdit);
