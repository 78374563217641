
import React from 'react';

import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  VerticalBarSeriesCanvas,
  HorizontalBarSeries,
  DiscreteColorLegend,
  Hint,
  ChartLabel,
  FlexibleXYPlot
} from 'react-vis';
import 'react-vis/dist/style.css';

const OpportunityDashboardChart = (props) => {
  const { responseData } = props;
  const data = responseData.data;
  const plotOptions = { margin: responseData.config.margin }
  const [valueHint, setValueHint] = React.useState(null);
  const _rememberValue = (value, event) => {
    setValueHint(value);
  }
  const BarSeries = responseData.config.orientation === 1 ? HorizontalBarSeries : VerticalBarSeries;
  const _forgetValue = (datapoint, event) => {
    setValueHint(null);
  }
  return (
    <>
      <DiscreteColorLegend className="has-text-centered"
        orientation={"horizontal"}
        items={data.map((item, key) => ({ title: item.name, strokeWidth: 10, color: item.color }))}
      />
      <div className="chart">
        <FlexibleXYPlot yType="ordinal" {...plotOptions} >
          <VerticalGridLines tickTotal={4} />
          <XAxis />
          <YAxis />
          {
            data.map((item, key) =>
              <BarSeries barWidth={0.9}
                key={key} data={item.data}
                onValueMouseOver={_rememberValue}
                onValueMouseOut={_forgetValue}
                color={item.color}
              />
            )
          }
          {valueHint ? <Hint value={valueHint} >
            <div className="rv-hint__content" style={{ 'backgroundColor': '#302e3a' }}>
              <div>
                <span className="rv-hint__title">{valueHint.y}</span>
              </div>
              <div>
                <span className="rv-hint__title">{valueHint.x}</span>
              </div>
            </div>
          </Hint> : null}
        </FlexibleXYPlot>
      </div>
    </>
  )
};

export default OpportunityDashboardChart;