import React, { useMemo } from 'react';
import { connect } from "react-redux";

import * as httpService from '../../http-service/httpService';

import PaginatedTable from '../../components/tables/PaginatedTable';

import Loading from '../../components/utils/Loading';

const ListEstablishments = ({ httpPost, ...props }) => {
    const [data, setData] = React.useState([]);

    const columns = useMemo(
        () => [
            {
                Header: "Estatus",
                accessor: "status",
                disableFilters: true,
            },
            {
                Header: "Fecha Cargo",
                accessor: "charge_date",
                disableFilters: true,
            },
            {
                Header: "Fecha Inicio",
                accessor: "start_date",
                disableFilters: true,
            },
            {
                Header: "Usuario",
                accessor: "customer_name",
                disableFilters: true,
            },
            {
                Header: "Establecimiento",
                accessor: "establishment_name",
                disableFilters: true,
            },
            {
                Header: "Sucursales",
                accessor: "branchs",
                disableFilters: true,
            },
            {
                Header: "Plan",
                accessor: "plan",
                disableFilters: true,
            },
            {
                Header: "Total",
                accessor: "total",
                disableFilters: true,
            },
            {
                Header: "Forma de Pago",
                accessor: "payment_type",
                disableFilters: true,
            },
        ],
        []
    );

    return (
        <>
            <section className="section is-main-section">
                <PaginatedTable
                    columns={columns}
                    url={`/api/afiliation/list/`}
                    data={data}
                    setData={setData}
                    exportToExcel={true}
                    url_excel={`/api/afiliation/excel/?format=xlsx`}
                    excelFileName={'Afiliaciones'}
                />
            </section>
        </>
    )
};

const mapStateToProps = ({ LoginReducer }) => LoginReducer;

const { //actions
    httpPost
} = httpService;

const mapDispatchToProps = { //actions
    httpPost
};
export default connect(mapStateToProps, mapDispatchToProps)(ListEstablishments);