import React from "react";
import Icon from "@mdi/react";
import { mdiEye, mdiEyeOff } from "@mdi/js";

export const ViewInputButton = ({
  view = false,
  onClick = (e) => console.log({ e }),
}) => {
  return (
    <button type="button" className="view-input-btn" onClick={onClick}>
      {view ? (
        <Icon path={mdiEyeOff} title="Ocultar" size={1} />
      ) : (
        <Icon path={mdiEye} title="Ver" size={1} />
      )}
    </button>
  );
};
