import React from "react";

export const ModalHeader = ({ onClose, children, isSize = 4 }) => {
  return (
    <header className="modal-card-head">
      <p className={`modal-card-title is-size-${isSize}`}>{children}</p>
      <button
        onClick={onClose}
        className="delete jb-modal-close"
        aria-label="close"
      ></button>
    </header>
  );
};
