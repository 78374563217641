import React from 'react';
import { connect } from 'react-redux';
import { useState, useEffect } from 'react';

import * as httpService from '../../http-service/httpService';
import { isValidPhoneNumber, isValidShcedule, isValidZipCode } from '../../components/utils/Validators';

import Icon from '@mdi/react';
import { mdiCheckBold } from '@mdi/js';
import Modal from '../../components/utils/Modal';


import Card from '../../components/Card';
import EstablishmentEditForm from '../../components/forms/EstablishmentEditForm';
import Loading from '../../components/utils/Loading';



const EstablishmentEdit = ({ httpGet, httpPost, match: { params: { uuid } }, ...props }) => {
  // const uuid = props.match.params.uuid;
  const [permissions, setPermissions] = React.useState(props.user_data.groups);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [subcategories, setSubCategories] = useState([]);
  const [establishment, setEstablishment] = useState({
    name: '',
    category: '',
    subcategories: [],
    tags: [],
    description: '',
    phone: '',
    address: '',
    address_number1: '',
    address_number2: '',
    district: '',
    city: '',
    state: '',
    zip_code: '',
    schedule: '',
    url_fb: '',
    url_tw: '',
    url_ins: '',
    url_web: '',
  });
  const [dataForm, setDataForm] = useState({
    categories: [],
    subcategories: { data: [], isLoading: false },
    states: [],
    positions: [],
  });

  const [establishmentError, setEstablishmentError] = useState({
    name: '',
    category: '',
    subcategories: '',
    tags: '',
    description: '',
    phone: '',
    address: '',
    address_number1: '',
    address_number2: '',
    district: '',
    city: '',
    state: '',
    zip_code: '',
    schedule: '',
    url_fb: '',
    url_tw: '',
    url_ins: '',
    url_web: '',
  });

  const [loading, setLoading] = useState(0);

  const resetErrorEstablishment = (field) => {
    setEstablishmentError(err => ({ ...err, [field]: '' }));
  }

  const handleOnChangeEstablishment = (e) => {
    const establecimiento = { ...establishment };
    establecimiento[e.target.name] = e.target.value;
    resetErrorEstablishment(e.target.name);
    setEstablishment(establecimiento);
    if (e.target.name === 'phone' && !isValidPhoneNumber(e.target.value)) {
      setEstablishmentError(err => ({ ...err, phone: ['ingrese un numero a 10 digitos se permiten espacios y "-"'] }))
    }
    if (e.target.name === 'schedule' && !isValidShcedule(e.target.value)) {
      setEstablishmentError(err => ({ ...err, schedule: ['Recuerda que puedes registrar hasta 3 horarios, respetando alguno de los formatos del ejemplo:Lun - Vie 11:00 a 23:00, Sáb 12:00 a 24:00, Dom 13:00 a 18:00'] }));
    }
    if (e.target.name === 'zip_code' && !isValidZipCode(e.target.value)) {
      setEstablishmentError(err => ({ ...err, zip_code: ['El codigo postal debe ser de 5 digitos'] }));
    }
    if (e.target.name === 'category' && e.target.value.length > 0) {
      getSubCategories(e.target.value)
    }
  }

  const getSubCategories = (category, loaded = []) => {
    setLoading(prev => prev + 1);
    setEstablishment(dat => ({ ...dat, subcategories: [] }));
    if (category !== null && category !== undefined && category.length > 0) {
      httpGet(`/api/establishment/subcategory/list/${category}/`)
        .then(response => {
          setDataForm(dat => {
            const newValue = { ...dat };
            newValue['subcategories'] = {
              ...dat['subcategories'], isLoading: false, data: response.data
            }
            return newValue;
          });
          var selected = []
          response.data.forEach((item) => {
            if (loaded.indexOf(item.value) > -1)
              selected.push(item);
          });
          handleSubCategoriesChange(selected);
        })
        .catch(error => {
        })
        .finally(() => {
          setLoading(prev => prev - 1);
        });
    } else {
      setLoading(prev => prev - 1);
    }
  }

  const handleSubCategoriesChange = (option) => {
    resetErrorEstablishment('subcategories');
    var rawValues = [];
    option.forEach((item) => {
      rawValues.push(item.value);
    });
    setSubCategories(option);
    setEstablishment(dat => ({ ...dat, subcategories: rawValues }));
  }

  const handleOnChangeTags = (tags) => {
    if (!(tags.slice(0, -1).indexOf(tags[tags.length - 1]) > -1))
      setEstablishment(dat => ({ ...dat, tags: tags }));
  }

  useEffect(() => {
    setLoading(prev => prev + 1);
    httpGet('/api/establishment/utils/')
      .then(response => {
        setDataForm(dat => {
          const newValue = { ...dat };
          newValue['categories'] = response.data.categories
          newValue['states'] = response.data.states
          newValue['positions'] = response.data.positions
          return newValue
        })
        setLoading(prev => prev - 1);
      })
      .catch(error => {
        setLoading(prev => prev - 1);
      });
    setLoading(prev => prev + 1);
    httpGet(`/api/establishment/detail/${uuid}/`)
      .then(response => {
        const data = response.data
        Object.keys(data).forEach((item) => {
          if (data[item] === null) {
            data[item] = ''
          }
        });
        setLoading(prev => prev - 1);
        getSubCategories(`${response.data['category']}`, response.data['subcategories']);
        setEstablishment(response.data);
      })
      .catch(error => {
        setLoading(prev => prev - 1);
      });
  }, [httpGet, uuid]);

  const validateForm = () => {
    let valid_est = true;
    let error_est = {};
    Object.keys(establishment).forEach((item) => {
      if (
        establishment[item].length === 0 &&
        item !== 'schedule' &&
        item !== 'description' &&
        item !== 'address_number2' &&
        item !== 'phone' &&
        item !== 'url_fb' &&
        item !== 'url_tw' &&
        item !== 'url_ins' &&
        item !== 'url_web' &&
        item !== 'subcategories' &&
        item !== 'tags'
      ) {
        error_est[item] = 'Este campo es requerido';
        valid_est = false
      }
      if (item === 'phone' && !isValidPhoneNumber(establishment[item])) {
        error_est[item] = 'ingrese un numero a 10 digitos se permiten espacios y "-"';
        valid_est = false;
      }
      if (establishment[item].length > 0 && item === 'schedule' && !isValidShcedule(establishment[item])) {
        error_est[item] = 'Recuerda que el horario debe respetar alguno de los formatos del ejemplo: Lun - Vie 11:00 a 23:00, Sáb 12:00 a 24:00, Dom 13:00 a 18:00';
        valid_est = false;
      }
      if (establishment[item].length > 0 && item === 'zip_code' && !isValidZipCode(establishment[item])) {
        error_est[item] = 'El codigo postal debe ser de 5 digitos';
        valid_est = false;
      }
    });
    if (!valid_est) {
      setEstablishmentError(err => ({ ...err, ...error_est }));
    }
    return valid_est;
  }

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let is_valid = validateForm();
    if (is_valid) {
      setLoading(true);
      httpPost(`/api/establishment/edit/${uuid}/`, establishment)
        .then(response => {
          setModalIsOpen(true);
        })
        .catch(error => {
          setEstablishmentError(err => {
            const newValue = {
              name: '',
              category: '',
              description: '',
              phone: '',
              address: '',
              address_number1: '',
              address_number2: '',
              district: '',
              city: '',
              state: '',
              zip_code: '',
            }
            error.response.data.errors.forEach(item => {
              if (item.field)
                newValue[item.field] = item.message
              return []
            });
            return newValue;
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  const handleOnModalClose = () => {
    setModalIsOpen(false);
    props.history.goBack();
  }

  return (
    <>
      <section className="section is-main-section">
        {
          (loading > 0) ?
            <div className="container">
              <div className="columns is-centered">
                <div className="columns is-narrow">
                  <Loading />
                </div>
              </div>
            </div> :
            <Card title={"Editar Establecimiento"}>
              <EstablishmentEditForm
                handleOnSubmit={handleOnSubmit}
                handleOnChangeEstablishment={handleOnChangeEstablishment}
                establishment={establishment}
                establishmentError={establishmentError}
                dataForm={dataForm}
                cancelButtonPath={props.history.goBack}
                handleSubCategoriesChange={handleSubCategoriesChange}
                subcategories={subcategories}
                handleOnChangeTags={handleOnChangeTags}
                permissions={permissions}
              />
              <Modal onClose={handleOnModalClose} isOpen={modalIsOpen} >
                <header className="modal-card-head">
                  <p className="modal-card-title is-size-4">Establecimiento Editado</p>
                  <button onClick={handleOnModalClose} className="delete jb-modal-close" aria-label="close"></button>
                </header>
                <section className="modal-card-body">
                  <div className="columns is-centered">
                    <div className="column is-narrow">
                      <span className="has-text-success">
                        <Icon path={mdiCheckBold} title={'OK'} size={5} />
                      </span>
                    </div>
                  </div>
                </section>
                <footer className="modal-card-foot">
                  <button className="button jb-modal-close" onClick={handleOnModalClose}>Aceptar</button>
                </footer>
              </Modal>
            </Card>
        }
      </section>
    </>
  )
};

const mapStateToProps = ({ LoginReducer }) => LoginReducer;

const { //actions
  httpGet, httpPost
} = httpService;

const mapDispatchToProps = { //actions
  httpGet, httpPost
};

export default connect(mapStateToProps, mapDispatchToProps)(EstablishmentEdit);