import React from 'react';
import Card from '../../components/Card';
import MarkImgController from '../../components/MarkImg/MarkImgController';


const MarkImgAdd = ({ match: { params: { establishmentUuid } }, ...props }) => {
    const urlPost = `/api/establishment/add/markImg/${establishmentUuid}/`;

    const handleSubmitSuccess = () => {
        props.history.goBack();
    }

    return (
        <section className="section is-main-section">
            <Card title={'Editar Puntero e Imagen'}>
                <MarkImgController
                    handleSubmitSuccess={handleSubmitSuccess}
                    cancelButtonPath={props.history.goBack}
                    establishmentUuid={establishmentUuid}
                    urlPost={urlPost}
                />
            </Card>
        </section>
    )
}

export default MarkImgAdd;