import React from 'react';
import Icon from '@mdi/react';

const Card = (props) => {
  return(
    <div className="card">
      <header className="card-header">
        <p className="card-header-title">
          {props.icon && <span className="icon"><Icon path={props.icon} size={"1em"} title={props.title} /></span>}
          {props.title}
        </p>
      </header>
      <div className="card-content">
        {props.children}
      </div>
    </div>
  );
};

export default Card;