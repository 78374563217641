import React from "react";
import Icon from "@mdi/react";
import { mdiCheckOutline } from "@mdi/js";
import shopnetLogo from "../styles/static/shopnetLogo.png";

const SuccessPublicPage = (props) => {
  return (
    <section className="container my-5">
      <div className="columns is-centered">
        <img src={shopnetLogo} alt="" height="100%" width="485px" />
      </div>
      <div className="columns is-mobile is-centered my-5">
        <div className="column is-narrow ">
          <h1 className="title">
            <span className="has-text-success">
              <Icon size={5} path={mdiCheckOutline} title={"Success"} />
              <i></i>
            </span>
          </h1>
        </div>
      </div>
      <div className="columns is-mobile is-centered">
        <div className="column is-narrow is-centered">
          <h1 className="success-title-h1">Operación exitosa, ya puedes cerrar esta pestaña</h1>
        </div>
      </div>
    </section>
  );
};

export default SuccessPublicPage;

