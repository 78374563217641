import React from 'react';
import { connect } from 'react-redux';
import { useState, useEffect } from 'react';

import * as httpService from '../../http-service/httpService';
import { isValidPhoneNumber } from '../../components/utils/Validators';

import Icon from '@mdi/react';
import { mdiCheckBold } from '@mdi/js';
import Modal from '../../components/utils/Modal';


import Card from '../../components/Card';
import ContactEditForm from '../../components/forms/ContactEditForm';
import Loading from '../../components/utils/Loading';


const ContactCreate = ({ httpGet, httpPost, match: { params: { uuid } }, ...props }) => {
  // const uuid = props.match.params.uuid;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [dataForm, setDataForm] = useState({
    positions: [],
  });

  const [contact, setContact] = useState({
    name: '',
    email: '',
    position: '',
    cellphone: '',
  });

  const [contactError, setContactError] = useState({
    name: '',
    email: '',
    position: '',
    cellphone: '',
  });

  const [loading, setLoading] = useState(0);

  const resetErrorContact = (field) => {
    setContactError(err => ({ ...err, [field]: '' }));
  }

  const handleOnChangeContact = (e) => {
    const contacto = { ...contact };
    contacto[e.target.name] = e.target.value;
    resetErrorContact(e.target.name);
    setContact(contacto);
    if (e.target.name === 'cellphone' && !isValidPhoneNumber(e.target.value)) {
      setContactError(err => ({ ...err, cellphone: ['ingrese un numero a 10 digitos se permiten espacios y "-"'] }))
    }
  }

  useEffect(() => {
    setLoading(prev => prev + 1);
    httpGet('/api/establishment/contact/utils/')
      .then(response => {
        setDataForm({ positions: response.data.positions });
        setLoading(prev => prev - 1);
      })
      .catch(error => {
        setLoading(prev => prev - 1);
      });
    setLoading(prev => prev + 1);
    httpGet(`/api/establishment/contact/detail/${uuid}/`)
      .then(response => {
        setContact(response.data);
        setLoading(prev => prev - 1);
      })
      .catch(error => {
        setLoading(prev => prev - 1);
      });
  }, [httpGet, uuid]);

  const validateForm = () => {
    let valid_cont = true;
    let error_cont = {};
    Object.keys(contact).forEach((item) => {
      if (contact[item].length === 0) {
        error_cont[item] = ['Este campo es requerido']
        valid_cont = false
      }
      if (item === 'cellphone' && !isValidPhoneNumber(contact[item])) {
        error_cont[item] = 'ingrese un numero a 10 digitos se permiten espacios y "-"';
        valid_cont = false;
      }
    });
    if (!valid_cont) {
      setContactError(err => ({ ...err, ...error_cont }));
    }

    return valid_cont;
  }

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let is_valid = validateForm();
    if (is_valid) {
      setLoading(true);
      httpPost(`/api/establishment/contact/edit/${uuid}/`, contact)
        .then(response => {
          setModalIsOpen(true);
        })
        .catch(error => {
          setContactError(err => {
            const newValue = {
              name: '',
              email: '',
              position: '',
              cellphone: '',
            }
            error.response.data.errors.forEach(item => {
              if (item.field)
                newValue[item.field] = item.message
              return []
            });
            return newValue;
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  const handleOnModalClose = () => {
    setModalIsOpen(false);
    props.history.goBack();
  }

  return (
    <>
      <section className="section is-main-section">
        {
          (loading > 0) ?
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-narrow">
                  <Loading />
                </div>
              </div>
            </div> :
            <Card title={"Agregar Contacto"}>
              <ContactEditForm
                handleOnSubmit={handleOnSubmit}
                handleOnChangeContact={handleOnChangeContact}
                contact={contact}
                contactError={contactError}
                dataForm={dataForm}
                cancelButtonPath={props.history.goBack}
              />
              <Modal onClose={handleOnModalClose} isOpen={modalIsOpen} >
                <header className="modal-card-head">
                  <p className="modal-card-title is-size-4">Contacto Editado</p>
                  <button onClick={handleOnModalClose} className="delete jb-modal-close" aria-label="close"></button>
                </header>
                <section className="modal-card-body">
                  <div className="columns is-centered">
                    <div className="column is-narrow">
                      <span className="has-text-success">
                        <Icon path={mdiCheckBold} title={'OK'} size={5} />
                      </span>
                    </div>
                  </div>
                </section>
                <footer className="modal-card-foot">
                  <button className="button jb-modal-close" onClick={handleOnModalClose}>Aceptar</button>
                </footer>
              </Modal>
            </Card>
        }
      </section>
    </>
  )
};
const { //actions
  httpGet, httpPost
} = httpService;

const mapDispatchToProps = { //actions
  httpGet, httpPost
};

export default connect(null, mapDispatchToProps)(ContactCreate);