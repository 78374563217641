import React from 'react';
import Nothing from '../components/utils/Nothing';


const Base = (props) => {
  console.log(props);
  return(
  <>
    <section className="section is-main-section">
      <Nothing />
    </section>
  </>
)};

export default Base;