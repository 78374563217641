import { fiscalDocument } from "./ValidationConstants";

export const isValidPhoneNumber = (phoneNumber) => {
  return phoneNumber.search(/^(\d\s?-?){10}$/g) !== -1;
};

export const isValidRFC = (rfc) => {
  return (
    rfc.search(
      /^([A-ZÑ&]{3,4})?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01]))?([A-Z\d]{2})([A\d])$/g
    ) !== -1
  );
};

export const isValidShcedule = (shcedule) => {
  return (
    shcedule.search(
      /^((Lun|Mar|Mi(e|é)|Jue|Vie|S(á|a)b|Dom{1})(\s(-)\s(Lun|Mar|Mi(e|é)|Jue|Vie|S(á|a)b|Dom{1}))?\s([0-1]?[0-9]|2[0-3]):[0-5][0-9]\s(a)\s([0-1]?[0-9]|2[0-4]):[0-5][0-9](,\s)?)*$/g
    ) !== -1
  );
};

export const isValidZipCode = (zipCode) => {
  return zipCode.search(/^([\d]{5})$/) !== -1;
};

export const isValidAccount = (account) => {
  return account.search(/^([\d]{10,12})$/) !== -1;
};

export const isValidClabe = (clabe) => {
  return clabe.search(/^([\d]{18})$/) !== -1;
};

export const isValidMonth = (month) => {
  return month.search(/^(0[1-9]|1[0-2])$/)!==-1;
}

export const isValidYear = (year) => {
  return year.search(/^([\d][\d])$/) !== -1;
};

export const isValidCard = (clabe) => {
  return clabe.search(/^([\d]{16})$/) !== -1;
};

export const isValidFilename = (filename = "", extensions = []) => {
  const filenameParts = filename.split(".");
  const ext = filenameParts[filenameParts.length - 1];
  return extensions.includes(ext);
};

export const isValidFileSize = (size = 0, maxSize = fiscalDocument.maxSize) => {
  return size <= maxSize;
};

export const isValidIdCif = (idCif = "") => {
  return /^[\d]{11}$/.test(idCif.trim());
};

export const isValidEmail = (email = "") => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email.trim()
  );
};
