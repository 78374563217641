import React from 'react';

const Footer = () => {
  let dateYear = new Date().getFullYear();
  
  return(
    <footer className="footer">

    </footer>
  );
}

export default Footer;