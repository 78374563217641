import React from "react";
import Modal from "../utils/Modal";
import Icon from "@mdi/react";
import {
  mdiCheckBold,
  mdiAlertCircleOutline,
  mdiTrumpet,
  mdiThermometerAlert,
  mdiEye,
  mdiEyeOff,
} from "@mdi/js";
import Select from "react-select";
import { SingleDatePicker } from "react-dates";
import * as moment from "moment";
import FiscalDataForm from "../fiscalData/FiscalDataForm";
import { FormInputFile } from "../FormInputFile";
import { FormInput } from "../FormInput";
import IdCIFExampleImg from "../../styles/static/id_cif_example.png";

const AfiliationForm = (props) => {
  return (
    <>
      <form onSubmit={props.handleOnSubmit}>
        <input type="password" name="hidden" style={{ display: "none" }} />
        <div className="columns is-multiline">
          <div className="column is-12-mobile is-6">
            <div className="field">
              <label className="label">Nombre del Negocio </label>
              <div className="control">
                <input
                  onChange={props.handleOnChangeEstablishment}
                  value={props.establishmentData.name}
                  type="text"
                  className={
                    props.establishmentDataError.name
                      ? "input is-danger"
                      : "input"
                  }
                  name="name"
                  placeholder="El work"
                />
              </div>
              {props.establishmentDataError.name && (
                <p className="help is-danger">
                  {props.establishmentDataError.name[0]}
                </p>
              )}
            </div>
          </div>
          <div className="column is-2">
            <div className="field">
              <label className="label">Sucursales</label>
              <div className="control">
                <input
                  onChange={props.handleOnChangeEstablishment}
                  name="branchs"
                  value={props.establishmentData.branchs}
                  className={
                    props.establishmentDataError.branchs
                      ? "input is-danger"
                      : "input"
                  }
                  type="number"
                />
              </div>
              {props.establishmentDataError.branchs && (
                <p className="help is-danger">
                  {props.establishmentDataError.branchs[0]}
                </p>
              )}
            </div>
          </div>
          <div className="column is-4">
            <div className="field">
              <label className="label">Estado</label>
              <div className="control">
                <Select
                  className="basic-single is-danger"
                  classNamePrefix="select"
                  name="state"
                  value={
                    props.dataForm.states.data[
                      props.getIndexValue(
                        props.dataForm.states.data,
                        props.customerData.state
                      )
                    ]
                  }
                  defaultValue={
                    props.dataForm.states.data[
                      props.getIndexValue(
                        props.dataForm.states.data,
                        props.customerData.state
                      )
                    ]
                  }
                  onChange={props.handleStateChange}
                  isSearchable={false}
                  isClearable={false}
                  isDisabled={true}
                  isLoading={props.dataForm.states.isLoading}
                  options={props.dataForm.states.data}
                  placeholder={"...Seleccione un Estado"}
                  styles={{
                    control: (base) => ({
                      ...base,
                      ...props.controlStylesHasError(
                        props.customerDataError.state.length > 0
                      ),
                    }),
                  }}
                ></Select>
              </div>
              {props.customerDataError.state && (
                <p className="help is-danger">
                  {props.customerDataError.state[0]}
                </p>
              )}
            </div>
          </div>
          <div className="column is-6">
            <div className="field">
              <label className="label">Esquema de Pago</label>
              <div className="control">
                <Select
                  className="basic-single is-danger"
                  classNamePrefix="select"
                  name="plan_id"
                  value={
                    props.dataForm.plans.data[
                      props.getIndexValue(
                        props.dataForm.plans.data,
                        props.customerData.plan_id
                      )
                    ]
                  }
                  defaultValue={
                    props.dataForm.plans.data[
                      props.getIndexValue(
                        props.dataForm.plans.data,
                        props.customerData.plan_id
                      )
                    ]
                  }
                  onChange={props.handlePlanChange}
                  isSearchable={false}
                  isClearable={false}
                  isDisabled={true}
                  isLoading={props.dataForm.plans.isLoading}
                  options={props.dataForm.plans.data}
                  placeholder={
                    "...Seleccione un Esquema /Costo /Reembolso Cliente /Redencion puntos"
                  }
                  styles={{
                    control: (base) => ({
                      ...base,
                      ...props.controlStylesHasError(
                        props.customerDataError.plan_id.length > 0
                      ),
                    }),
                  }}
                ></Select>
              </div>

              <p className="help">
                Esquema /Costo /Reembolso Cliente /Redencion puntos
              </p>
              {props.total.length > 0 && (
                <p className="">
                  <b>{props.total}</b>
                </p>
              )}
              {props.customerDataError.plan_id && (
                <p className="help is-danger">
                  {props.customerDataError.plan_id[0]}
                </p>
              )}
            </div>
          </div>
          <div className="column is-12-mobile is-2 ">
            <div className="field">
              <label className="label">Fecha de inicio </label>
              <div className="control">
                <SingleDatePicker
                  date={moment(props.customerData.billing_cycle_start)} // momentPropTypes.momentObj or null
                  onDateChange={(date) => props.handleOnBillingCycle(date)} // PropTypes.func.isRequired
                  focused={props.focus} // PropTypes.bool
                  onFocusChange={({ focused }) => props.setFocus(focused)} // PropTypes.func.isRequired
                  id={`billing_cycle_start`} // PropTypes.string.isRequired,
                  openDirection={"up"}
                  anchorDirection={"right"}
                  displayFormat={moment.localeData().postformat("yyyy/MM/DD")}
                  isOutsideRange={(date) =>
                    date.isBefore(moment()) ||
                    date.isAfter(moment().add(30, "day"))
                  }
                  numberOfMonths={2}
                />
              </div>
              {props.customerDataError.billing_cycle_start && (
                <p className="help is-danger">
                  {props.customerDataError.billing_cycle_start[0]}
                </p>
              )}
            </div>
          </div>
          <div className="column is-2">
            <label className="label">Quiere Recibo?</label>
            <div className="control" style={{ paddingTop: "10px" }}>
              <label className="b-radio radio">
                <input
                  value={true}
                  type="radio"
                  name="receipt"
                  checked={props.customerData.receipt}
                  onChange={props.handleOnChangeCustomerData}
                />
                <span className="check is-primary"></span>
                <span className={"control-label"}>Sí</span>
              </label>
              <label className="b-radio radio">
                <input
                  value={false}
                  type="radio"
                  name="receipt"
                  checked={!props.customerData.receipt}
                  onChange={props.handleOnChangeCustomerData}
                />
                <span className="check is-primary"></span>
                <span className={"control-label"}>No</span>
              </label>
            </div>
          </div>
          <div className="column is-2">
            <div className="field">
              <label className="label">Quiere Factura?</label>
              <div className="control" style={{ paddingTop: "10px" }}>
                <label className="b-radio radio">
                  <input
                    value={true}
                    type="radio"
                    name="invoice"
                    checked={props.customerData.invoice}
                    onChange={props.handleOnChangeCustomerData}
                  />
                  <span className="check is-primary"></span>
                  <span className={"control-label"}>Sí</span>
                </label>
                <label className="b-radio radio">
                  <input
                    value={false}
                    type="radio"
                    name="invoice"
                    checked={!props.customerData.invoice}
                    onChange={props.handleOnChangeCustomerData}
                  />
                  <span className="check is-primary"></span>
                  <span className={"control-label"}>No</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="columns is-multiline">
          <div className="column is-4 is-12-mobile">
            <div className="field">
              <label className="label">Nombre del Tarjetahabiente</label>
              <div className="control">
                <input
                  onChange={props.handleOnChangeCustomerData}
                  name="name"
                  value={props.customerData.name}
                  className={
                    props.customerDataError.name ? "input is-danger" : "input"
                  }
                  type="text"
                  placeholder="Juan Perez"
                />
              </div>
              {props.customerDataError.name && (
                <p className="help is-danger">
                  {props.customerDataError.name[0]}
                </p>
              )}
            </div>
          </div>
          <div className="column is-4 is-12-mobile">
            <div className="field">
              <label className="label">Email</label>
              <div className="control">
                <input
                  onChange={props.handleOnChangeCustomerData}
                  name="email"
                  value={props.customerData.email}
                  className={
                    props.customerDataError.email ? "input is-danger" : "input"
                  }
                  type="text"
                  placeholder="contacto@correo.com.mx"
                />
              </div>
              {props.customerDataError.email && (
                <p className="help is-danger">
                  {props.customerDataError.email[0]}
                </p>
              )}
            </div>
          </div>
          <div className="column is-4 is-12-mobile">
            <div className="field">
              <label className="label">Phone</label>
              <div className="control">
                <input
                  onChange={props.handleOnChangeCustomerData}
                  name="phone"
                  value={props.customerData.phone}
                  className={
                    props.customerDataError.phone ? "input is-danger" : "input"
                  }
                  type="text"
                  placeholder="8114256239"
                />
              </div>
              {props.customerDataError.phone && (
                <p className="help is-danger">
                  {props.customerDataError.phone[0]}
                </p>
              )}
            </div>
          </div>
          <div className="column is-4 is-12-mobile">
            <div className="field">
              <label className="label">Numero de Tarjeta</label>
              <div className="control">
                <input
                  onChange={props.handleOnChangePaymentInfo}
                  name="account"
                  value={props.paymentInfo.account}
                  className={
                    props.paymentInfoError.account ? "input is-danger" : "input"
                  }
                  type="text"
                  placeholder="5454545454545454"
                  autoComplete="off"
                />
              </div>
              {props.cardBrand.length > 0 && (
                <p className="help">{props.cardBrand}</p>
              )}
              {props.paymentInfoError.account && (
                <p className="help is-danger">
                  {props.paymentInfoError.account}
                </p>
              )}
            </div>
          </div>
          <div className="column is-4">
            <div className="field">
              <label className="label">Fecha de Expiración</label>
              <div
                className="columns is-multiline"
                style={{ justifyContent: "space-between" }}
              >
                <div className="column is-5">
                  <div className="control">
                    <input
                      onChange={props.handleOnChangePaymentInfo}
                      name="month"
                      value={props.paymentInfo.month}
                      className={
                        props.paymentInfoError.month
                          ? "input is-danger"
                          : "input"
                      }
                      type="text"
                      maxLength="2"
                      placeholder="MM"
                      autoComplete="off"
                    />
                  </div>
                  {props.paymentInfoError.month && (
                    <p className="help is-danger">
                      {props.paymentInfoError.month[0]}
                    </p>
                  )}
                </div>
                <div className="column is-1">
                  <label style={{ fontSize: "1.7rem" }}>/</label>
                </div>
                <div className="column is-6">
                  <div className="control">
                    <input
                      onChange={props.handleOnChangePaymentInfo}
                      name="year"
                      value={props.paymentInfo.year}
                      className={
                        props.paymentInfoError.year
                          ? "input is-danger"
                          : "input"
                      }
                      type="text"
                      maxLength="2"
                      placeholder="YY"
                      autoComplete="off"
                    />
                  </div>
                  {props.paymentInfoError.year && (
                    <p className="help is-danger">
                      {props.paymentInfoError.year[0]}
                    </p>
                  )}
                </div>
              </div>
              {props.paymentInfoError.monthYear && (
                <p className="help is-danger">
                  {props.paymentInfoError.monthYear[0]}
                </p>
              )}
            </div>
          </div>
          <FormInput
            is={3}
            label="Código de Seguridad (CVC)"
            type="text"
            name="cvc"
            value={props.paymentInfo.cvc}
            onChange={props.handleOnChangePaymentInfo}
            inputClassName={
              props.paymentInfoError.cvc ? "input is-danger" : "input"
            }
            error={
              props.paymentInfoError.cvc ? props.paymentInfoError.cvc[0] : ""
            }
            showToggleView
            autoComplete="off"
            minLength="3"
            maxLength="4"
          />
          {/*
 <div className="column is-3">
            <div className="field">
              <label className="label"></label>
              <div className="control">
                <input
                  onChange={props.handleOnChangePaymentInfo}
                  name="cvc"
                  value={props.paymentInfo.cvc}
                  className={
                    props.paymentInfoError.cvc ? "input is-danger" : "input"
                  }
                  type="password"
                  placeholder="123"
                  autoComplete="new-password"
                />
              </div>
              {props.paymentInfoError.cvc && (
                <p className="help is-danger">
                  {props.paymentInfoError.cvc[0]}
                </p>
              )}
            </div>
          </div>


          */}

          <FormInput
            is={5}
            isMobile={12}
            label="RFC"
            name="rfc"
            value={props.fiscalData.rfc.toUpperCase()}
            onChange={props.handleOnChangeFiscalData}
            type="text"
            placeholder="EWK2008158D7"
            error={props.fiscalDataError.rfc}
          />

          <FormInput
            is={5}
            isMobile={12}
            isOffset={1}
            label="idCIF"
            name="idCIF"
            value={props.fiscalData.idCIF.toUpperCase()}
            onChange={props.handleOnChangeFiscalData}
            type="text"
            placeholder="16070424307"
            error={props.fiscalDataError.idCIF}
          />

          <FormInput
            is={5}
            isMobile={12}
            label="Email"
            placeholder="facturacion@correo.com"
            name="email"
            value={props.fiscalData.email}
            onChange={props.handleOnChangeFiscalData}
            type="text"
            error={props.fiscalDataError.email}
          />

          <div className="column is-6 is-12-mobile has-text-centered">
            <div className="field">
              <div className="control">
                <label className="label">
                  <input
                    onChange={props.handleOnChangeFiscalData}
                    name="not_factura"
                    checked={props.fiscalData.not_factura}
                    className={
                      props.fiscalDataError.not_factura ? "is-danger" : ""
                    }
                    type="checkbox"
                  />{" "}
                  No requiere Factura
                </label>
              </div>
              {props.fiscalDataError.not_factura && (
                <p className="help is-danger">
                  {props.fiscalDataError.not_factura}
                </p>
              )}
            </div>
          </div>
        </div>

        <hr />
        <div className="columns is-multiline">
          <div className="column is-12 has-text-centered">
            <div className="field">
              <div className="control">
                <label className="label">
                  <input
                    onChange={props.handleOnChangeCustomerData}
                    name="terms"
                    defaultChecked={props.customerData.terms}
                    className={props.customerDataError.terms ? "is-danger" : ""}
                    type="checkbox"
                  />{" "}
                  Acepto{" "}
                  <a
                    target="_blanck"
                    href="https://shopnet.com.mx/comercios-terminos-condiciones"
                  >
                    Terminos y Condiciones
                  </a>
                </label>
              </div>
              {props.customerDataError.terms && (
                <p className="help is-danger">
                  {props.customerDataError.terms}
                </p>
              )}
            </div>
          </div>
          <div className="column has-text-centered">
            <p>
              <span className="has-text-danger">**</span>
              <b>
                Al dar click en "Aceptar", se genera un cargo prueba de $5
                pesos, este monto se retornara a su tarjeta por parte de nuestro
                Procesador de Pago en los próximos días.
              </b>
            </p>
          </div>
        </div>
        <hr />
        <div className="field is-grouped is-grouped-right">
          <p className="control">
            <button type="submit" className="button is-primary">
              Aceptar
            </button>
          </p>
          <p className="control">
            <button
              onClick={props.cancelButtonPath}
              type="button"
              className="button is-light"
            >
              Cancel
            </button>
          </p>
        </div>
      </form>
    </>
  );
};

export default AfiliationForm;
