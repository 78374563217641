import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import * as LoginActions from '../actions/LoginActions';

const PrivateRoute = ({ component: Component, ...rest }) => {

  let can_view = false;
  let currentDate = Date.now().valueOf() / 1000;
  if (rest.user_data !== null && rest.user_data !== undefined) {
    can_view = currentDate < rest.user_data.exp;
  }
  if (!can_view) {
    rest.logoutUser();
  }
  return (
    <Route
      {...rest}
      render={props => {
        return (can_view ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
              from={props.location.pathname}
            />
          ))
      }
      }
    />
  )
}

const mapStateToProps = ({ LoginReducer }) => LoginReducer;

export default connect(mapStateToProps, LoginActions)(PrivateRoute);