import React from 'react';
import Modal from '../utils/Modal';
import Icon from '@mdi/react';
import { mdiCheckBold, mdiAlertCircleOutline } from '@mdi/js';


const RegisterEstablishmentBankForm = (props) => {


  return (
    <>
      <form onSubmit={props.handleOnSubmit}>
        <div className="columns is-multiline">
          <div className="column is-12-mobile is-8">
            <div className="field">
              <label className="label">Nombre del Establecimiento </label>
              <div className="control">
                <input onChange={props.handleOnChangeEstablishment} value={props.establishment.name} type="text"
                  className={props.establishmentError.name ? "input is-danger" : "input"} name='name' placeholder="El work"
                />
              </div>
              {
                props.establishmentError.name && <p className="help is-danger">{props.establishmentError.name[0]}</p>
              }
            </div>
          </div>
        </div>
        <hr />
        <div className="columns is-multiline">
          <div className="column is-12-mobile is-12">
            <div className="field">
              <label className="label">Banco</label>
              <div className="control">
                <div className={props.bankDataError.bank_id ? "select is-danger" : "select"}>
                  <select name="bank_id" value={props.bankData.bank_id} onChange={props.handleOnChangeBankData} >
                    <option value="">------------</option>
                    {
                      props.dataForm.banks.map((item) => (
                        <option key={item.id} value={item.id} >{item.name}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              {
                props.bankDataError.bank_id && <p className="help is-danger">{props.bankDataError.bank_id[0]}</p>
              }
            </div>
          </div>
          <div className="column is-6 is-12-mobile">
            <div className="field">
              <label className="label">Cuenta</label>
              <div className="control">
                <input onChange={props.handleOnChangeBankData} name='account' value={props.bankData.account}
                  className={props.bankDataError.account ? "input is-danger" : "input"} type="text"
                  placeholder="812563496"
                />
              </div>
              {
                props.bankDataError.account && <p className="help is-danger">{props.bankDataError.account}</p>
              }
            </div>
          </div>
          <div className="column is-6 is-12-mobile">
            <div className="field">
              <label className="label">Clabe Interbancaria</label>
              <div className="control">
                <input onChange={props.handleOnChangeBankData} name='clabe' value={props.bankData.clabe}
                  className={props.bankDataError.clabe ? "input is-danger" : "input"} type="text"
                  placeholder="84252526528624"
                />
              </div>
              {
                props.bankDataError.clabe && <p className="help is-danger">{props.bankDataError.clabe}</p>
              }
            </div>
          </div>
        </div>
        <hr />
        <div className="columns is-multiline">
          <div className="column is-12">
            <div className="field">
              <label className="label">Nombre del Contacto</label>
              <div className="control">
                <input onChange={props.handleOnChangeContact} name='name' value={props.contact.name}
                  className={props.contactError.name ? "input is-danger" : "input"} type="text" placeholder="Juan Perez"
                />
              </div>
              {
                props.contactError.name && <p className="help is-danger">{props.contactError.name[0]}</p>
              }
            </div>
          </div>
          <div className="column is-6 is-12-mobile">
            <div className="field">
              <label className="label">email</label>
              <div className="control">
                <input onChange={props.handleOnChangeContact} value={props.contact.email} name='email'
                  className={props.contactError.email ? "input is-danger" : "input"} type="email" placeholder="contacto@correo.com.mx"
                />
              </div>
              {
                props.contactError.email && <p className="help is-danger">{props.contactError.email[0]}</p>
              }
            </div>
          </div>
          <div className="column is-6 is-12-mobile">
            <div className="field">
              <label className="label">Celular</label>
              <div className="control">
                <input onChange={props.handleOnChangeContact} value={props.contact.cellphone} name='cellphone'
                  className={props.contactError.cellphone ? "input is-danger" : "input"} type="text" placeholder="8125362405"
                />
              </div>
              {
                props.contactError.cellphone && <p className="help is-danger">{props.contactError.cellphone[0]}</p>
              }
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label className="label">Puesto</label>
              <div className="control">
                <div className={props.contactError.position ? "select is-danger" : "select"}>
                  <select name="position" value={props.contact.position} onChange={props.handleOnChangeContact} >
                    <option value="">------------</option>
                    {
                      props.dataForm.positions.map((item) => (
                        <option key={item.id} value={item.id} >{item.title}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              {
                props.contactError.position && <p className="help is-danger">{props.contactError.position[0]}</p>
              }
            </div>
          </div>
        </div>
        <hr />
        <div className="field is-grouped is-grouped-right">
          <p className="control">
            <button type="submit" className="button is-primary">Aceptar</button>
          </p>
          <p className="control">
            <button onClick={props.cancelButtonPath} type="button" className="button is-light">Cancel</button>
          </p>
        </div>
      </form>
      <Modal onClose={props.handleOnModalClose} isOpen={props.modalIsOpen} >
        <header className="modal-card-head">
          <p className="modal-card-title is-size-4">Establecimiento agregado</p>
          <button onClick={props.handleOnModalClose} className="delete jb-modal-close" aria-label="close"></button>
        </header>
        <section className="modal-card-body">
          <div className="columns is-centered">
            <div className="column is-narrow">
              {props.confirm ?
                <div className="has-text-centered">
                  <span className="has-text-warning">
                    <Icon path={mdiAlertCircleOutline} title={'OK'} size={5} />
                  </span>
                  <p className="is-size-5">{"Seguro que tus datos bancarios son correctos?"}
                    <ul>
                      <li> Banco: {props.bankData.bank_name} </li>
                      <li> Cuenta: {props.bankData.account} </li>
                      <li> Clabe Interbancaria: {props.bankData.clabe} </li>
                    </ul>
                  </p>
                </div>
                : <span className="has-text-success">
                  <Icon path={mdiCheckBold} title={'OK'} size={5} />
                </span>}
            </div>
          </div>
        </section>
        <footer className="modal-card-foot" style={{ 'justifyContent': 'flex-end' }}>
          {props.confirm ? <>
            <button className="button jb-modal-close is-success" onClick={props.handleOnConfirmed}>Aceptar</button>
            <button className="button jb-modal-close is-danger" onClick={props.handleOnModalClose}>Cancel</button>
          </> :
            <button className="button jb-modal-close" onClick={props.handleOnModalClose}>Aceptar</button>

          }
        </footer>
      </Modal>
    </>
  )
};

export default RegisterEstablishmentBankForm;