import React, { useState } from 'react';
import { connect } from 'react-redux';

import * as LoginActions from '../../actions/LoginActions';

import Icon from '@mdi/react';
import {
  mdiForwardburger, mdiBackburger, mdiDotsVertical,
  mdiClose, mdiChevronDown, mdiAccount, mdiCog, mdiEmail,
  mdiLogout, mdiHelpCircleOutline, mdiAccountKey, mdiArrowLeft
} from '@mdi/js';
import { Link } from 'react-router-dom';

const NavBar = ({title, goBack,...props}) => {
  const ICON_SIZE_PROFILE_MENU = '0.8rem';
  const ICON_SIZE_NAVBAR_MENU = '1em';
  const [selectedIcon, setSelectedIcon] = useState(true);
  const handleShowMenuAside = () => {
    document.documentElement.classList.toggle('has-aside-mobile-expanded')
    setSelectedIcon(!selectedIcon);
  }

  const [selectedIconMenuTop, setSelectedIconMenuTop] = useState(true);
  const handleShowMenuTop = (e) => {
    document.getElementById(e.currentTarget.getAttribute('data-target')).classList.toggle('is-active')
    setSelectedIconMenuTop(!selectedIconMenuTop);
  }

  return (
    <nav id="navbar-main" className="navbar is-fixed-top">
      <div className="navbar-brand">
        <div className="navbar-item is-hidden-desktop jb-aside-mobile-toggle" onClick={handleShowMenuAside}>
          <span className="icon"><Icon path={selectedIcon ? mdiForwardburger : mdiBackburger} size={'24px'} title={'Menu'} /></span>
        </div>
        <div className="navbar-item breadcrumbc">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <ul>
                  <li><span className="icon" onClick={goBack}><Icon path={mdiArrowLeft} size={'1em'} /></span></li>
                  {
                    title.map((item, key) => <li key={key}>{item}</li>)
                  }
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="control"><input placeholder="Search everywhere..." className="input" /></div> */}
        </div>
      </div>
      <div className="navbar-brand is-right">
        <div className="navbar-item is-hidden-desktop jb-navbar-menu-toggle" data-target="navbar-menu" onClick={handleShowMenuTop}>
          <span className="icon"><Icon path={selectedIconMenuTop ? mdiDotsVertical : mdiClose} size={ICON_SIZE_NAVBAR_MENU} /></span>
        </div>
      </div>
      <div className="navbar-menu fadeIn animated faster" id="navbar-menu">
        <div className="navbar-end">
          <div className="navbar-item has-dropdown has-dropdown-with-icons has-divider has-user-avatar is-hoverable">
            <div className="navbar-link is-arrowless">
              <div className="is-user-avatar">
                <img src={props.user_data && `https://avatars.dicebear.com/v2/initials/${props.user_data.slug}.svg`} alt="John Doe" />
              </div>
              <div className="is-user-name"><span>{props.user_data && props.user_data.name}</span></div>
              <span className="icon"><Icon path={mdiChevronDown} size={'1em'} /></span>
            </div>
            <div className="navbar-dropdown">
              <a className="navbar-item">
                <span className="icon"><Icon path={mdiAccount} size={ICON_SIZE_PROFILE_MENU} /></span>
                <span>My Profile</span>
              </a>
              <a className="navbar-item">
                <span className="icon"><Icon path={mdiAccountKey} size={ICON_SIZE_PROFILE_MENU} /></span>
                <span>{props.user_data && props.user_data.last_login}</span>
              </a>
              <a className="navbar-item">
                <span className="icon"><Icon path={mdiCog} size={ICON_SIZE_PROFILE_MENU} /></span>
                <span>Settings</span>
              </a>
              <a className="navbar-item">
                <span className="icon"><Icon path={mdiEmail} size={ICON_SIZE_PROFILE_MENU} /></span>
                <span>Messages</span>
              </a>
              <hr className="navbar-divider" />
              <Link to="" className="navbar-item" onClick={() => props.logoutUser()}>
                <span className="icon"><Icon path={mdiLogout} size={ICON_SIZE_PROFILE_MENU} /></span>
                <span>Log Out</span>
              </Link>
            </div>
          </div>
          <Link to={'/'} title="About" className="navbar-item has-divider is-desktop-icon-only">
            <span className="icon"><Icon path={mdiHelpCircleOutline} size={ICON_SIZE_NAVBAR_MENU} /></span>
            <span>About</span>
          </Link>
          <Link to="" title="Log out" className="navbar-item is-desktop-icon-only" onClick={() => props.logoutUser()}>
            <span className="icon"><Icon path={mdiLogout} size={ICON_SIZE_NAVBAR_MENU} /></span>
            <span>Log out</span>
          </Link>
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = ({ LoginReducer }) => LoginReducer;

export default connect(mapStateToProps, LoginActions)(NavBar);